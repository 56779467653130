import { createApp } from 'vue';
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { clone } from '../../helpers';

export default function (data) {
    let counter = 0;

    return createApp({
        data() {
            return _.merge(data, {
                showContactFormToggle: true,
                sortOptions: [
                    { label: 'First Name', value: 'first' },
                    { label: 'Last Name', value: 'last' },
                ],
                selectedSortOptionIdx: 0,
                errors: {},
                contactFormPayload: {},
            });
        },

        computed: {
            filteredStaff() {
                return this.staffPaginator.data;
            },
            numResults() {
                return this.staffPaginator ? this.staffPaginator.total : 0;
            },
            selectedSortOption() {
                return this.sortOptions[this.selectedSortOptionIdx];
            },
        },

        methods: {
            selectSort(sortItem) {
                this.selectedSortOptionIdx = this.sortOptions.findIndex(o => o.value === sortItem.value);
                this.searchState.order = sortItem.value;
                this.searchState.page = 1;
                this.fetchSearchResults();
            },

            fetchSearchResults() {
                const params = _.pickBy(this.searchState, (value, key) => {
                    if (!_.includes(['perPage', 'page', 'order'], key)) {
                        return false;
                    }

                    if (key === 'perPage' && parseInt(value) === this.searchState.DEFAULT_PER_PAGE) {
                        return false;
                    }

                    return !!value;
                });

                Api.get('staff/search', { params })
                    .then(response => {
                        this.staffPaginator = response.data;
                        history.pushState(
                            {
                                counter: ++counter,
                                searchState: clone(this.searchState),
                                paginator: clone(this.staffPaginator),
                            },
                            '',
                            '?' + (new URLSearchParams(params)).toString());
                    }).catch(() => {
                        Alert.toastError('Whoops!', 'Something went wrong.');
                    });
            },

            changePage(page) {
                this.searchState.page = page;
                this.fetchSearchResults();
            },

            scrollToContactForm() {
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },

            scrolled() {
                this.showContactFormToggle = window.scrollY < this.$refs.contactFormContainer.offsetTop - 240;
            },
        },

        mounted() {
            history.replaceState({
                counter: ++counter,
                searchState: clone(this.searchState),
                paginator: clone(this.staffPaginator),
            }, '');

            window.addEventListener('popstate', event => {
                this.searchState = event.state.searchState;
                this.searchQuery = this.searchState.q;
                this.staffPaginator = event.state.paginator;
            });
        },

        created() {
            window.addEventListener('scroll', _.debounce(this.scrolled, 100));
        },
    });
}
