<template>
  <VModal
    id="contact-modal"
    name="contact-modal"
    :backdrop="true"
    size="2xl"
  >
    <div class="modal-header">
      <div
        class="modal-title"
        v-text="headerText"
      />
      <button
        type="button"
        class="close hover:opacity-75"
        @click="closeModal"
      >
        <VIcon i="close" />
      </button>
    </div>
    <div class="flex flex-col-reverse md:flex-row items-stretch w-full">
      <div class="md:flex-1 flex flex-col justify-center">
        <div class="modal-body flex flex-col items-center">
          <img
            v-if="agentsite.cardHeadshotUrl"
            v-lazy:background-image="agentsite.cardHeadshotUrl"
            class="w-1/2 h-1/2 rounded-full bg-cover bg-center mb-4"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
            :alt="`${agentsite.name} photo`"
          >
          <img
            v-if="agentsite.logo"
            :src="agentsite.logo"
            class="h-10 md:h-14 w-auto my-3"
            alt=""
          >
          <p
            class="font-alt text-2xl my-2"
            v-text="agentsite.name"
          />
          <p
            class="my-2"
            v-text="agentsite.phone"
          />
          <div class="flex flex-row justify-center items-center my-2">
            <div
              v-for="(socialLink, index) in agentsite.socialMediaLinks"
              :key="index"
              class="mx-1"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                :href="socialLink.link"
              >
                <VIcon
                  class="text-neutral-200 text-4xl md:text-5xl hover:opacity-75"
                  :i="socialLink.icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex-1 bg-neutral-100 flex flex-col justify-center">
        <div class="modal-body">
          <div v-if="!submitted">
            <form @submit.prevent>
              <div>
                <FormInput
                  label="Name"
                  :required="true"
                  :errors="errors['firstName'] || errors['lastName']"
                >
                  <input
                    v-model="form.fullName"
                    type="text"
                    placeholder="Enter your full name"
                  >
                </FormInput>
              </div>
              <div>
                <FormInput
                  label="Email"
                  :required="true"
                  :errors="errors['email']"
                >
                  <input
                    v-model="form.email"
                    type="email"
                    placeholder="Enter your email address"
                  >
                </FormInput>
              </div>
              <div>
                <FormInput
                  :errors="errors['phone']"
                  label="Phone (Optional)"
                >
                  <input
                    v-model="form.phone"
                    v-mask="'(###) ###-####'"
                    type="tel"
                    placeholder="(   ) ___ - ____"
                  >
                </FormInput>
              </div>
              <div class="flex flex-col flex-1">
                <FormInput
                  :errors="errors['comments']"
                  label="Message"
                  :required="true"
                  class="flex flex-col flex-1"
                >
                  <textarea
                    v-model="form.comments"
                    :placeholder="placeholder"
                    class="flex-1"
                    rows="5"
                  />
                </FormInput>
              </div>
              <button
                type="button"
                class="mt-4 btn btn-primary btn-lg w-full"
                @click="submit"
              >
                Submit
              </button>
            </form>
          </div>

          <div v-else>
            <h3
              class="text-4xl text-center mx-auto my-4"
              v-text="confirmationText"
            />
            <button
              class="btn btn-primary btn-lg w-full"
              type="button"
              @click="closeModal"
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  </VModal>
</template>

<script>
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { submitWithRecaptcha } from '../../recaptcha';

export default {
  props: {
    agentsite: {
      type: Object,
      required: true,
      validator: (value) => {
        const reqProps = ['name', 'type'];
        return reqProps.every(prop => prop in value);
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Serious about buying? Casually looking to sell but wondering what your home is worth? Let us know and we\'ll make sure your message gets to the right person!',
    },
  },

  data() {
    return {
      companyLogo: null,
      errors: {},
      form: {
        contactID: this.agentsite.contactAgentID,
        fullName: '',
        _ignoreOffice: true,
      },
      submitted: false,
    };
  },

  computed: {
    headerText() {
      return this.agentsite.type === 'team' ? 'Contact Us' : 'Contact Me';
    },
    confirmationText() {
      return this.agentsite.type === 'team'
        ? 'We will be in touch with you soon.'
        : 'I will be in touch with you soon.';
    },
  },

  watch: (() => {
    const watchers = {};
    ['fullName', 'firstName', 'lastName', 'phone', 'email', 'comments'].forEach(value => {
      watchers[`form.${value}`] = function (newVal, oldVal) {
        this.clearErrors(value);
      };
    });
    return watchers;
  })(),

  methods: {
    submit() {
      submitWithRecaptcha((recaptchaResponse) => {
        Api.post('forms/agentsite-contact', {
          recaptchaResponse,
          ...this.form,
        })
          .then(() => {
            this.submitted = true;
          })
          .catch((err) => {
            if (err.validation && err.validation.errors) {
              this.errors = err.validation.errors;
            } else {
              Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
            }
          });
      });
    },
    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },
    closeModal() {
      this.errors = {};
      this.$modal.hide('contact-modal');
      this.submitted = false;
      this.form = {
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      };
    },
  },
};
</script>

<style lang="scss">
#contact-modal {
  .modal-header {
    @apply border-b border-neutral-200 mb-0 pb-5;
  }

  .modal-body {
    form {
      @apply flex-1 flex flex-col p-0;
      @screen md {
        @apply px-6 py-10;
      }
      input, textarea {
        @apply border-none;
      }
    }
  }
}
</style>
