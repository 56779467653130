/* global grecaptcha */
import Keychain from './services/Keychain';

export const submitWithRecaptcha = (submit, action = 'submit') => {
    if (!Keychain.recaptcha()) {
        return submit(null);
    }

    grecaptcha.ready(async () => {
        const token = await grecaptcha.execute(Keychain.recaptcha(), { action });

        return submit(token);
    });
};
