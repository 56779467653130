
<template>
  <div class="manage-group-container">
    <div class="mb-12">
      <div
        v-for="group in user.groups"
        :key="group.id"
      >
        <div class="flex flex-row justify-between">
          <span
            class="text-secondary-300"
            v-text="group.name"
          />
          <button
            class="underline hover:opacity-75"
            @click="deleteGroup(group.id)"
          >
            DELETE
          </button>
        </div>

        <hr class="my-3">
      </div>
    </div>
    <div class="text-sm text-secondary-300 mb-2">
      CREATE A NEW GROUP
    </div>
    <div class="form-input-group">
      <FormInput
        class="form-input input-lg"
        :required="true"
        :errors="errors.name"
      >
        <input
          v-model="newGroup.name"
          type="text"
          placeholder="Enter group name here*"
          autofocus
        >
      </FormInput>

      <button
        type="button"
        class="btn btn-primary ml-2 text-sm add-group-btn"
        @click="addGroup"
      >
        ADD
      </button>
    </div>
  </div>
</template>

<script>
import Api from '../../../services/Api';
import Alert from '../../../services/Alert';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['listings-changed', 'user-groups-changed'],

  data() {
    return {
      newGroup: {},
      errors: {},
    };
  },

  watch: {
    newGroup: {
      handler: function (newVal, oldVal) {
        this.clearErrors('name');
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },

    deleteGroup(id) {
      Api.delete('/user/saved-listings/groups/' + id, {})
        .then((response) => {
          if (response.data) {
            this.$emit('user-groups-changed', response.data.groups);
            // this.user.groups = response.data.groups;
            this.$emit('listings-changed', response.data.listings);
          }
          this.errors = {};
          Alert.toastSuccess('Group has been deleted');
        })
        .catch((err) => {
          if (err.validation && err.validation.errors) {
            this.errors = err.validation.errors;
          } else {
            Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
          }
        });
    },
    addGroup() {
      Api.post('/user/saved-listings/groups', this.newGroup)
        .then((response) => {
          if (response.data && response.data.groups) {
            // this.user.groups = response.data.groups;
            this.$emit('user-groups-changed', response.data.groups);
          }
          this.errors = {};
          Alert.toastSuccess('New Group has been created');
        })
        .catch((err) => {
          if (err.validation && err.validation.errors) {
            this.errors = err.validation.errors;
          } else {
            Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input-group {
  .btn {
    @apply text-lg px-10;
    &.add-group-btn {
      // note: these styles are here so that the button does not increase in height
      //  when the adjacent text input's error validation message is present.
      height: fit-content;
      line-height: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}
</style>
