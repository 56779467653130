<template>
  <div v-if="items.length">
    <SliderCarouselHeader
      v-if="showHeader"
      :header-text="headerText"
      :listing-count="listingCount"
    />

    <div
      class="slider-carousel select-none"
      :class="[lastNavigated, maxWidthClass]"
      :style="{height: carouselHeight, 'grid-template-columns': outerGridCols}"
    >
      <div class="nav-prev">
        <button
          v-show="arrowButtonsVisible"
          type="button"
          :disabled="!hasPreviousPage"
          :class="[iconSizeClass, 'scale-up-on-hover']"
          @click="prev"
        >
          <VIcon
            :i="navButtonIcon"
            :rotate="90"
          />
          <span class="sr-only">Previous</span>
        </button>
      </div>
      <div class="nav-next">
        <button
          v-show="arrowButtonsVisible"
          type="button"
          :disabled="!hasNextPage"
          :class="[iconSizeClass, 'scale-up-on-hover']"
          @click="next"
        >
          <VIcon
            :i="navButtonIcon"
            :rotate="-90"
          />
          <span class="sr-only">Next</span>
        </button>
      </div>

      <div
        v-click-away="deselectItem"
        :style="{transform: 'translateX(' + xTransform + 'px)'}"
        @touchstart.prevent="startSwipe"
        @touchend.prevent="stopSwipe"
        @touchmove.prevent="moveHandler"
        @mousedown="startSwipe"
        @mouseup="stopSwipe"
        @mouseleave="stopSwipe"
        @mousemove="moveHandler"
      >
        <TransitionGroup
          tag="div"
          :name="animation"
          :class="[colsClass, 'items-center']"
        >
          <div
            v-for="(item, index) in visibleItems"
            :key="item.id + index"
            :style="{'grid-area': 'card-' + index}"
          >
            <component
              :is="cardComponent"
              :expanded="selectedItem === index || smallScreen === true"
              :item="item"
              :index="index"
              :is-last="index === perPage - 1"
              :is-first="index === 0"
              :mode="mode"
              :pass-shadow-class="!smallScreen"
              :website-logo="websiteLogo"
              :tenant-name="tenantName"
              @on-expand="selectedItem = index"
              @on-collapse="selectedItem = null"
            >
              <slot />
            </component>
          </div>
        </TransitionGroup>
      </div>
      <div class="absolute overflow-hidden h-0 w-0">
        <img
          v-for="(item, index) in nextItems"
          :key="item.id + index"
          :src="item.imageUrl"
          alt=""
        >
      </div>
    </div>
    <section v-if="showDots">
      <VCarouselDots
        :current-page="currentPage"
        :total-count="items.length"
        :dots="dots"
      />
    </section>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    websiteLogo: {
      type: String,
      required: false,
      default: '',
    },
    tenantName: {
      type: String,
      required: false,
      default: '',
    },
    mode: {
      type: String,
      default: 'listing',
    },
    listingTitle: {
      type: String,
      default: '',
    },
    animation: {
      type: String,
      default: 'slide',
      validator: function (value) {
        return [
          'slide',
          'none',
        ].indexOf(value) !== -1;
      },
    },
    headerText: {
      type: String,
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    dots: {
      type: Number,
      default: 12,
    },
    displayDots: {
      type: Boolean,
      default: false,
    },
    countPerPage: {
      type: Number,
      default: null, // non-null value sets fixed number of cards per page
    },
    navButtonIcon: {
      type: String,
      default: 'caret-outline-rev-circle',
    },
    navButtonIconSize: {
      type: String,
      default: '3xl',
      validator: (value) => {
        return ['xs', 'sm', 'md', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'].indexOf(value) !== -1;
      },
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cardComponent: 'div',
      currentPage: 0,
      perPage: 1,
      selectedItem: null,
      lastNavigated: 'next',
      carouselHeight: 'auto',
      xTransform: 0,
      xTouchStart: 0,
      smallScreen: false,
      arrowButtonsVisible: false,
      colsClass: '',
      maxWidthClass: '',
    };
  },

  computed: {
    nextPage() {
      return this.currentPage + 1;
    },
    visibleItems() {
      if (this.currentPage === this.totalPages - 1) {
        return this.items.slice(this.items.length - this.perPage);
      }
      return this.items.slice(
        this.currentPage * this.perPage,
        this.currentPage * this.perPage + this.perPage,
      );
    },
    nextItems() {
      if (!this.hasNextPage) {
        return [];
      }
      if (this.nextPage === this.totalPages - 1) {
        return this.items.slice(this.items.length - 4);
      }
      return this.items.slice(
        this.nextPage * this.perPage,
        this.nextPage * this.perPage + this.perPage,
      );
    },
    totalPages() {
      return Math.ceil(this.items.length / this.perPage);
    },
    hasPreviousPage() {
      return this.loop ? this.totalPages > 1 : this.currentPage > 0;
    },
    hasNextPage() {
      return this.loop ? this.totalPages > 1 : this.currentPage < this.totalPages - 1;
    },
    listingCount() {
      return this.totalCount ? this.totalCount : this.items.length;
    },
    showDots() {
      if (
        this.items.length > 1
        && this.smallScreen
        && (this.mode === 'listing' || this.displayDots === true)
      ) {
        return true;
      }
      return false;
    },
    outerGridCols() {
      let buttonGridWidth = '';
      switch (this.navButtonIconSize) {
        case 'xs':
        case 'sm':
        case 'base':
        case 'md':
          buttonGridWidth = '1rem';
          break;
        case 'lg':
        case 'xl':
          buttonGridWidth = '1.5rem';
          break;
        case '2xl':
        case '3xl':
          buttonGridWidth = '2rem';
          break;
        case '4xl':
          buttonGridWidth = '2.75rem';
          break;
        case '5xl':
        case '6xl':
          buttonGridWidth = '3.5rem';
          break;
        default:
          buttonGridWidth = '2rem';
          break;
      }
      return `${buttonGridWidth} 1fr ${buttonGridWidth}`;
    },
    iconSizeClass() {
      const fontSize = this.navButtonIconSize === 'md' ? 'base' : this.navButtonIconSize;
      return `text-${fontSize}`;
    },
  },

  watch: {
    perPage() {
      this.currentPage = 0;
    },
    countPerPage() {
      this.initPerPage();
    },
  },

  created() {
    window.addEventListener('resize', _.debounce(
      () => {
        this.initPerPage();
        this.initWindowWidth();
      }
      , 100));
  },

  mounted() {
    this.initCardComponent();
    this.initPerPage();
    this.initWindowWidth();
  },

  beforeUpdate() {
    this.initWindowWidth();
    this.initPerPage();
  },

  methods: {
    initWindowWidth() {
      this.smallScreen = window.innerWidth < 640;
    },
    initPerPage() {
      this.perPage = this._determinePerPage();
    },

    startSwipe(event) {
      this.moving = true;
      this.xTouchStart = event.clientX || event.changedTouches[0].screenX;
    },

    stopSwipe(event) {
      // Introduced 5px buffer when swiping to accomodate more sensitive touch screens
      if (event.changedTouches && Math.abs(event.changedTouches[0].screenX - this.xTouchStart) < 5) {
        event.target.click();
      }
      this.moving = false;
      this.xTouchStart = 0;
      this.xTransform = 0;
    },

    handleSwipe(direction) {
      direction === 'left' ? this.next() : this.prev();
    },

    moveHandler: function (event) {
      if (this.moving) {
        let distance = (event.clientX || event.changedTouches[0].screenX) - this.xTouchStart;
        const multiplier = distance < 0 ? -1 : 1;
        distance = Math.abs(distance);
        if (distance > Math.min(Math.round(window.innerWidth * 0.3), 300)) {
          this.handleSwipe(multiplier > 0 ? 'right' : 'left');
          this.stopSwipe({});
        } else {
          this.xTransform = multiplier * Math.round(Math.sqrt(distance) * 2);
        }
      }
    },

    _determinePerPage() {
      let perPage;
      const itemLength = this.items.length;

      if (this.countPerPage !== null) {
        perPage = Math.min(this.countPerPage, itemLength);
        this.arrowButtonsVisible = window.innerWidth >= 640 && itemLength > perPage;
        this.colsClass = 'grid-cols-' + perPage;
        this.maxWidthClass = itemLength < perPage ? 'm-auto' : '';
      } else if (window.innerWidth < 640) {
        perPage = 1;
        this.arrowButtonsVisible = false;
        this.colsClass = '';
      } else if (window.innerWidth < 768) {
        if (itemLength <= 2) {
          perPage = itemLength;
          this.arrowButtonsVisible = false;
          this.colsClass = 'grid-cols-' + itemLength;
          this.maxWidthClass = '';
        } else {
          perPage = 2;
          this.arrowButtonsVisible = true;
          this.colsClass = 'grid-cols-2 gap-2';
        }
      } else if (window.innerWidth < 1024 || this.cardComponent.toLowerCase() === 'largeimagecontentcard') {
        if (itemLength <= 3) {
          perPage = itemLength;
          this.arrowButtonsVisible = false;
          this.colsClass = 'gap-4 grid-cols-' + itemLength;
          this.maxWidthClass = 'm-auto max-w-2xl';
        } else {
          perPage = 3;
          this.arrowButtonsVisible = true;
          this.colsClass = 'grid-cols-3 gap-2';
        }
      } else {
        if (this.cardComponent.toLowerCase() === 'imagecontentcard' && itemLength > 5) {
          perPage = 5;
          this.arrowButtonsVisible = true;
          this.colsClass = 'grid-cols-5';
        } else if (itemLength === 4) {
          perPage = itemLength;
          this.arrowButtonsVisible = false;
          this.colsClass = 'grid-cols-4 gap-2';
        } else if (itemLength === 3) {
          perPage = itemLength;
          this.arrowButtonsVisible = false;
          this.colsClass = 'gap-8 grid-cols-' + itemLength;
          this.maxWidthClass = 'm-auto max-w-6xl';
        } else if (itemLength === 2) {
          perPage = itemLength;
          this.arrowButtonsVisible = false;
          this.colsClass = 'gap-8 grid-cols-' + itemLength;
          this.maxWidthClass = 'm-auto max-w-3xl';
        } else if (itemLength === 1) {
          perPage = itemLength;
          this.arrowButtonsVisible = false;
          this.colsClass = 'gap-0 grid-cols-' + itemLength;
          this.maxWidthClass = 'm-auto max-w-3xl';
        } else {
          perPage = 4;
          this.arrowButtonsVisible = true;
          this.colsClass = 'grid-cols-4 gap-2';
        }
      }
      return perPage;
    },

    deselectItem() {
      this.selectedItem = null;
    },

    prev() {
      if (this.hasPreviousPage) {
        this.navigate('prev');
      }
    },

    next() {
      if (this.hasNextPage) {
        this.navigate('next');
      }
    },

    navigate(direction) {
      this.selectedItem = null;
      this.lastNavigated = direction;
      this.carouselHeight = this.$el.clientHeight + 'px';
      this.$nextTick(() => {
        this.carouselHeight = 'auto';
        if (direction === 'next') {
          if (this.loop && this.currentPage === this.totalPages - 1) {
            this.currentPage = 0;
          } else {
            this.currentPage++;
          }
        } else {
          if (this.loop && this.currentPage === 0) {
            this.currentPage = this.totalPages - 1;
          } else {
            this.currentPage--;
          }
        }
      });
    },

    initCardComponent() {
      if (!this.mode) {
        this.cardComponent = 'ListingContentCard';
      } else if (this.mode.toLowerCase() === 'team') {
        this.cardComponent = 'TeamContentCard';
      } else {
        this.cardComponent = this.mode + 'ContentCard';
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.slider-carousel {
  @screen sm {
    @apply grid grid-cols-3 gap-2;
    grid-template-areas: 'prev-btn visible-items next-btn';
  }

  > div > div {
    grid-area: visible-items;
    @apply grid;
    grid-template-areas: 'card-0';

    @screen sm {
      grid-template-areas: 'card-0 card-1';
    }

    @screen md {
      grid-template-areas: 'card-0 card-1 card-2';
    }

    @screen lg {
      grid-template-areas: 'card-0 card-1 card-2 card-3 card-4';
    }
  }

  .slide-leave-active, .slide-enter-active {
    transition: all 0.3s ease;
  }

  &.prev {
    .slide-leave-to {
      transform: translateX(95vw);
    }

    .slide-enter-from {
      transform: translate(-95vw);
    }
  }

  &.next {
    .slide-leave-to {
      transform: translateX(-95vw);
    }

    .slide-enter-from {
      transform: translateX(95vw);
    }
  }

  .nav-next, .nav-prev {
    button {
      @apply hidden text-black opacity-50;
      @screen sm {
        @apply flex;
      }
      z-index: 995;
      outline: none;

      &:disabled {
        @apply cursor-not-allowed opacity-25;
      }

      &:hover:not(:disabled) {
        @apply opacity-75;
      }
    }
  }

  .nav-prev {
    grid-area: prev-btn;
  }

  .nav-next {
    grid-area: next-btn;
  }

  .nav-next, .nav-prev {
    @apply flex flex-col justify-center items-center;
  }
}

.currently-sliding {
  @apply relative;
}
</style>
