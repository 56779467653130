<template>
  <component
    :is="state.tabContainer"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<script>
import { reactive, onMounted, provide } from 'vue';

export default {
  props: {
    morphBreakpoint: {
      type: Number,
      default: 768,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      tabContainer: 'v-accordion',
    });

    function initTabMode() {
      if (window.innerWidth < props.morphBreakpoint) {
        state.tabContainer = 'v-accordion';
      } else {
        state.tabContainer = 'v-tabs';
      }
      setTimeout(() => { emit('tabs-morphed'); }, 100);
    }

    function tabSelected(tab) {
      emit('tab-selected', tab);
    }

    onMounted(() => {
      initTabMode();
      window.addEventListener('resize', _.debounce(initTabMode, 100));
    });

    provide('MorphTabsProvider', {
      state,
      tabSelected,
    });

    return {
      state,
    };
  },
};
</script>
