<template>
  <div
    class="status-bar"
    :class="statusBarClasses"
  >
    <div class="bar-content">
      <slot name="content">
        <VIcon
          v-if="icon"
          :i="icon"
          class="status-icon"
        />
        <p
          v-if="statusText"
          class="status-text"
          v-text="statusText"
        />
      </slot>
    </div>
    <div
      class="actions"
      :class="{'pr-8': (removable && centerContent)}"
    >
      <button
        v-for="(actionButton, index) in actionButtons"
        :key="index"
        type="button"
        class="btn btn-sm hover:opacity-75"
        :class="['bar-button-' + (actionButton.type || 'outlined')]"
        @click="$emit(actionButton.eventName)"
        v-text="actionButton.text"
      />
      <a
        v-if="removable"
        role="button"
        class="status-close-btn hover:opacity-75"
        :class="{'fixed-right': centerContent}"
        @click="closeBar"
      >
        <VIcon
          i="close"
          :inline-with-text="false"
        />
        <span class="sr-only">Close</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    statusText: {
      type: String,
      default: '',
    },
    barType: {
      type: String,
      default: '',
      validator: function (value) {
        return ['', 'sticky', 'sticky-top', 'sticky-bottom', 'fixed-top', 'fixed-bottom'].indexOf(value) !== -1;
      },
    },
    barStyle: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'success', 'warning', 'danger'].indexOf(value) !== -1;
      },
    },
    actionButtons: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.every(actionButton => {
          return typeof actionButton.text === 'string'
              && typeof actionButton.eventName === 'string'
              && (!actionButton.type || ['outlined', 'filled'].indexOf(actionButton.type) !== -1);
        });
      },
    },
    removable: {
      type: Boolean,
      default: true,
    },
    centerContent: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-status-bar'],

  data() {
    return {
      closed: false,
    };
  },

  computed: {
    statusBarClasses() {
      return [
        this.barType === 'sticky' ? 'sticky-bar' : this.barType,
        this.closed ? 'status-bar-hidden' : '',
        this.centerContent ? 'justify-center' : 'justify-between',
        'bar-' + this.barStyle,
      ];
    },
  },

  methods: {
    closeBar() {
      this.closed = true;
      this.$emit('close-status-bar');
    },
  },
};
</script>

<style scoped lang="scss">
.status-bar {
  @apply w-full flex flex-row items-center p-2 relative;
  min-height: 3.5rem;

  @screen md {
    @apply px-4 py-2;
  }

  &.status-bar-hidden {
    @apply hidden;
  }

  .bar-content {
    @apply flex flex-row items-center;
    color: currentColor;

    .status-icon {
      @apply mr-1;
      color: currentColor;

      @screen md {
        @apply mr-2;
      }
    }

    .status-text {
      @apply mr-2;
      color: currentColor;

      @screen md {
        @apply mr-5;
      }
    }
  }

  .actions {
    @apply flex flex-row items-center;
    color: currentColor;

    button {
      @apply border;
      color: currentColor;
      border-color: currentColor;
      min-width: 5rem;

      &.bar-button-outlined {
        @apply bg-transparent;
      }

      & + button {
        @apply ml-2;
      }
    }

    .status-close-btn {
      @apply cursor-pointer ml-2;
      color: currentColor;

      &.fixed-right {
        @apply absolute right-0 mr-2;

        @screen md {
          @apply mr-4;
        }
      }
    }
  }

  // Bar Type styling
  &.sticky-bar {
    @apply sticky top-0 bottom-0 z-50;
  }

  &.sticky-top {
    @apply sticky top-0 z-50;
  }

  &.sticky-bottom {
    @apply sticky bottom-0 z-50;
  }

  &.fixed-top {
    @apply fixed top-0 left-0 w-screen z-50;
    .actions {
      .status-close-btn {
        &.fixed-right {
          @apply mr-3;

          @screen md {
            @apply mr-6;
          }
        }
      }
    }
  }

  &.fixed-bottom {
    @apply fixed bottom-0 left-0 w-screen z-50;
    .actions {
      .status-close-btn {
        &.fixed-right {
          @apply mr-3;

          @screen md {
            @apply mr-6;
          }
        }
      }
    }
  }

  // Bar Style styling
  &.bar-primary {
    @apply text-white bg-primary;
    .actions {
      button {
        &.bar-button-filled {
          @apply text-primary bg-white border-white;
        }
      }
    }
  }

  &.bar-success {
    @apply text-white bg-success;
    .actions {
      button {
        &.bar-button-filled {
          @apply text-success bg-white border-white;
        }
      }
    }
  }

  &.bar-warning {
    @apply text-black bg-warning;
    .actions {
      button {
        &.bar-button-filled {
          @apply text-warning bg-black border-black;
        }
      }
    }
  }

  &.bar-danger {
    @apply text-white bg-danger;
    .actions {
      button {
        &.bar-button-filled {
          @apply text-danger bg-white border-white;
        }
      }
    }
  }
}
</style>
