import 'lightslider';
import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import LoaderDirective from './LoaderDirective';
import VueClickAway from 'vue3-click-away';
import SvgVue from 'svg-vue3';
import VueLazyloadNext from 'vue-lazyload-next';
import ModalPlugin from '../Vue/ModalPlugin';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueCookies from 'vue3-cookies';
import { VueMaskDirective } from 'v-mask';
const vMaskV3 = {
    beforeMount: VueMaskDirective.bind,
    updated: VueMaskDirective.componentUpdated,
    unmounted: VueMaskDirective.unbind,
};

function provisionApp(app, config = {}) {
    app.use(SvgVue);
    app.use(VueClickAway);
    app.use(VueLazyloadNext);
    app.use(ModalPlugin);
    app.use(VueCookies);
    app.use(VueTippy, {
        directive: 'tippy',
        component: 'tippy',
        flipDuration: 0,
        arrow: true,
        arrowType: 'round',
        popperOptions: {
            modifiers: {
                preventOverflow: {
                    enabled: false,
                },
                hide: {
                    enabled: false,
                },
            },
        },
    });

    if (config.googleMaps) {
        app.use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyA09O7G6i7uVBrP6OLfuIz1zOBio4ucR7c', // gitleaks:allow
            },
            installComponents: true,
        });
    }

    app.directive('mask', vMaskV3);
    app.directive('loader', LoaderDirective);

    // Bizarrely, Vue 3 removes whitespace between elements, which affects
    // the positioning of inline siblings. This setting should prevent this.
    app.config.compilerOptions.whitespace = 'preserve';

    app.provide('modal', app.config.globalProperties.$modal);
}

function registerGlobalComponents(app, contexts) {
    contexts.forEach(context => {
        context.keys().forEach(filename => {
            const componentConfig = context(filename);
            const componentName = filename.split('/').pop()?.split('.')[0];
            app.component(componentName, componentConfig.default || componentConfig);
        });
    });
}

export {
    provisionApp,
    registerGlobalComponents,
};
