<template>
  <v-modal name="login-modal" :backdrop="true" @before-open="init">
    <button type="button" class="close" @click="$modal.hide('login-modal')">
      <v-icon i="close"></v-icon>
    </button>
    <div class="modal-header">
      <h3 class="modal-title">Please sign in</h3>
    </div>
    <div class="modal-body">
      <form-input label="Email" :errors="errors.email">
        <input type="email" v-model="loginForm.email">
      </form-input>
      <form-input label="Password" :errors="errors.password">
        <input type="password" v-model="loginForm.password">
      </form-input>
    </div>
    <div class="modal-footer">
      <a href="/password/reset" class="btn btn-light mr-2">
        Forgot Password
      </a>
      <button type="button" class="btn btn-primary" @click="submit()">
        Log In
      </button>
    </div>
  </v-modal>
</template>
<script>
import Api from "../services/Api";

let resolveLogin, rejectLogin;

export default {
  data() {
    return {
      loginForm: {},
      loginPromise: null,
      errors: {},
    }
  },
  
  methods: {
    init() {
      this.reset();
      this.loginPromise = new Promise((resolve, reject) => {
        resolveLogin = resolve;
        rejectLogin = reject;
      });
    },
    reset() {
      this.loginForm = {
        email: '',
        password: '',
      }
      this.errors = {};
    },
    
    submit() {
      this.errors = {};
      return Api.post('/login', this.loginForm)
          .then(() => {
            resolveLogin();
            this.$modal.hide('login-modal');
          })
          .catch(error => {
            if (error.validation) {
              this.errors = error.validation.errors;
            }
            rejectLogin(error)
          });
    }
  }
}
</script>
