<template>
  <VModal
    :name="modalName"
    size="sm"
  >
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <span class="sr-only">Close modal</span>
      <VIcon i="close" />
    </button>
    <div class="modal-body py-5">
      <ReviewForm
        :tenant-name="tenantName"
        :agent-id="agentId"
      />
    </div>
  </VModal>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
  agentId: {
    type: Number,
    required: true,
  },
  modalName: {
    type: String,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
});

const modal = inject('modal');
const closeModal = () => {
  modal.hide(props.modalName);
};
</script>
