<template>
  <div>
    <div class="mb-6">
      <h3 class="text-2xl mb-2">Contact Information</h3>
      <p class="text-sm" v-text="subtitle"></p>
    </div>
    <form class="contact-form">
      <div class="row">
        <FormInput label="first name" :errors="errors.firstName" :required="true">
          <input type="text" v-model="form.firstName" placeholder="Enter first name">
        </FormInput>
        <FormInput label="last name" :errors="errors.lastName" :required="true">
          <input type="text" v-model="form.lastName" placeholder="Enter last name">
        </FormInput>
      </div>
      <div class="row">
        <FormInput label="email" :errors="errors.email" :required="true">
          <input type="text" v-model="form.email" placeholder="Enter email">
        </FormInput>
        <FormInput label="phone" :errors="errors.phone">
          <input type="text" v-model="form.phone" placeholder="Enter phone">
        </FormInput>
      </div>
      <div class="row">
        <FormInput label="address 1">
          <input type="text" v-model="form.address1" placeholder="Enter address line 1">
        </FormInput>
        <FormInput label="address 2">
          <input type="text" v-model="form.address2" placeholder="Enter address line 2">
        </FormInput>
      </div>
      <div class="row">
        <FormInput label="city">
          <input type="text" v-model="form.city" placeholder="Enter city">
        </FormInput>
        <FormInput label="state">
          <select v-model="form.state" class="pr-5">
            <option value="null" disabled selected>Choose a state</option>
            <option v-for="(state, index) in states" :value="state.abbr">{{ state.name }}</option>
          </select>
        </FormInput>
      </div>
      <div class="row">
        <button type="button" @click="submit" class="w-40 mt-4 btn btn-primary ">
          Update
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import Alert from "../../../services/Alert";
import FormInput from "../../../components/FormInput";

export default {
  components: {
    FormInput,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      form: this.user,
      errors: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      }
    }
  },

  watch: (() => {
    let watchers = {};
    ['firstName', 'lastName', 'phoneNumber', 'email'].map((value) => {
      watchers[`form.${value}`] = function (newVal, oldVal) {
        this.clearErrors(value);
      }
    })
    return watchers;
  })(),

  methods: {
    submit() {
      Api.put('user/account/contact-info', this.form).then(response => {
        Alert.toastSuccess("Changes saved!");
        this.errors = {};
      }).catch(error => {
        this.errors = error.validation.errors;
        Alert.toastError('Whoops!', 'Something went wrong.');
      });
    },

    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = null;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-form {
  @apply flex flex-col;
  .row {
    @apply flex w-full flex-col;
    @screen md {
      @apply flex-row;
    }
    .form-input {
      @apply w-full pr-2 py-1;
      @screen xl {
        @apply w-1/2 pr-36;
      }
      @screen 2xl {
        @apply w-2/5;
      }
    }

  }
}
</style>
