class GalleryInstance {
    constructor(images = []) {
        this.currentIndex = 0;
        this.images = images;
    }

    setImages(images) {
        this.images = images;
    }

    setIndex(index) {
        this.currentIndex = index;
    }

    fullscreen(target = 'body') {
        this.$lg = $(target).lightGallery({
            dynamic: true,
            dynamicEl: this.images.map(i => {
                return {
                    id: i.id,
                    src: i.url
                }
            })
        });

        this.$lg.data('lightGallery').index = this.currentIndex;
    }
}

class Gallery {
    create(images = []) {
        return new GalleryInstance(images);
    }
}

export {
    GalleryInstance
};

export default new Gallery;

