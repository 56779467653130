import { createApp } from "vue";
import Api from "../../services/Api";
import Alert from "../../services/Alert";

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                center: {
                    'lat': 0,
                    'lng': 0
                },
                mapOptions: {
                    detectEvents: true,
                    clickableIcons: false,
                    disableDefaultUi: true,
                    drawEnabled: false,
                    fitBoundsOnDraw: true,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    maxZoom: 18,
                    minZoom: 2,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                },
                address: '',
                markers: [],
                showMap: false,
                googleVerifiedAddress: '',
                bathroom: "",
                bedroom: "",
                sqft: "",
                propertyType: "",
                formattedAddress: "",
                addressComponents: []
            });
        },

        methods: {
            findMap(){
                this.$refs.theMap.$mapPromise.then(() => {
                    let geocoder = new google.maps.Geocoder;
                    geocoder.geocode({address: this.address}, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK){
                            this.showMap = true;
                            let location = results[0].geometry.location;
                            this.addressComponents = results[0].address_components;
                            this.formattedAddress = results[0].formatted_address;
                            this.markers.push({
                                position: {lat: location.lat(), lng: location.lng()},
                            });
                            this.center = {
                                'lat': location.lat(),
                                'lng': location.lng()
                            };
                            this.googleVerifiedAddress = results[0].address_components[0].long_name + ' ' + results[0].address_components[1].long_name;
                            this.findListingByAddress();
                            window.scrollTo(0, this.$refs.atHomeHeader.offsetTop);
                        } else {
                            Alert.toastError('Whoops!', 'Could not find your home, please try a different address');
                        }
                    })
                });
            },
            setAddress($event){
                this.address = $event.target.value;
            },
            findListingByAddress(){
                Api.post('listings/search/address', {
                    address: {
                        HSN: this.findAddressComponentsByType('street_number'),
                        STR: this.findAddressComponentsByType('route'),
                        ZP: this.findAddressComponentsByType('postal_code'),
                        City: this.findAddressComponentsByType('locality'),
                        ST: this.findAddressComponentsByType('administrative_area_level_1'),
                    }
                })
                .then((results) => {
                    if (results.length > 0){
                        this.bedroom = results[0].bedroom;
                        this.bathroom = results[0].bathrooms;
                        this.propertyType = results[0].propertyType;
                        this.sqft = results[0].sqft;
                    }
                })
            },
            findAddressComponentsByType(type){
                for (let i = 0; i < this.addressComponents.length; i++){
                    if (this.addressComponents[i].types.includes(type)){
                        return this.addressComponents[i].long_name;
                    }
                }
                return '';
            }

        },
    })
}
