class Loader {
    constructor() {
        this.loaders = {};
        this.items = {};
        this.itemCount = 0;
        this.messageCount = 0;
    }

    init() {
        this._initialized || (() => {
            this.$element = $('#loader-fullscreen');
            this.$loaderText = this.$element.find('.loader-text');
            this._initialized = true;
        })();
    }

    _refreshText() {
        this.$loaderText.empty();
        _.forOwn(this.items, item => {
            item.message && this.$loaderText.append(`<div class="p-2">${item.message}</div>`);
        });
    }

    loader(loader = 'fullscreen', message = null) {
        this.init();
        return this._add(Math.random().toString(), message, loader);
    }

    full(message) {
        return this.loader('fullscreen', message);
    }

    _add(key, message, loader = 'fullscreen') {
        if (!_.isString(message)) {
            message = '';
        }

        if (_.isUndefined(this.items[key]) ) {
            this.items[key] = { loader, message };
            this._loaderIncrement(loader);
            this.itemCount++;
            message && this.messageCount++;
        }

        this._refreshText();

        return new Deferred(() => {
            this._done(key);
        });
    }

    _done(key) {
        if (!_.isUndefined(this.items[key])) {
            if (this.items[key].message) {
                this.messageCount--;
            }

            this._loaderDecrement(this.items[key].loader);

            delete this.items[key];
            this.itemCount--;
        }

        this._refreshText();
    }

    clear() {
        _.forOwn(this.items, (item, key) => {
            this._done(key);
        });
    }

    _loaderIncrement(loader) {
        this.loaders[loader] || (this.loaders[loader] = 0);
        this.loaders[loader]++;

        if (this.loaders[loader] === 1) {
            $('#loader-' + loader).show();
        }
    }

    _loaderDecrement(loader) {
        this.loaders[loader]--;

        if (this.loaders[loader] === 0) {
            setTimeout(() => {
                $('#loader-' + loader).fadeOut('fast');
            }, 300);
        }
    }
}

class Deferred {
    constructor(work) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        }).finally(work);
    }

    done() {
        this.resolve();
    }
}

export default new Loader;
