class DirBoundDictionary {
    constructor() {
        this.south = 'minLat';
        this.north = 'maxLat';
        this.west = 'minLng';
        this.east = 'maxLng';
    }
}

export default new DirBoundDictionary;
