<template>
  <div
      class="tag-container"
      :class="tagSizeStyles"
  >
    <p class="tag-label" v-text="label"></p>
    <!-- Different size classes: remove-btn-xs, remove-btn-sm, remove-btn-md, remove-btn-lg -->
    <div
        @click="removeTag"
        class="cursor-pointer hover:opacity-75"
        :class="['remove-btn','remove-btn-' + size]"
        v-if="removable"
    >
      <v-icon i="close"></v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: function(value) {
        return ['lg', 'md', 'sm', 'xs'].indexOf(value) > -1;
      },
    },
  },

  computed: {
    tagSizeStyles() {
      switch (this.size) {
        case "xs": return ['px-2', 'py-1', 'text-xs'];
        case "sm": return ['px-2', 'py-1', 'text-sm'];
        case "lg": return ['px-4', 'py-2', 'text-lg'];
        case "md":
        default: return ['px-2', 'py-1', 'text-base'];
      }
    },
  },

  methods: {
    removeTag() {
      this.$emit('tag-removed');
    },
  },
}
</script>

<style lang="scss" scoped>
  .tag-container {
    @apply bg-neutral flex flex-row items-center text-white rounded;

    .remove-btn {
      @apply ml-6 cursor-pointer text-sm;
      &.remove-btn-xs {
        font-size: 0.60rem;
      }
      &.remove-btn-sm {
        font-size: 0.70rem;
      }
      &.remove-btn-md {
        @apply text-sm ml-8;
      }
      &.remove-btn-lg {
        @apply text-base ml-10;
      }
    }
  }
</style>