<template>
  <p class="text-xs my-3">
    By clicking the Submit button below, I agree to the
    <a
      href="/pages/privacy-policy"
      class="underline hover:opacity-75"
      target="_blank"
      rel="nofollow"
    >Privacy Policy</a> and
    <a
      href="/pages/terms-of-use"
      class="underline hover:opacity-75"
      target="_blank"
      rel="nofollow"
    >Terms of Service</a>
    and the Consent Policy below, which I have read.
  </p>
  <p
    v-if="showRecaptcha"
    class="text-xs"
  >
    This site is protected by reCAPTCHA and the Google
    <a
      href="https://policies.google.com/privacy"
      class="underline hover:opacity-75"
      target="_blank"
      rel="nofollow"
    >Privacy Policy</a> and
    <a
      href="https://policies.google.com/terms"
      class="underline hover:opacity-75"
      target="_blank"
      rel="nofollow"
    >Terms of Service</a>
    apply.
  </p>
</template>

<script setup>
import Keychain from '../../services/Keychain';

const showRecaptcha = !!Keychain.recaptcha();
</script>
