import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                form: {},
                errors: {},
                submitted: false,
            });
        },

        watch: (() => {
            const watchers = {};
            ['email', 'password', 'password_confirmation'].forEach((field) => {
                watchers[`form.${field}`] = function () {
                    this.clearErrors(field);
                };
            });
            return watchers;
        })(),

        methods: {
            clearErrors(fieldName) {
                if (this.errors && this.errors[fieldName]) {
                    this.errors[fieldName] = null;
                }
            },

            submitSendLinkForm() {
                Api.post('/password/email', this.form)
                    .then(() => {
                        this.errors = {};
                        this.submitted = true;
                        window.scrollTo(0, 0);
                        Alert.toastSuccess('Password reset link has been sent');
                    })
                    .catch((err) => {
                        if (err.validation && err.validation.errors) {
                            this.errors = err.validation.errors;
                        } else {
                            Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
                        }
                    });
            },

            resendResetLinkEmail() {
                Api.post('/resend-activation', { userId: this.newUserId })
                    .then((response) => {
                        this.errors = {};
                        Alert.toastSuccess('Password reset link has been sent again');
                    })
                    .catch((err) => {
                        if (err.validation && err.validation.errors) {
                            this.errors = err.validation.errors;
                        }
                    });
            },

            submitResetForm() {
                _.merge(this.form, { token: this.token });

                Api.post('/password/reset', this.form)
                    .then((response) => {
                        this.errors = {};
                        Alert.toastSuccess('Password has been reset.');
                        setTimeout(() => {
                            window.location.href = response.data;
                        }, 1500);
                    })
                    .catch((err) => {
                        if (err.validation && err.validation.errors) {
                            this.errors = this.prepareValidationMessages(err.validation.errors);
                        } else {
                            Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
                        }
                    });
            },

            prepareValidationMessages(errors) {
                if (errors.password) {
                    errors.password.forEach((message, index) => {
                        if (message.toLowerCase().indexOf('password confirmation') > 0) {
                            errors.password_confirmation = [errors.password[index]];
                            errors.password.splice(index, 1);
                        }
                    });
                }
                return errors;
            },
        },
    });
}
