<template>
  <div class="flex flex-row justify-between md:listing-image-slider bg-white">
    <div
      class="relative md:h-72 lg:h-[34rem] bg-neutral-100"
      :class="secondaryImages.length ? 'w-[65%] md:w-[55%] lg:w-[62%]' : 'w-full'"
      :data-thumb="primaryImage[urlProp]"
    >
      <button
        class="block w-full h-full"
        @click="openModal"
      >
        <div class="image-banners">
          <div
            v-for="(banner, index) in banners"
            :key="index"
            :class="banner !== 'Exclusive Listing' ? 'hidden lg:block' : ''"
          >
            {{ banner }}
          </div>
        </div>
        <img
          :data-src="primaryImage[urlProp]"
          :src="primaryImage[urlProp]"
          :alt="primaryImage.alt || fallbackAlt"
          :class="primaryImage.height > primaryImage.width ? 'object-contain' : 'object-cover'"
          class="h-full w-full"
        >
        <span class="sr-only">Open photo gallery modal</span>
      </button>
    </div>

    <div
      v-if="secondaryImages.length"
      class="w-[36%] md:w-[40%] lg:w-[35%]"
    >
      <div class="flex flex-col md:grid h-full w-full gap-3 px-2 md:px-0 md:gap-7 lg:gap-8 md:grid-cols-1">
        <button
          v-for="(image, index) in secondaryImages"
          :key="index"
          class="relative md:h-32 lg:h-64 bg-neutral-100"
          :data-thumb="image[urlProp]"
          @click="openModal"
        >
          <span
            v-if="index === 1"
            class="absolute hidden md:block bottom-2 right-2 text-sm text-black font-bold bg-neutral-100 border-1 px-10 py-2 border-r-1 shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]"
          >
            {{ items.length }} Photos
          </span>
          <img
            :key="index"
            :data-src="image[urlProp]"
            :src="image[urlProp]"
            :alt="image.alt || fallbackAlt"
            :class="image.height > image.width ? 'object-contain' : 'object-cover'"
            class="h-full w-full bg-neutral-100"
          >
          <span class="sr-only">Open photo gallery modal</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, inject, ref } from 'vue';
import { GalleryInstance } from '../../services/Gallery';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  fallbackAlt: {
    type: String,
    default: '',
  },
  gallery: {
    // todo: Figure out why we need the generic Object type
    type: [Object, GalleryInstance],
    default: () => new GalleryInstance(),
  },
  urlProp: {
    type: String,
    default: 'url',
  },
  banners: {
    type: String,
    default: '',
  },
});

const modal = inject('modal');
const openModal = () => modal.show('gallery-grid-modal');

const primaryImage = computed(() => props.items[0]);
const secondaryImages = computed(() => props.items.slice(1, 3));
const banners = ref(JSON.parse(props.banners));
</script>
