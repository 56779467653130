<template>
    <div class="relative">
        <label :for="inputId" class="sr-only">{{inputLabel}}</label>
        <input type="text"
               ref="input"
               role="combobox"
               autocomplete="off"
               autocapitalize="off"
               spellcheck="false"
               @blur="handleBlur"
               @keydown="handleKeydown"
               @input="handleInput"
               :id="inputId"
               :class="inputClass"
               :placeholder="placeholder"
        >
        <ul class="absolute z-20 bg-white"
            role="listbox"
            v-if="results.length"
            ref="typeahead-dropdown">
            <li v-for="item in results" :key="item[displayKey]"
                class="block w-full px-4 py-1 hover:bg-neutral-200">
                {{item[displayKey]}}
            </li>
        </ul>
    </div>
</template>

<script>
    import Api from '../services/Api';
    import {debounce} from '../helpers';
    import {guid} from "../helpers";

    export default {
        props: {
            source: {
                type: [String, Array],
                required: true
            },
            displayKey: {
                type: String,
                default: 'label'
            },
            urlKey: {
                type: String,
                default: 'url'
            },
            minChars: {
                type: Number,
                default: 3
            },
            placeholder: {
                type: String,
                default: ''
            },
            inputId: {
                type: String,
                default: guid(),
            },
            inputClass: {
                type: String,
                default: '',
            },
            inputLabel: {
                type: String,
                default: '',
            },
            inputDelay: {
                type: Number,
                default: 350
            }
        },

        data() {
            this.handleInput = debounce(this.handleInput, this.inputDelay);

            return {
                results: [],
                selectedIndex: 0,
                searchCounter: 0,
                loading: false
            }
        },

        methods: {
            getResults(keyword) {
                if (typeof this.source === 'string') {
                    return Api.get(this.source + encodeURIComponent(keyword));
                } else {
                    return new Promise((resolve, reject) => {
                        // @todo handle flat array
                        resolve({data: this.source});
                    });
                }
            },
            search(keyword) {
                // clear existing results and return
                // early if keyword doesn't meet min.
                // length criteria.
                if (!(keyword.length >= this.minChars)) {
                    this.clearResults();
                    return;
                }

                this.loading = true;
                const currentSearch = ++this.searchCounter
                this.getResults(keyword)
                    .then((result) => {
                        // ignore previous searches
                        if (currentSearch !== this.searchCounter) {
                            return;
                        }
                        this.results = result.data;
                        this.selectedIndex = -1;
                    })
                    .catch((error) => {
                        this.clearResults();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            research() {
                this.search(this.$refs.input.value);
            },
            reset() {
                this.setInputValue('');
                this.clearResults();
            },
            clearResults() {
                this.results = [];
                this.selectedIndex = -1;
            },
            selectResult() {
                const selectedResult = this.results[this.selectedIndex];
                if(selectedResult) {
                    this.setValue(selectedResult[this.displayKey]);
                }
                this.clearResults();
            },
            setInputValue(str) {
                this.$refs.input.value = str;
            },
            setSelectedIndex(fromIndex) {
                const resultsCount = this.results.length;
                this.selectedIndex = ((fromIndex % resultsCount) + resultsCount) % resultsCount;
            },
            /*
            Event Handlers
             */
            handleInput(event) {
                this.search(event.target.value);
            },
            handleFocus(event) {
                if(event.target.value.length) {
                    this.research();
                }
            },
            handleBlur(event) {
                this.clearResults();
            },
            handleKeydown(event) {
                const {key} = event

                switch (key) {
                    case 'Up':
                    case 'ArrowUp': {
                        event.preventDefault();
                        this.setSelectedIndex(this.selectedIndex - 1);
                        break;
                    }
                    case 'Down':
                    case 'ArrowDown': {
                        event.preventDefault();
                        if(this.results.length) {
                            this.setSelectedIndex(this.selectedIndex + 1);
                        } else {
                            // if the results are empty (perhaps because the
                            // user blurred the input by clicking elsewhere),
                            // the down key should re-trigger the existing
                            // search.
                            this.research();
                        }
                        break;
                    }
                    case 'Tab': {
                        this.selectResult();
                        break;
                    }
                    case 'Enter': {
                        const selectedResult = this.results[this.selectedIndex]
                        this.selectResult()
                        this.onSubmit(selectedResult)
                        break
                    }
                    case 'Esc':
                    case 'Escape': {
                        // Completely reset the input
                        this.reset();
                        break;
                    }
                    default:
                        return;
                }
            }
        },
    }
</script>

<style scoped>

</style>
