<template>
    <div class="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-10">
        <office-card v-for="office in filteredOffices"
                     :key="office.id"
                     :office="office"
        ></office-card>
    </div>
</template>
<script>
    export default {
        props: ['searchString', 'offices'],

        computed: {
            filteredOffices() {
                if (!this.searchString) {
                    return this.offices;
                }
                return this.offices.filter(o => {
                    return o.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1;
                });
            }
        }
    }
</script>
