<template>
    <div class="card-container">
        <div class="card" :style="{backgroundImage: `url(${headshotUrl})`}">
            <div class="card-info">
                <p class="font-alt text-2xl text-white" v-text="name"></p>
                <a
                    :href="`tel:${phone}`"
                    class="text-sm text-white underline hover:opacity-75 my-2"
                    v-text="phone"
                    v-if="phone"
                ></a>
                <a
                    v-if="profileUrl"
                    :href="profileUrl"
                    target="_blank"
                    class="btn btn-sm hero-button text-white hover:opacity-75 my-8"
                >
                    View My Profile
                </a>
                <div v-if="socialMediaLinks.length" class="flex flex-row justify-center items-center">
                    <div
                        class="mx-1"
                        v-for="(socialLink, i) in socialMediaLinks"
                        :key="i"
                    >
                        <a target="_blank" rel="noopener noreferrer" :href="socialLink.link">
                            <v-icon class="text-3xl text-neutral-100 hover:opacity-75" :i="socialLink.icon"></v-icon>
                        </a>
                    </div>
                </div>
                <button
                    v-if="contactMe"
                    @click="openContactModal"
                    class="btn btn-sm hero-button text-white hover:opacity-75 my-8"
                >
                    Contact Me
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        headshotUrl: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        phone: {
            type: String,
            default: '',
        },
        profileUrl: {
            type: String,
            default: '',
        },
        socialMediaLinks: {
            type: Array,
            default: () => [],
        },
        contactMe: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        openContactModal() {
            this.$emit('open-contact-modal')
        },
    },
}
</script>

<style lang="scss" scoped>
    .card-container {
        .card {
            @apply relative bg-cover bg-center;
            padding-bottom: 120%;

            .card-info {
                @apply flex flex-col items-center justify-center absolute bottom-0 left-0 w-full p-0 text-center;
                background-color: rgba(21,21,21,0.7);
                @apply transition-all h-0;

                > * {
                    @apply transition-all hidden;
                }
            }

            &:hover {
                .card-info {
                    @apply transition-all h-full p-3;

                    > * {
                        @apply transition-all flex;
                    }
                }
            }
        }
    }
</style>
