<template>
<!-- Future enhancement idea - virtual scrolling dots: still have limited number of dots shown but show 1-to-1 dot to -->
<!-- item via animation. See any instagram mobile app post with many images. -->
  <ul class="flex flex-row justify-center items-center mt-5">
    <li
        v-for="(item, index) in itemRange"
        class="rounded-full mr-3"
        :key="index"
        :class="[
            (dotMatch === index ) ? activeItem : inactiveItem,
            (dotMatch === index - 1 || dotMatch === index + 1) ? nextActiveItem : '',
            (dotMatch === index - 2 || dotMatch === index + 2) ? nextActiveItem : ''
        ]"
    >
      <span
          class="sr-only"
          aria-live="polite"
          role="status"
      > {{ (currentPage === index) ? `Listing card  ${item + 1 } selected` : `` }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SliderDots",

  props: {
    totalCount: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    dots: {
      type: Number,
      default: 12,
    }
  },

  data() {
    return {
      activeItem: 'active h-4 w-4 bg-neutral-400 transition duration-300 ease-in-out transform scale-100',
      nextActiveItem: 'h-3 w-3 bg-neutral opacity-50 transition duration-300 ease-in-out',
      inactiveItem: 'h-2 w-2 bg-neutral opacity-25 transform scale-75 transition duration-300 ease-in-out',
    }
  },

  computed: {
    itemRange() {
      if (this.totalCount > this.dots) {
        return this.dots;
      }
      return this.totalCount;
    },
    dotMatch() {
      return Math.floor(this.itemRange / this.totalCount * this.currentPage);
    },
  },
}
</script>

<style scoped>

</style>
