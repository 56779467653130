<template>
  <div class="flex flex-col hover:shadow-2xl">
    <a
      :href="item.url"
      target="_blank"
      class="relative"
    >
      <img
        :src="item.imageUrl"
        class="h-48 w-full object-cover object-center bg-neutral-150"
        :alt="item.imageAlt"
        width="327"
        height="192"
        loading="lazy"
      >
      <img
        v-if="logo?.src || logo?.alt"
        class="absolute bottom-1 right-1 h-6 bg-semi-transparent-gray-light p-1 rounded"
        :src="logo.src"
        :alt="logo.alt"
        loading="lazy"
      >
      <div
        v-if="item.status"
        class="absolute top-4 right-0 py-1 pl-2 pr-6 border-l-3 border-secondary bg-transparent-dark text-right text-white font-bold uppercase text-xs"
        v-text="item.status"
      />
      <div class="px-4 py-2 h-24">
        <slot>
          <div v-text="item.streetAddress" />
          <div v-text="cityState" />
          <div v-text="item.price" />
        </slot>
      </div>
    </a>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
    validator: (value) => value?.url?.length > 0,
  },
  websiteLogo: {
    type: String,
    required: true,
    validator: (value) => value?.length > 0,
  },
  tenantName: {
    type: String,
    required: true,
    validator: (value) => value?.length > 0,
  },
});

const logo = computed(() => {
  if (props.item.isTenantListing) {
    return {
      src: props.websiteLogo,
      alt: `${props.tenantName} logo`,
    };
  }
  if (props.item.brokerReciprocity && props.item.brokerReciprocity.required) {
    return {
      src: props.item.brokerReciprocity.logo,
      alt: 'Broker Reciprocity logo',
    };
  }
  return null;
});

const cityState = computed(() => [props.item.city, props.item.state].filter(part => part?.length).join(', '));
</script>
