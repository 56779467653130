<template>
  <div
    class="tabbed-router-view-container"
    :class="vertical ? 'vertical' : 'horizontal'"
  >
    <div>
      <slot name="top-left-corner" />
    </div>
    <div>
      <slot name="header" />
    </div>
    <div
      v-show="showTabs"
      class="route-tabs"
    >
      <ul
        class="hidden"
        :class="vertical ? 'md:block' : 'md:flex'"
      >
        <li
          v-for="tab in tabs"
          :key="tab.label"
        >
          <RouterLink
            :to="tab.link || tab.route"
            active-class="active"
          >
            {{ tab.label }}
          </RouterLink>
        </li>
      </ul>
      <FormInput
        class="md:hidden"
        label=""
      >
        <select @change="selectTab($event.target.value)">
          <option
            v-for="(tab, index) in tabs"
            :key="tab.label"
            :value="index"
            :selected="tab.route.name === $route.name"
          >
            {{ tab.label }}
          </option>
        </select>
      </FormInput>
    </div>
    <div class="route-view">
      <RouterView />
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import Alert from '../services/Alert';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  vertical: {
    type: Boolean,
    default: true,
  },
  confirmBeforeChangeText: {
    type: String,
    default: 'Are you sure you want to navigate away from this page?',
  },
  confirmBeforeChangeCondition: {
    type: Function,
    default: null,
  },
});

const router = useRouter();
const route = useRoute();

const showTabs = computed(() => !(route.meta?.hideTabs || false));

router.beforeEach((to, from, next) => {
  if (props.confirmBeforeChangeCondition && props.confirmBeforeChangeCondition(to, from)) {
    Alert.confirmYesNo('Confirm', props.confirmBeforeChangeText)
      .then(response => {
        if (response.isConfirmed) {
          next(true);
        } else {
          next(false);
        }
      });
  } else {
    next(true);
  }
});

function selectTab(index) {
  const tab = props.tabs[index];
  router.push(tab.link || tab.route);
}
</script>

<style lang="scss" scoped>
.tabbed-router-view-container {
  &.vertical {
    @apply grid gap-8;
    @screen md {
      grid-template-columns: 1fr 3fr;
    }
    @screen xl {
      grid-template-columns: 1fr 4fr;
    }
  }
  &.horizontal {
    //
  }

}

.vertical {
  .route-tabs {
    li {
      @apply py-4 px-3 border-t-2 border-neutral-100 text-neutral-300;
      .active {
        @apply font-bold text-black;
      }
      &:last-child {
        @apply border-b-2;
      }
    }
  }
}
.horizontal {
  .route-tabs {
    ul {
      @apply flex-row items-center uppercase;
    }

    li {
      @apply flex-1 text-center;
      a {
        @apply block w-full border-b-4 border-neutral-100 py-4 px-6;
        &:hover:not(.active) {
          @apply border-neutral-200;
        }
      }
      .active {
        @apply text-black border-primary;
      }
    }
  }

  .route-view {
    @apply mt-10;
  }
}
</style>
