const dropdownProps = {
    triggerIcon: {
        type: [String, Boolean],
        default: 'caret',
    },

    wideTrigger: {
        type: Boolean,
        default: false,
    },

    anchor: {
        type: String,
        default: 'right',
        validator: v => ['left', 'center', 'right'].includes(v),
    },

    focusRing: {
        type: Boolean,
        default: true,
    },
}

export {
    dropdownProps,
}
