export function useContentCard(props, emit) {
    function toggle() {
        props.expanded ? doCollapse() : doExpand();
    }

    function doCollapse() {
        emit('on-collapse');
    }

    function doExpand() {
        emit('on-expand');
    }

    let shouldExpand = false;

    function collapse() {
        shouldExpand = false;
        setTimeout(() => {
            doCollapse();
        }, 250)
    }

    function expand() {
        shouldExpand = true;
        setTimeout(() => {
            if (shouldExpand) {
                doExpand();
            }
        }, 500);
    }

    return {
        toggle,
        collapse,
        expand,
        doCollapse,
        doExpand,
    }
}
