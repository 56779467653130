<template>
  <FormInput
    :label="label"
    :label-class="labelClass"
    :label-for="`${labelFor}-filter-select`"
  >
    <select
      :id="`${labelFor}-filter-select`"
      :value="modelValue"
      :disabled="disabledField"
      :class="modelValue ? 'text-primary' : 'text-neutral'"
      @change="change"
    >
      <option
        value=""
        v-text="noPreferenceLabel"
      />
      <option
        v-for="o in options"
        :key="o.value"
        :value="o.value"
        v-text="o.label"
      />
    </select>
  </FormInput>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    labelClass: {
      type: String,
      default: '',
      required: false,
    },
    noPreferenceLabel: {
      type: String,
      default: 'No preference',
    },
    modelValue: {
      type: [Object, String, Number],
      default: () => {},
      required: false,

    },
    disabledField: {
      type: Boolean,
      default: false,
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      labelFor: 'search-' + (Math.random() + 1).toString(36).substring(7),
    };
  },

  methods: {
    change($event) {
      this.$emit('update:modelValue', $event.target.value);
    },
  },
};
</script>
