import { createApp } from 'vue';
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import DirBoundDictionary from '../../services/DirBoundDictionary';
import 'marker-clusterer-plus';
import { GMapCluster } from '@fawmi/vue-google-maps';
import gmapDefaultStyles from '../configs/GmapDefaultStyles';
import gmapMarkerIcon from '../configs/GmapMarkerIcon';
import gmapClusterStyles from '../configs/GmapClusterStyles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config.js';
import { clone } from '../../helpers';
const fullTailwindConfig = resolveConfig(tailwindConfig);

export default function (data) {
    let counter = 0;
    let searchCount = 0;
    return createApp({
        components: {
            GMapCluster,
        },
        data() {
            return _.merge(data, {
                showMap: true,
                mobileMapVisible: false,
                showMoreResultsOnScroll: true,
                showStickyBottomBar: true,
                infoWindow: {
                    open: false,
                    position: null,
                    options: { pixelOffset: { width: 0, height: -35 } },
                    item: {},
                },
                pauseMapSearch: true,
                visibleSearchResults: [],
                neighborhoods: [],
                loading: false,
                gmapMarkerIcon,
                gmapClusterStyles,
                mapOptions: {
                    zoomControl: true,
                    minZoom: 2,
                    maxZoom: 18,
                    mapTypeControl: false,
                    detectEvents: true,
                    drawEnabled: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: true,
                    styles: gmapDefaultStyles,
                },
                bounds: null,
                markers: [],
                listingsToMarkers: {},
                errors: {},
                contactFormPayload: {},
            });
        },
        computed: {
            sortByLabel() {
                return this.sortBy.label || 'Sort By';
            },

            sortedResults() {
                const sortByOption = this.sortByOptions.find(o => o.prop === this.sortBy.prop);

                if (!sortByOption) {
                    return this.searchResults;
                }

                return this.searchResults.sort((a, b) => {
                    if (a[sortByOption.sortProp] < b[sortByOption.sortProp]) {
                        return this.sortBy.dir === 'desc' ? 1 : -1;
                    }
                    if (a[sortByOption.sortProp] > b[sortByOption.sortProp]) {
                        return this.sortBy.dir === 'desc' ? -1 : 1;
                    }
                    return 0;
                });
            },
        },

        methods: {
            initMap(customBounds) {
                const autoFitMap = this.previousSearchId !== this.searchId;
                this.listingsToMarkers = {};
                this.markers = [];
                this.$refs.theMap.$mapPromise.then(map => {
                    const bounds = new google.maps.LatLngBounds();
                    if (this.searchResults.length) {
                        this.searchResults.forEach((item, index) => {
                            item.markerID = index;
                            this.listingsToMarkers[item.id] = this.addMarker(item);
                            if (autoFitMap) bounds.extend({ lat: item.lat, lng: item.lng });
                        });
                    }

                    setTimeout(() => {
                        if (autoFitMap) {
                            this.pauseMapSearch = true;
                            map.fitBounds(bounds);
                        } else if (customBounds) {
                            map.fitBounds(customBounds);
                        }
                    });

                    this.showMap = this.searchResults.length;
                });
            },

            mapTilesLoaded() {
                this.pauseMapSearch = false;
            },

            mapIdle() {
                if (this.pauseMapSearch) {
                    return;
                }

                if (this.infoWindow.open) {
                    return;
                }

                const bounds = this.getBoundingRectangle();

                if (this.previousBounds && _.isEqual(this.previousBounds, bounds)) {
                    return;
                }

                this.fetchResults();
                this.previousBounds = bounds;
            },

            emptyVisibleSearchResults() {
                window.scrollTo(0, this.$el.offsetTop);
                this.visibleSearchResults = [];
            },

            makeUrlParams() {
                const urlParams = {};

                if (this.sortBy.prop) {
                    urlParams.sortBy = this.sortBy.prop;
                }
                if (this.sortBy.dir) {
                    urlParams.dir = this.sortBy.dir;
                }
                return (new URLSearchParams(urlParams)).toString();
            },

            prepareSearchFilters() {
                return {
                    minPrice: this.appliedFilters.minPrice,
                    maxPrice: this.appliedFilters.maxPrice,
                    searchTerm: this.appliedFilters.searchTerm,
                    neighborhoodID: this.appliedFilters.neighborhoodID,
                };
            },

            getBoundingRectangle(forUrl = false) {
                const original = this.$refs.theMap.$mapObject.getBounds();
                const json = original.toJSON();
                const bounds = {};
                ['south', 'north', 'east', 'west'].forEach(dir => {
                    bounds[forUrl ? dir.substring(0, 1) : DirBoundDictionary[dir]] = json[dir];
                });
                return bounds;
            },

            showMarkerCard(marker) {
                this.infoWindow.open = true;
                this.infoWindow.position = marker.position;
                this.infoWindow.item = marker.item;
            },

            openInfoWindow(item) {
                if (!this.showMap || window.innerWidth < parseInt(fullTailwindConfig.theme.screens.lg)) {
                    return;
                }
                const index = this.listingsToMarkers[item.id];
                if (this.markers[index]) {
                    this.showMarkerCard(this.markers[index]);
                }
            },

            fetchResults() {
                this.loading = true;
                this.emptyVisibleSearchResults();

                const searchFilters = this.prepareSearchFilters();
                const urlParams = this.makeUrlParams();

                const requestID = ++searchCount;
                const payload = {
                    requestID,
                    searchString: this.searchString,
                    searchFilters,
                };
                if (this.searchString === this.appliedFilters.searchTerm) {
                    payload.searchString = '';
                } else {
                    searchFilters.searchTerm = null;
                    searchFilters.searchTerm = this.searchString;
                }

                this.searchResults = [];

                Api.post('developments/search', payload)
                    .then(response => {
                        if (response.requestID < searchCount) {
                            // another search initiated, this response is stale
                            return;
                        }
                        const shouldPushToHistory = this.searchId !== response.searchId || urlParams !== this.urlParams;
                        this.previousSearchId = this.searchId;
                        this.searchId = response.searchId;
                        this.searchString = response.searchString;
                        this.urlParams = urlParams;

                        this.searchResults = response.data;
                        this.initMap();

                        if (shouldPushToHistory) {
                            this.pushHistoryState();
                        }

                        this.loading = false;
                        this.loadNextPage();
                    }).catch(() => Alert.toastError('Whoops!', 'Something went wrong.'));
                this.appliedFilters.searchTerm = ''; // added to clear searchTerm...rj
                this.appliedFilters.neighborhoodID = ''; // test to clear neighborhoodID...rj
            },

            historyStateData() {
                return clone({
                    counter: ++counter,
                    searchId: this.searchId,
                    searchResults: this.searchResults,
                    appliedFilters: this.appliedFilters,
                    searchString: this.searchString,
                    sortBy: this.sortBy,
                });
            },

            pushHistoryState() {
                history.pushState(
                    this.historyStateData(),
                    '',
                    `${this.baseUrl}/developments/search/${this.searchId}` + (this.urlParams ? `?${this.urlParams}` : ''),
                );
            },

            loadNextPage() {
                if (!this.searchResults.length) {
                    return;
                }

                const offset = this.visibleSearchResults.length;

                for (let i = 0; i < 20; i++) {
                    if (this.sortedResults[offset + i]) {
                        this.visibleSearchResults.push(this.formatSearchResult(this.sortedResults[offset + i]));
                    } else {
                        break;
                    }
                }
            },

            formatSearchResult(item) {
                item.imageUrl = item.image;
                item.imageAlt = `${item.name} photo`;
                return item;
            },

            setSortBy(option) {
                if (this.sortBy.prop === option.prop && this.sortBy.dir === option.dir) {
                    return;
                }

                this.sortBy = option;
                this.urlParams = this.makeUrlParams();
                this.pushHistoryState();
                this.emptyVisibleSearchResults();
                setTimeout(() => {
                    this.loadNextPage();
                }, 200);
            },

            addMarker(item) {
                if (
                    _.isUndefined(item.lat)
                    || _.isUndefined(item.lng)
                    || isNaN(parseFloat(item.lat))
                    || isNaN(parseFloat(item.lng))
                ) {
                    return false;
                }

                this.markers.push({
                    position: { lat: item.lat, lng: item.lng },
                    item,
                });

                return this.markers.length - 1;
            },

            toggleMobileMap() {
                this.mobileMapVisible = !this.mobileMapVisible;
                if (this.mobileMapVisible) {
                    window.scrollTo(0, 120);
                }
            },

            scrollToContactForm() {
                this.showMoreResultsOnScroll = false;
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },

            scrolled() {
                this.showStickyBottomBar = window.scrollY < this.$refs.contactFormContainer.offsetTop - 740;
            },

            handleSuperSearchSelected(event) {
                if (event && event.label && event.category === 'neighborhood') {
                    this.searchString = event.label;
                    this.appliedFilters.neighborhoodID = event.id;
                    this.fetchResults();
                } else if (event && event.url) {
                    window.location.href = event.url;
                }
            },

            clearErrors(fieldName) {
                if (this.errors && this.errors[fieldName]) {
                    this.errors[fieldName] = [];
                }
            },

            scheduleTour(listing) {
                this.errors = {};
                this.$modal.show('schedule-virtual-tour', { listing });
            },
        },

        mounted() {
            if (!this.searchId) {
                this.showMap = false;
            }
            this.previousSearchId = this.searchId;
            history.replaceState(this.historyStateData(), '');

            window.addEventListener('popstate', event => {
                this.searchId = event.state.searchId;
                this.searchResults = event.state.searchResults;
                this.appliedFilters = event.state.appliedFilters;
                this.searchString = event.state.searchString;
                this.sortyBy = event.state.sortBy;

                this.emptyVisibleSearchResults();
                this.loadNextPage();
                this.initMap();
            });

            window.onscroll = _.debounce(() => {
                this.scrolled();

                if (!this.showMoreResultsOnScroll) {
                    return;
                }

                const pixelsBelowViewport
                    = document.documentElement.offsetHeight
                    - document.documentElement.scrollTop
                    - window.innerHeight;

                if (!this.showMoreResultsOnScroll && pixelsBelowViewport > 400) {
                    this.showMoreResultsOnScroll = true;
                }

                if (
                    !this.showMoreResultsOnScroll
                    || !this.searchResults.length
                    || pixelsBelowViewport > 1500
                ) {
                    return;
                }

                this.loadNextPage();
            }, 300);

            this.loadNextPage();
            this.initMap(this.mbr
                ? {
                        south: parseFloat(this.mbr[DirBoundDictionary.south]),
                        north: parseFloat(this.mbr[DirBoundDictionary.north]),
                        east: parseFloat(this.mbr[DirBoundDictionary.east]),
                        west: parseFloat(this.mbr[DirBoundDictionary.west]),
                    }
                : null);
        },
    });
}
