<template>
  <div
    class="flex flex-row items-center my-5 py-2 bg-neutral-100 text-sm text-center "
    :class=" !galleryLayout ? 'justify-around gap-2' : 'justify-end sm:justify-around gap-4 sm:gap-2'"
  >
    <div
      v-if="!galleryLayout && galleryCount > 1"
      class="text-sm text-center hover:text-neutral-300 whitespace-nowrap mr-0 md:mr-3"
      :class="virtualTourUrl ? '' : 'flex flex-1 sm:grow-0 justify-center'"
    >
      <button @click="$modal.show('gallery-grid-modal')">
        VIEW ALL PHOTOS <span class="hidden md:inline">({{ galleryCount }})</span>
      </button>
    </div>

    <div
      v-if="virtualTourUrl"
      class="text-sm  hover:text-neutral-300 whitespace-nowrap"
      :class="!galleryLayout ? 'grow-0 text-center' : 'flex flex-1 md:grow-0 justify-start ml-2'"
    >
      <a
        :href="virtualTourUrl"
        target="_blank"
        rel="noreferrer noopener"
      >
        VIRTUAL TOUR
      </a>
    </div>
    <div
      v-else
      :class="!galleryLayout ? 'hidden' : 'inline flex-1 md:hidden'"
    />

    <div
      v-if="videoUrl"
      class="flex-row items-center hover:text-neutral-300"
      :class="{
        'justify-start md:justify-center': compactButtons,
        'justify-center': !compactButtons,
        'hidden md:flex': hideButtonsOnMobile,
        'mt-1 md:mt-0': galleryLayout
      }"
    >
      <button
        @click="openVideoModal"
      >
        <span :class="{'hidden md:inline': compactButtons}">VIDEO</span>
        <VIcon
          key="solid"
          class="inline md:hidden text-2xl md:text-base"
          i="video-solid-circle"
        />
      </button>
    </div>

    <div
      v-if="brochureUrl"
      class="flex-row items-center hover:text-neutral-300"
      :class="{
        'justify-start md:justify-center': compactButtons,
        'justify-center': !compactButtons,
        'hidden md:flex': hideButtonsOnMobile
      }"
    >
      <a
        :href="brochureUrl"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span :class="{'hidden md:inline': compactButtons}">BROCHURE</span>
        <VIcon
          key="solid"
          class="inline md:hidden text-2xl md:text-base"
          i="file-outline-circle"
        />
      </a>
    </div>
    <div
      v-if="isFavoriteVisible"
      class="hover:text-neutral-300"
      :class="{
        'justify-start md:justify-center': compactButtons,
        'justify-center': !compactButtons,
        'hidden md:flex': hideButtonsOnMobile
      }"
    >
      <FavoriteToggle
        :listing="listing"
        favorite-icon="heart-solid-circle"
        un-favorite-icon="heart-outline-circle"
        :include-text-label="true"
        :compact-buttons="compactButtons"
        class="flex items-center text-2xl md:text-xl"
      />
    </div>
    <div
      v-if="listing.socialLinks && listing.socialLinks.length"
      class="flex-row items-center"
      :class="{
        'justify-start md:justify-center': compactButtons,
        'justify-center': !compactButtons,
        'hidden md:flex': hideButtonsOnMobile
      }"
    >
      <VPopover
        anchor="right"
        :trigger-icon="false"
        :focus-ring="false"
      >
        <template #trigger>
          <div class="flex flex-row items-center">
            <span class="text-2xl md:text-xl leading-[0rem] text-center mr-3 hover:text-neutral-300 mt-1">
              <VIcon
                i="share-outline-circle"
              />
            </span>
            <div
              class="text-sm text-center hidden lg:block"
            >
              SHARE
            </div>
          </div>
        </template>
        <ul class="menu-list w-48">
          <li
            v-for="socialLink in listing.socialLinks"
            :key="socialLink"
          >
            <a
              :href="socialLink.url"
              target="_blank"
            >
              <VIcon
                :i="socialLink.icon"
                class="mr-2"
              />
              <span>{{ socialLink.shareText }}</span>
            </a>
          </li>
          <ShareOptions
            :share-message-text="listing.shareMessageText"
            :share-url="listing.listingUrl"
          />
        </ul>
      </VPopover>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
  galleryLayout: {
    type: Boolean,
    default: true,
  },
  compactButtons: {
    type: Boolean,
    default: false,
  },
  hideButtonsOnMobile: {
    type: Boolean,
    default: false,
  },
  virtualTourUrl: {
    type: String,
    required: true,
  },
  videoUrl: {
    type: String,
    required: true,
  },
  galleryCount: {
    type: Number,
    default: 0,
  },
  brochureUrl: {
    type: String,
    required: true,
  },
  isFavoriteVisible: {
    type: Boolean,
    default: true,
  },
  hasRealVisionVirtualTour: {
    type: Boolean,
    default: false,
  },
  listing: {
    type: Object,
    required: true,
  },
});

const modal = inject('modal');
const openVideoModal = () => {
  modal.show('video-modal', {
    url: props.videoUrl,
    title: props.listing.streetAddress,
  });
};

</script>
