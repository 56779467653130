import { ref, onMounted, onBeforeUnmount } from 'vue';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export default function useMobileDetection() {
    const isMobile = ref(false);

    const checkMobile = () => {
        isMobile.value = window.innerWidth < Number(fullConfig.theme.screens.md.replace('px', '')); // tailwind breakpoint
    };

    onMounted(() => {
        checkMobile();
        window.addEventListener('resize', _.debounce(checkMobile, 100));
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', _.debounce(checkMobile, 100));
    });

    return {
        isMobile,
    };
}
