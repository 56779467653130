import { createApp } from "vue";
import Api from "../../services/Api";
import Alert from "../../services/Alert";

export default function (appData) {
    return createApp({
            data() {
                return _.merge(appData, {
                    loginForm: {},
                    userId: null,
                    errors: {},
                });
            },

            watch: (() => {
                let watchers = {};
                ['email', 'password'].map((value) => {
                    watchers[`loginForm.${value}`] = function (newVal, oldVal) {
                        this.clearErrors(value);
                    }
                })
                return watchers;
            })(),

            methods: {
                submitLoginForm(event) {
                    Api.post('/login', this.loginForm)
                        .then((response) => {
                            this.errors = {};
                            if (this.redirectTo) {
                                location.href = this.redirectTo;
                            } else if (response.data && response.data.redirectTo) {
                                location.href = response.data.redirectTo;
                            } else {
                                location.reload();
                            }
                        })
                        .catch((err) => {
                            if (!err.validation || !err.validation.errors) {
                                this.errors = {};
                                this.userId = null;
                                return;
                            }

                            this.errors = err.validation.errors;

                            if (err.validation.errors.activation && err.validation.errors.activation.length > 0) {
                                this.userId = err.validation.errors.activation[0];
                            }
                        });
                },

                clearErrors(fieldName) {
                    if (this.errors && this.errors[fieldName]) {
                        this.errors[fieldName] = [];
                    }
                },

                resendActivationEmail() {
                    if (!this.userId) {
                        return;
                    }
                    Api.post('/resend-activation', { userId: this.userId })
                        .then((response) => {
                            this.errors = {};
                            Alert.toastSuccess('Activation email sent again')
                        })
                        .catch((err) => {
                            if (err.validation && err.validation.errors) {
                                this.errors = err.validation.errors;
                            }
                        });
                },
            }
        }
    )
}
