<template>
  <div>
    <div
      v-if="submitted"
      class="flex flex-col"
    >
      <h2 class="text-4xl text-center mb-2 t-header">
        Application Received!
      </h2>
      <p class="text-center mb-4">
        A member of our team will be in touch soon.
      </p>
      <div class="flex flex-initial items-center justify-center">
        <a
          href="/"
          class="btn btn-primary btn-lg"
        >Back To Home</a>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col"
    >
      <h2
        v-if="!hideHeading"
        class="text-4xl mb-2 t-header"
        :class="[alignLeft ? 'text-left': 'text-center']"
        v-text="heading"
      />
      <p
        class="mb-4"
        :class="[alignLeft ? 'text-left': 'text-center']"
      >
        <slot>Please complete the form below.</slot>
      </p>
      <form @submit.prevent="submit">
        <div class="sm:flex sm:flex-row">
          <div class="sm:flex-1 sm:mr-2">
            <FormInput
              class="input-lg"
              label="Name"
              :required="true"
              :errors="errors.firstName || errors.lastName"
            >
              <input
                v-model="form.fullName"
                type="text"
                placeholder="Enter your full name"
                @keyup="(errors.firstName = null) || (errors.lastName = null)"
              >
            </FormInput>
          </div>
        </div>
        <div>
          <FormInput
            class="input-lg"
            label="Email"
            :required="true"
            :errors="errors.email"
          >
            <input
              v-model="form.email"
              type="email"
              placeholder="Email"
              @keyup="errors.email = null"
            >
          </FormInput>
        </div>
        <div
          v-if="!hidePhone"
          class="mt-2"
        >
          <FormInput
            class="input-lg"
            :errors="errors.phone"
          >
            <input
              v-model="form.phone"
              v-mask="'(###) ###-####'"
              type="text"
              placeholder="Phone (Optional)"
              @keyup="errors.phone = null"
            >
          </FormInput>
        </div>
        <div
          v-if="!hideComments"
          class="mt-2"
        >
          <FormInput class="input-lg">
            <textarea
              v-model="form.comments"
              placeholder="Comments (Optional)"
            />
          </FormInput>
        </div>
        <FormUserAgreement />
        <button
          type="submit"
          class="mt-4 btn btn-primary btn-lg w-full"
        >
          Submit
        </button>
        <FormUserConsent />
      </form>
    </div>
  </div>
</template>

<script>
import Api from '../../services/Api';
import { submitWithRecaptcha } from '../../recaptcha';

export default {
  props: {
    heading: {
      type: String,
      default: 'Join Us',
    },
    hideHeading: {
      type: Boolean,
      default: false,
    },
    hidePhone: {
      type: Boolean,
      default: false,
    },
    hideComments: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    jobTitle: {
      type: String,
      default: '',
    },
  },

  emits: ['form-submitted'],

  data() {
    return {
      form: {
        jobTitle: this.jobTitle,
        fullName: '',
        _ignoreOffice: true,
        _ignoreComments: true,
      },
      errors: {
        //
      },
      submitted: false,
    };
  },

  methods: {
    submit() {
      submitWithRecaptcha((recaptchaResponse) => {
        Api.post('/forms/careers', {
          recaptchaResponse,
          ...this.form,
        })
          .then(() => {
            this.$emit('form-submitted');
            this.submitted = true;
          })
          .catch(error => {
            this.errors = error.validation.errors;
          });
      });
    },
  },
};
</script>
