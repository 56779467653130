<template>
  <div class="my-6 flex flex-col">
    <a
      v-if="state.useHistoryBack"
      href="media-center/media-resources"
      class="uppercase text-base mb-10"
      @click.prevent="$router.back()"
    >
      <VIcon
        i="arrow"
        :rotate="180"
      /> Back
    </a>
    <RouterLink
      v-else
      :to="{ name: 'mediaCenter.mediaResources' }"
      class="uppercase text-base mb-10"
    >
      <VIcon
        i="arrow"
        :rotate="180"
      /> Back
    </RouterLink>
    <div
      v-if="state.resource"
      class="flex flex-col lg:flex-row"
    >
      <div class="flex-auto">
        <img
          v-if="state.resource.pressReleaseImage"
          v-lazy="state.resource.pressReleaseImage"
          alt=""
          class="object-contain w-full max-h-96"
        >

        <div class="p-5 lg:p-0 w-full lg:mb-10">
          <div class="flex justify-between">
            <p
              class="text-base text-neutral mt-6 mb-4 uppercase"
              v-text="state.resource.formattedPublicationDate"
            />
            <VDropMenu
              anchor="right"
              :trigger-icon="false"
              :items="socialShareLinks"
            >
              <template #trigger>
                <span class="text-3xl block text-center text-neutral">
                  <VIcon i="share-solid-circle" />
                </span>
                <div class="text-xs text-center text-neutral">
                  Share
                </div>
              </template>
            </VDropMenu>
          </div>

          <h1
            class="text-left text-black text-3xl md:text-4xl t-header my-5"
            v-text="state.resource.pressReleaseHeadline"
          />

          <div
            class="text-left mb-2 md:mb-4 basic-html"
            v-html="state.resource.pressReleaseBody"
          />
        </div>
      </div>

      <MediaCenterPressKit
        :hide-on-mobile="false"
        :staff="staff"
      />
    </div>

    <div class="text-center">
      <h3 class="text-3xl t-header my-6">
        We bring the search to you
      </h3>
      <p>
        There&rsquo;s a reason we&rsquo;re #1 in Chicago: we go the extra mile to be as close-to-your-fingertips
        as possible.
      </p>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, reactive, inject, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Api from '../../../services/Api';

const staff = inject('staff');

const state = reactive({
  resource: null,
  useHistoryBack: false,
});

const route = useRoute();

const router = useRouter();

const socialShareLinks = computed(() => {
  return state.resource?.socialLinks.map(link => {
    return {
      href: link.url,
      newTab: true,
      icon: link.icon,
      label: link.shareText,
    };
  }) || [];
});

onBeforeMount(() => {
  Api.get(`media-center/resources/${route.params.resource}`).then(({ data: resource }) => {
    state.resource = resource;

    document.title = resource.metaSeo.pageTitle;
  });

  if (router.options.history?.state.back === '/media-resources') {
    state.useHistoryBack = true;
  }
});

</script>
