import { createApp } from 'vue';
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { clone } from '../../helpers';

export default function (data) {
    let counter = 0;

    return createApp({
        data() {
            return _.merge(data, {
                showContactFormToggle: true,
                sortOptions: [
                    { label: 'Default', value: 'shuffle' },
                    { label: 'First Name', value: 'first' },
                    { label: 'Last Name', value: 'last' },
                ],
                selectedSortOptionIdx: 0,
                searchQuery: '',
                errors: {},
                agentModal: {},
                contactFormPayload: {},
            });
        },

        computed: {
            filteredAgents() {
                return this.agentsPaginator.data;
            },
            numResults() {
                return this.agentsPaginator ? this.agentsPaginator.total : 0;
            },
            selectedSortOption() {
                return this.sortOptions[this.selectedSortOptionIdx];
            },
            isFilterApplied() {
                return this.searchState.filters && this.searchState.filters.length > 0;
            },
        },

        methods: {
            handleItemSelected(event) {
                if (event === null) {
                    return;
                }
                this.searchQuery = '';

                const filterCategories = ['office', 'language'];
                if (filterCategories.indexOf(event.category) > -1) {
                    const filterIsAlreadyActive = this.searchState.filters
                        .findIndex(f => f.id === event.id && f.category === event.category) > -1;
                    if (!filterIsAlreadyActive) {
                        this.searchState.filters.push(event);
                        this.searchState.page = 1;
                        this.updateSearchStateFilters();
                        this.fetchSearchResults();
                    }
                } else if (event.url) {
                    window.location.href = event.url;
                }
            },

            getFiltersByCategory(category) {
                if (!category || category.length <= 0) {
                    return null;
                }

                return this.searchState.filters
                    .filter(filterOpt => filterOpt.category === category)
                    .map(filterOpt => filterOpt.id)
                    .join(',');
            },

            updateSearchStateFilters(categories = null) {
                if (!categories) {
                    categories = ['office', 'language', 'q'];
                }

                categories.forEach(category => {
                    this.searchState[category] = this.getFiltersByCategory(category);
                });
            },

            removeFilter(idx) {
                if (idx < 0 || idx >= this.searchState.filters.length) {
                    return;
                }

                this.searchState.filters.splice(idx, 1);
                this.searchState.page = 1;
                this.updateSearchStateFilters();
                this.fetchSearchResults();
            },

            selectSort(sortItem) {
                this.selectedSortOptionIdx = this.sortOptions.findIndex(o => o.value === sortItem.value);
                this.searchState.order = sortItem.value;
                this.searchState.page = 1;
                this.fetchSearchResults();
            },

            updateSearchQueryFilter() {
                const searchQueryFilter = {
                    category: 'q',
                    id: this.searchQuery, // setting value to 'id' for proper reading for request
                    label: `"${this.searchQuery}"`,
                };

                const searchQueryIdx = this.searchState.filters.findIndex(filter => filter.category === 'q');
                if (searchQueryIdx > -1) {
                    this.searchState.filters[searchQueryIdx] = searchQueryFilter;
                } else {
                    this.searchState.filters.push(searchQueryFilter);
                }

                this.searchQuery = '';
            },

            search() {
                this.searchState.page = 1;
                this.updateSearchQueryFilter();
                this.updateSearchStateFilters();
                this.fetchSearchResults();
            },

            fetchSearchResults() {
                const params = _.pickBy(this.searchState, (value, key) => {
                    if (!_.includes(['perPage', 'page', 'office', 'language', 'order', 'q'], key)) {
                        return false;
                    }

                    if (key === 'perPage' && parseInt(value) === this.searchState.DEFAULT_PER_PAGE) {
                        return false;
                    }

                    return !!value;
                });

                Api.get('agents/search', { params })
                    .then(response => {
                        this.agentsPaginator = response.data;
                        history.pushState(
                            {
                                counter: ++counter,
                                searchState: clone(this.searchState),
                                paginator: clone(this.agentsPaginator),
                            },
                            '',
                            '?' + (new URLSearchParams(params)).toString());
                    }).catch(() => Alert.toastError('Whoops!', 'Something went wrong.'));
            },

            changePage(page) {
                this.searchState.page = page;
                this.fetchSearchResults();
            },

            scrollToContactForm() {
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },

            scrolled() {
                this.showContactFormToggle = window.scrollY < this.$refs.contactFormContainer.offsetTop - 240;
            },

            showModalAndSetAgent(agent) {
                this.agentModal = agent;
                this.$modal.show('contact-agent-form');
            },
        },

        mounted() {
            history.replaceState({
                counter: ++counter,
                searchState: clone(this.searchState),
                paginator: clone(this.agentsPaginator),
            }, '');

            window.addEventListener('popstate', event => {
                this.searchState = event.state.searchState;
                this.searchQuery = this.searchState.q;
                this.agentsPaginator = event.state.paginator;
            });
        },

        created() {
            window.addEventListener('scroll', _.debounce(this.scrolled, 100));
        },
    });
}
