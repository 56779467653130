<template>
    <v-paginator v-bind="$attrs"></v-paginator>
</template>

<script>
    export default {
        created() {
            console.error(
                "<paginator/> is deprecated and replaced by <v-paginator/> because " +
                "single word components are a bad idea. Blame Tikhon."
            );
        }
    }
</script>
