<template>
  <FormInput
    :label="label"
    :label-for="`${labelFor}-filter-text`"
  >
    <input
      :id="`${labelFor}-filter-text`"
      type="text"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </FormInput>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],
  data() {
    return {
      labelFor: 'search-' + (Math.random() + 1).toString(36).substring(7),
    };
  },
};
</script>
