<template>
  <div class="flex-none" v-if="article.imageUrl">
    <a :href="article.articleUrl" target="_blank" rel="noopener noreferrer">
      <img
          class="object-contain w-full h-72 md:w-6 md:h-6"
          :alt="article.title"
          v-lazy="article.imageUrl"
      >
    </a>
  </div>
  <div class="flex-col flex-auto md:pl-6">
    <p class="text-xs md:text-sm text-neutral pt-4 md:pt-0 mb-4 uppercase flex flex-row items-center">
      <a
          :href="article.articleUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="mr-2 md:mr-4"
          v-if="article.iconUrl"
      >
        <img
            class="object-contain w-6 h-6 inline"
            :alt="article.title"
            v-lazy="article.iconUrl"
        >
      </a>
      <a
          :href="article.articleUrl"
          target="_blank"
          rel="noopener noreferrer"
          v-if="article.iconUrl"
      >
        <span v-text="article.publicationName"></span>
      </a>
      <span class="mx-1.5 md:mx-3">|</span>
      <span v-text="article.publicationDate"></span>
    </p>
    <h6 class="text-xl font-bold mb-4">
      <a
          :href="article.articleUrl"
          v-text="article.articleTitle"
          target="_blank"
          rel="noopener noreferrer"
      ></a>
    </h6>
    <p class="text-lg mb-4" v-text="article.description"></p>
    <a class="uppercase" :href="article.articleUrl" target="_blank" rel="noopener noreferrer">
      <span v-text="linkItem.text"></span>
      <v-icon class="ml-1" :i="linkItem.icon"></v-icon>
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {
      linkItem: {
        text: 'Read More',
        icon: 'arrow',
      },
    }
  },

  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  beforeMount() {
    if (this.article.hasDownloadLink) {
      return this.linkItem = {
        text: 'Download',
        icon: 'download',
      }
    }
  },
}
</script>
