<template>
  <div class="listing-link-card-container">
    <a class="listing-link-card" :href="listing.linkUrl">
      <div
          class="listing-link-card-image"
          :style="`background-image: url('${listing.imageUrl}');`"
      ></div>
      <div class="listing-link-card-details">
        <p :class="[largeTextSizes, 'font-alt font-black my-1']" v-text="listing.address1"></p>
        <p :class="[smallTextSizes, 'my-1']" v-text="listing.address2"></p>
        <p :class="[smallTextSizes, 'my-1']" v-text="listingDetails"></p>
        <p :class="[smallTextSizes, 'my-1']" v-text="listingPrice"></p>
        <VIcon :class="[iconSizes, 'listing-link-card-icon']" :rotate="270" i="caret-outline-circle" />
      </div>
    </a>
  </div>
</template>

<script>
let numberFormatter = new Intl.NumberFormat('en-US');
export default {
  props: {
    listing: {
      type: Object,
      required: true,
      validator: function (val) {
        let requiredProps = ['address1', 'address2', 'formattedPrice'];
        return requiredProps.every(prop => val[prop]);
      },
    },
    breakPointTextSizes: {
      type: Object,
      default: () => {
        return {
          'default': 'xs',
          'md': 'sm',
          'lg': 'lg',
        };
      },
      validator: function (val) {
        let validBreakpoints = ['default', 'xs', 'sm', 'md', 'lg', 'xl']; // 'default' and 'xs' are the same
        let validSizes = ['xs', 'sm', 'md', 'base', 'lg', 'xl']; // 'md' and 'base' are the same
        return Object.keys(val).every(bp => validBreakpoints.includes(bp) && validSizes.includes(val[bp]));
      }
    },
  },

  computed: {
    listingDetails() {
      let details = [];
      let [beds, fBaths, hBaths, sqft] = [this.listing.beds, this.listing.fullBaths, this.listing.halfBaths, this.listing.squareFootage];
      if (beds) {
        details.push(`${beds} bed${parseInt(beds) === 1 ? '' : 's'}`);
      }
      if (fBaths) {
        details.push(`${fBaths} bath${parseInt(fBaths) === 1 ? '' : 's'}`);
      }
      if (hBaths) {
        details.push(`${hBaths} half bath${parseInt(hBaths) === 1 ? '' : 's'}`);
      }
      if (sqft) {
        details.push(`${sqft} sq. ft.`);
      }
      return details.join(' | ');
    },
    listingPrice() {
      return this.listing.formattedPrice;
    },
    largeTextSizes() {
      // Available text size classes (purgecss prevention)
      //
      // text-base sm:text-base md:text-base lg:text-base xl:text-base
      // text-lg sm:text-lg md:text-lg lg:text-lg xl:text-lg
      // text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl
      // text-3xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl
      return Object.keys(this.breakPointTextSizes).map(bp => {
        let bpPrefix = (bp === 'default' || bp === 'xs') ? '' : bp;
        let largeTextClassMap = {
          'xs': 'text-base',
          'sm': 'text-lg',
          'md': 'text-2xl',
          'base': 'text-2xl',
          'lg': 'text-3xl',
          'xl': 'text-3xl',
        };
        let textClass = largeTextClassMap[this.breakPointTextSizes[bp]];
        return bpPrefix ? `${bpPrefix}:${textClass}` : textClass;
      }).join(' ');
    },
    smallTextSizes() {
      // Available text size classes (purgecss prevention)
      //
      // text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs
      // text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm
      // text-base sm:text-base md:text-base lg:text-base xl:text-base
      // text-lg sm:text-lg md:text-lg lg:text-lg xl:text-lg
      // text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl
      return Object.keys(this.breakPointTextSizes).map(bp => {
        let bpPrefix = (bp === 'default' || bp === 'xs') ? '' : bp;
        let textSize = this.breakPointTextSizes[bp];
        let textClass = (textSize === 'md') ? 'text-base' : `text-${textSize}`;
        return bpPrefix ? `${bpPrefix}:${textClass}` : textClass;
      }).join(' ');
    },
    iconSizes() {
      return Object.keys(this.breakPointTextSizes).map(bp => {
        let bpPrefix = (bp === 'default' || bp === 'xs') ? '' : bp;
        let largeTextClassMap = {
          'xs': 'text-2xl',
          'sm': 'text-3xl',
          'md': 'text-4xl',
          'base': 'text-4xl',
          'lg': 'text-5xl',
          'xl': 'text-5xl',
        };
        let textClass = largeTextClassMap[this.breakPointTextSizes[bp]];
        return bpPrefix ? `${bpPrefix}:${textClass}` : textClass;
      }).join(' ');
    },
  },
}
</script>

<style lang="scss" scoped>
.listing-link-card-container {
  @apply relative;
  padding-bottom: 92%;

  .listing-link-card {
    @apply absolute top-0 left-0 h-full w-full cursor-pointer;

    .listing-link-card-image {
      @apply bg-cover bg-center h-full;
    }

    .listing-link-card-details {
      @apply flex flex-col items-center justify-center absolute bottom-0 left-0 w-full text-white;
      @apply transition-all duration-300 h-2/5;
      background-color: rgba(21, 21, 21, 0.70);

      @screen lg {
        @apply h-1/3;
      }

      > p {
        @apply transition-all duration-300 text-white;
      }

      .listing-link-card-icon {
        @apply transition-all duration-300 hidden mt-0;
      }
    }

    &:hover {
      .listing-link-card-details {
        @apply transition-all duration-300 h-full;

        .listing-link-card-icon {
          @apply transition-all duration-300 block mt-12;
        }

        > p {
          @apply transition-all duration-300 block;
        }
      }
    }
  }
}
</style>
