import { createApp } from "vue";
import Alert from "../../services/Alert";
const COOKIE_ACCEPTANCE_COOKIE = 'cookie-consent-notice-acceptance';

export default function (appData) {
    return createApp({
        data() {
            return appData;
        },

        methods: {
            revokeCookieConsent() {
                this.$cookies.set(COOKIE_ACCEPTANCE_COOKIE, 0, '1y');
                Alert.toastSuccess("You've revoked your consent.");
            },
        }
    })
}
