<template>
  <ul v-show="currentPage <= paginator.last_page && paginator.last_page > 1">
    <li v-if="currentPage > 1">
      <button
        type="button"
        @click="prevPage"
      >
        Previous
      </button>
    </li>
    <li
      v-for="page in pages"
      :class="{active: currentPage === page}"
    >
      <button
        v-if="page"
        type="button"
        @click="selectPage(page)"
        v-text="page"
      />
      <span v-else>...</span>
    </li>
    <li v-if="currentPage < paginator.last_page">
      <button
        type="button"
        @click="nextPage"
      >
        Next
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    paginator: {
      type: Object,
      required: true,
    },
  },

  computed: {
    allPages() {
      return _.range(1, this.paginator.last_page + 1);
    },

    pages() {
      if (this.allPages.length < 10) {
        return this.allPages;
      }

      let min, max, count;

      if (this.currentPage < 6) {
        return _.range(1, 8).concat([null, this.paginator.last_page]);
      }
      if (this.currentPage > this.paginator.last_page - 5) {
        return [1, null].concat(_.range(
          this.paginator.last_page - 6,
          this.paginator.last_page + 1,
        ));
      }

      return [1, null].concat(_.range(
        this.currentPage - 2,
        this.currentPage + 3,
      )).concat([null, this.paginator.last_page]);
    },

    currentPage() {
      return this.paginator.current_page;
    },
  },

  methods: {
    selectPage(page) {
      this.$emit('change-page', page);
    },

    prevPage() {
      this.$emit('change-page', this.currentPage - 1);
    },

    nextPage() {
      this.$emit('change-page', this.currentPage + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  @apply flex flex-row justify-center;
  li {
    button, span {
      @apply flex items-center justify-center rounded-full text-neutral-400;
      min-width: 2rem;
      height: 2rem;
    }

    span {
      min-width: unset;
      @apply px-1;
    }

    &.active {
      button {
        @apply bg-black text-white;
      }
    }
  }
}
</style>
