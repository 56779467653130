import Api from "../../services/Api";
import { createApp } from "vue";

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData,{
                activeListings: [],
                soldListings: [],
                showAllTestimonials: false,
                activeListingsHeader: 'Active Listings',
                soldListingsHeader: 'Recent Sales',
                totalSoldListings: 0
            });
        },

        computed: {
            visibleTestimonials(){
                if (this.showAllTestimonials){
                    return this.testimonials;
                }
                return [...this.testimonials].splice(0,3);
            }
        },

        methods: {
            toggleTestimonials() {
                this.showAllTestimonials = !this.showAllTestimonials;
            },

            scheduleTour(listing) {
                this.$modal.show('schedule-virtual-tour', {listing});
            },
        },

        mounted() {
            Api.get(`teams/${appData.teamID}/listings/active`, {})
                .then(response => {
                    this.activeListings = response.data;
                });

            Api.get(`teams/${appData.teamID}/listings/recent`, {})
                .then(response => {
                    this.soldListings = response.data;
                    this.totalSoldListings = response.total;
                });
        }
    })
}
