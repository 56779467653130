<template>
  <div class="expandable-card-container">
    <figure
      class="expandable-card text-center m-auto p-2"
    >
      <img
        :src="item.imageURL"
        :alt="item.alt"
        class="object-cover w-full h-full"
        width="20"
        height="20"
      >
      <figcaption
        class="sr-only"
        v-text="item.alt"
      />
    </figure>
  </div>
</template>
<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
    .expandable-card-container {
      @apply relative m-auto h-auto;
      max-width: 275px;

      @screen md {
        max-width: 150px;
      }
    }
    .expandable-card {
        @apply flex flex-col bg-white;
    }
</style>
