<template>
  <div class="tag-list-container"
       :class="{'vertical-layout': layout === 'vertical'}">
    <p class="tag-list-label" v-if="hasLabel" v-text="label"></p>
    <div class="tag-list">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    layout: {
      type: String,
      default: 'horizontal',
      validator: function(value) {
        return ['horizontal', 'vertical'].indexOf(value) > -1;
      },
    },
  },

  computed: {
    hasLabel() {
      return this.label && this.label.length;
    },

  },
}
</script>

<style lang="scss" scoped>
  .tag-list-container {
    @apply flex flex-row;

    .tag-list-label {
      @apply mr-3 mt-1 mb-2 whitespace-nowrap font-medium;
    }
    .tag-list {
      @apply flex flex-row flex-wrap items-center;

      > * {
        @apply mr-2 mb-1;
      }
    }

    &.vertical-layout {
      @apply flex-col items-start;

      .tag-list-label {
        @apply flex-none m-0 mb-2;
      }
      .tag-list {
        @apply flex-1 flex flex-col flex-nowrap items-start overflow-y-auto;
      }
    }
  }
</style>