<template>
  <div
    class="form-input"
    :class="inputClass"
  >
    <label
      v-if="label"
      :for="labelFor"
      :class="labelClass"
    >
      <span>
        {{ label }}
        <span
          v-if="required"
          class="text-secondary"
        >*</span>
      </span>

      <VIcon
        v-if="tooltip"
        v-tippy="{size: 'small'}"
        i="info-solid-circle"
        class="small-icon cursor-pointer text-neutral inline"
        :content="tooltip"
      />
    </label>
    <slot />
    <span
      v-if="withAddon"
      class="addon"
      v-text="withAddon"
    />
    <div
      v-if="errors"
      class="form-input-msg mt-3"
    >
      <div
        v-for="(error, k) in errors"
        :key="k"
        class="help-block flex gap-3 items-center text-danger"
      >
        <VIcon i="alert-circle" />
        <span class="text-sm leading-4">{{ error }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import VIcon from './VIcon';
import { computed } from 'vue';

const props = defineProps({
  labelFor: {
    type: String,
    default: '',
    required: false,
  },
  label: {
    type: String,
    default: '',
    required: false,
  },
  errors: {
    type: Object,
    default: () => {},
    required: false,
  },
  size: {
    type: String,
    default: 'base',
    validator: v => ['base', 'lg'].indexOf(v) > -1,
  },
  required: {
    type: Boolean,
    default: false,
    required: false,
  },
  tooltip: {
    type: String,
    default: '',
    required: false,
  },
  withAddon: {
    type: [String, Boolean],
    default: false,
    validator: v => v === false || v.length === 1,
  },
  labelClass: {
    type: String,
    default: '',
    required: false,
  },
});

const inputClass = computed(() => {
  return {
    error: hasErrors.value,
    'with-addon': !!props.withAddon,
    'input-lg': props.size === 'lg',
  };
});

const hasErrors = computed(() => {
  if (_.isArray(props.errors)) {
    return props.errors.length > 0;
  }

  if (_.isObject(props.errors)) {
    return props.errors.keys().length > 0;
  }

  return false;
});

</script>
