<template>
  <div>
    <div
      v-if="submitted"
      class="flex flex-col"
    >
      <h2 class="text-4xl text-center mb-2 mt-4 t-header">
        Thank you!
      </h2>
      <p class="text-center mt-2 mb-8">
        Your testimonial has been submitted.
      </p>
    </div>

    <div
      v-else
      class="flex flex-col flex-1 h-full"
    >
      <h2 class="t-header text-3xl mb-2 text-center">
        <span>Review Me</span>
      </h2>

      <p class="text-center mb-5">
        If you would be so kind, please provide a testimonial<br class="hidden sm:inline"> for your agent to use
      </p>

      <form
        class="flex-1 flex flex-col"
        @submit.prevent="submit"
      >
        <FormInput :errors="errors['rating']">
          <StarRating
            :default-rating="5"
            @rating-selected="handleRatingSelected($event)"
            @change="clearErrors('rating')"
          />
        </FormInput>

        <div class="mt-2">
          <FormInput
            class="input-lg"
            :errors="errors['testimonial']"
          >
            <textarea
              v-model="form.testimonial"
              rows="3"
              placeholder="Testimonial*"
              @change="clearErrors('testimonial')"
            />
          </FormInput>
        </div>

        <div>
          <FormInput
            class="input-lg"
            :errors="errors['clientName']"
          >
            <input
              v-model="form.clientName"
              type="text"
              placeholder="Name*"
              @change="clearErrors('clientName')"
            >
          </FormInput>
        </div>

        <div>
          <FormInput
            class="input-lg"
            :errors="errors['clientEmail']"
          >
            <input
              v-model="form.clientEmail"
              type="email"
              placeholder="Email*"
              @change="clearErrors('clientEmail')"
            >
          </FormInput>
        </div>

        <div class="flex mt-3">
          <FormInput :errors="errors['publish']">
            <div class="radio-buttons flex flex-col flex-1">
              <label
                for="yesPublish"
                :style="radioButtonLabelStyle"
              >
                <input
                  id="yesPublish"
                  v-model="form.publish"
                  type="radio"
                  name="publish"
                  value="1"
                  :class="{ active: form.publish === 'yes' }"
                  :style="radioButtonInputStyle"
                  @change="clearErrors('publish')"
                >
                I give this <span v-text="extendedTenantName" /> agent permission to post this rating and testimonial on various websites if they choose to do so.  (If so, only your initials will be published.  We won't ever publish your email address.)
              </label>

              <label
                for="noPublish"
                class="mt-4 mb-2"
                :style="radioButtonLabelStyle"
              >
                <input
                  id="noPublish"
                  v-model="form.publish"
                  type="radio"
                  name="publish"
                  value="0"
                  :class="{ active: form.publish === 'no' }"
                  :style="radioButtonInputStyle"
                  @change="clearErrors('publish')"
                >
                I <strong>do not</strong> give this <span v-text="extendedTenantName" /> agent permission to post this rating and testimonial on various websites.
              </label>
            </div>
          </FormInput>
        </div>
        <FormUserAgreement />
        <button
          type="submit"
          class="mt-4 btn btn-primary btn-lg w-full"
        >
          Submit
        </button>
        <FormUserConsent />
      </form>
    </div>
  </div>
</template>
<script>
import StarRating from '../StarRating';
import FormUserConsent from '../FormUserConsent';
import FormUserAgreement from '../FormUserAgreement';
import Api from '../../../services/Api.js';
import Alert from '../../../services/Alert.js';
import { submitWithRecaptcha } from '../../../recaptcha';

export default {
  components: {
    FormUserConsent,
    StarRating,
    FormUserAgreement,
  },

  props: {
    tenantName: {
      type: String,
      required: true,
    },
    agentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      form: {},
      errors: {},
      submitted: false,
      shortName: window.$siteContext.shortName,
    };
  },

  computed: {
    radioButtonInputStyle() {
      return {
        left: '0',
        position: 'absolute',
        top: '4px',
      };
    },
    radioButtonLabelStyle() {
      return {
        color: 'var(--color-black)',
        display: 'block',
        'font-size': 'inherit',
        'padding-left': '25px',
        position: 'relative',
        'text-transform': 'none',
      };
    },
    extendedTenantName() {
      if (['chicagoland', 'dallas', 'nw indiana', 'sw michigan', 'se michigan', 'se wisconsin'].includes(this.shortName.toLowerCase())) {
        return `${this.tenantName} Christie’s International Real Estate`;
      }
      return `${this.tenantName}`;
    },
  },

  methods: {
    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },
    handleRatingSelected(value) {
      this.form.rating = value;
      this.clearErrors('rating');
    },
    submit() {
      submitWithRecaptcha((recaptchaResponse) => {
        Api.post('/testimonials', {
          recaptchaResponse,
          ...this.form,
          agentPID: this.agentId,
        })
          .then(() => {
            this.clearErrors();
            this.submitted = true;
          })
          .catch(err => {
            if (err.validation && err.validation.errors) {
              this.errors = err.validation.errors;
            } else {
              Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
            }
          });
      });
    },
  },
};
</script>
