<template>
  <VModal
    :name="modalName"
    @before-open="init"
  >
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <span class="sr-only">Close modal</span>
      <VIcon i="close" />
    </button>
    <div class="modal-body py-5">
      <ContactForm
        endpoint="forms/contact-media-staff"
        :heading="heading"
        :show-client-type-options="false"
        :show-area-of-interest="false"
        comments-placeholder="Type your message"
        :payload="{ contactType: 'media', mediaStaffPid: contactPid }"
      >
        <template
          v-if="showStaffCard"
          #staff
        >
          <div class="max-w-[24rem] mx-auto">
            <StaffCard
              :staff="contact"
              name-classes="leading-5 mb-1"
              class="mb-6 mx-auto"
            />
          </div>
        </template>
      </ContactForm>
    </div>
  </VModal>
</template>

<script>
import VModal from '../../components/VModal.vue';
import ContactForm from './forms/ContactForm.vue';
import VIcon from '../../components/VIcon.vue';
import StaffCard from './StaffCard.vue';

export default {
  components: { StaffCard, VIcon, ContactForm, VModal },
  props: {
    modalName: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      default: 'Contact Me',
    },
    showStaffCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contact: {},
      contactPid: null,
    };
  },
  methods: {
    init(staff) {
      this.contact = staff;
      this.contactPid = staff.pid;
    },
    closeModal() {
      this.errors = {};
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
00
