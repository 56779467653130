import { createApp } from "vue";

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                descriptionExpanded: false,
            })
        },
    });
}
