<template>
  <div class="flex-1 max-w-xs text-primary-400 shadow-lg">
    <figure>
      <img
        class="h-48 w-full object-cover object-center border-neutral-100 p-3"
        :src="builder.image"
        :alt="builder.name"
      >
      <figcaption class="px-4 py-5">
        <h3
          class="t-header text-2xl text-left"
          v-text="builder.headline"
        />
        <div
          class="basic-html mt-3 mb-4"
          v-html="sanitizedContent"
        />

        <div class="flex flex-row space-x-2">
          <div v-if="builder.galleryImages">
            <button
              type="button"
              class="text-4xl text-primary hover:opacity-75"
              @click="fullscreenGallery"
            >
              <VIcon
                i="photo-circle"
                aria-hidden="true"
              />
              <span class="sr-only">Open photo gallery</span>
            </button>
          </div>
          <div v-if="builder.link">
            <a
              :href="builder.link"
              target="_blank"
              class="text-4xl text-primary hover:opacity-75"
            >
              <VIcon
                i="video-solid-circle"
                aria-hidden="true"
              />
              <span class="sr-only">Open virtual tour</span>
            </a>
          </div>
        </div>
      </figcaption>
    </figure>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useSanitizeContent } from '../composables/useSanitizeContent.js';
import Gallery from '../../services/Gallery';

const props = defineProps({
  builder: {
    type: Object,
    required: true,
  },
});

const { sanitizeContent } = useSanitizeContent();
const gallery = ref(null);
const sanitizedContent = ref('');

onMounted(() => {
  gallery.value = Gallery.create(props.builder.galleryImages);
  sanitizedContent.value = sanitizeContent(props.builder.content);
});

const fullscreenGallery = (event) => gallery.value.fullscreen(event.target);

</script>
