<template>
  <Menu
    as="div"
    class="relative inline-block text-left"
  >
    <div>
      <MenuButton
        :class="{'w-full': wideTrigger}"
        class="hover:text-neutral-300"
      >
        <span class="sr-only">Open options</span>
        <slot
          name="trigger"
          class="font-bold"
        />
        <VIcon
          v-if="triggerIcon"
          :i="triggerIcon"
          class="w-8"
        />
      </MenuButton>
    </div>

    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        :class="anchorClass"
        class="absolute mt-2 shadow-xl border-neutral-100 border bg-white text-neutral-400 focus:outline-none z-10 uppercase text-sm"
        style="min-width: 10rem;"
      >
        <div class="py-2">
          <MenuItem
            v-for="item in filteredItems"
            :key="item.key || item.label"
            v-slot="{ active }"
            :disabled="item.disabled"
            class="lg:whitespace-nowrap"
          >
            <RouterLink
              v-if="item.route"
              :to="item.route"
              class="block p-2 cursor-pointer"
              :class="[active ? 'bg-neutral-100' : '', 'block px-4 py-2']"
            >
              <VIcon
                v-if="item.icon"
                :i="item.icon"
                class="mr-2"
              />
              <span v-text="item.label" />
            </RouterLink>
            <a
              v-else
              class="block p-2 cursor-pointer"
              :class="[active ? 'bg-neutral-100' : '', 'block px-4 py-2']"
              :href="item.href || undefined"
              :target="item.newTab ? '_blank' : '_self'"
              @click="selectItem(item)"
            >
              <VIcon
                v-if="item.icon"
                :i="item.icon"
                class="mr-2"
              />
              <span v-text="item.label" />
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </Transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import VIcon from './VIcon';
import { dropdownProps } from '../Vue/UiConfigs';
import { useDropdown } from '../website/composables/useDropdown';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },

  ...dropdownProps,
});

const { anchorClass } = useDropdown(props);

const emit = defineEmits([
  'item-selected',
]);

const filteredItems = computed(() => props.items.filter(i => i.visible || _.isUndefined(i.visible)));

function selectItem(item) {
  if (_.isFunction(item.onClick)) {
    item.onClick();
  }
  emit('item-selected', item);
}
</script>
