import { ref, computed } from 'vue';
import CurrentUser from '../../services/CurrentUser.js';
import Api from '../../services/Api.js';
import Alert from '../../services/Alert.js';

const savedListings = ref(CurrentUser.savedListings ?? []);

function findListingIndex({ MLS, LN }) {
    return savedListings.value.findIndex(l => l.MLS === MLS && l.LN === LN);
}

function findListing({ MLS, LN }) {
    return savedListings.value.find(l => l.MLS === MLS && l.LN === LN);
}

export function useFavoriting({ MLS, LN }) {
    const isFavorited = computed(() => findListingIndex({ MLS, LN }) > -1);

    async function favorite() {
        if (isFavorited.value) {
            return;
        }

        const { data } = await Api.post('user/saved-listings', { LN, MLS, alerts: 0 });

        savedListings.value.push({ id: data.id, MLS, LN });

        Alert.toastSuccess('Listing saved');

        return data;
    }

    async function unfavorite() {
        const listing = findListing({ MLS, LN });
        if (!listing) {
            return;
        }

        await Api.delete(`user/saved-listings/${listing.id}`);

        savedListings.value.splice(findListingIndex({ MLS, LN }), 1);

        Alert.toastSuccess('Listing removed');
    }

    return {
        isFavorited,
        favorite,
        unfavorite,
    };
}
