import { ColorTranslator } from 'colortranslator';

class Color {
    normalize(color) {
        if (color === 'white') {
            return '#FFF';
        }
        if (color === 'black') {
            return '#000';
        }

        return $theme[`--color-${color}`] || color;
    }

    applyAlpha(color, alpha, format = 'RGBA') {
        let c = new ColorTranslator(this.normalize(color));
        c.setA(alpha);
        return c[format.toUpperCase()];
    }

    toHex(color, withAlpha = false) {
        return ColorTranslator[withAlpha ? 'toHEXA' : 'toHEX'](this.normalize(color));
    }

    toRgb(color, withAlpha = false) {
        return ColorTranslator[withAlpha ? 'toRGBA' : 'toRGB'](this.normalize(color));
    }

    toHexa(color) {
        return this.toHex(color, true);
    }

    toRgba(color) {
        return this.toRgb(color, true);
    }
}

export default new Color;
