<template>
    <div class="card">
        <div class="question" v-text="item.question"></div>
        <div class="answer" v-html="item.answer"></div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
}
</script>

<style lang="scss" scoped>
.card {
    @apply flex flex-col items-center w-80 mb-5 px-0 text-center;

    @screen md {
      @apply w-64 mb-0;
    }
    @screen xl {
      @apply w-80;
    }
    @screen 2xl {
      @apply w-96;
    }

    .question {
        @apply text-2xl font-alt text-primary-400;

        @screen md {
            @apply mt-2;
        }
        @screen 2xl {
            @apply text-3xl;
        }
    }

    .answer {
        @apply text-lg leading-6 text-neutral-300 mt-4 px-3;
    }
}
</style>
