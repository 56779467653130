<template>
  <div class="accordion-tabs">
    <slot />
  </div>
</template>

<script>
import { provide, reactive } from 'vue';

export default {
  props: {
    openMultiple: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const state = reactive({
      tabs: [],
    });

    function selectTab(index) {
      togglePanel(state.tabs[index], true);
    }

    function togglePanel(tab, isOpen = null) {
      tab = accordionPanel(tab);
      tab.isOpen = isOpen === null ? !tab.isOpen : isOpen;
    }

    function accordionPanel(tab) {
      if (tab.panelComponent) {
        // tab is MorphTabsPanel, so the actual VAccordionPanel is its child
        tab = tab.$children[0];
      }

      return tab;
    }

    function toggleAccordionPanel(panel) {
      if (props.openMultiple || !panel.isOpen) return;

      state.tabs.forEach(tab => {
        tab = accordionPanel(tab);

        if (tab !== panel) {
          tab.isOpen = false;
        }
      });
    }

    provide('AccordionProvider', {
      toggleAccordionPanel,
      selectTab,
    });

    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion-tabs {
  .accordion-tab:not(:first-child) {
    @apply border-t-0;
  }
}
</style>
