import mitt from 'mitt';

let emitter = mitt();

const $modal = {
    preventAutoClose: false,
};

const ModalPlugin = {
    install(app, options = {}) {
        // make sure we only install once
        if (app.config.globalProperties.$modal) return;

        this.$emitter = emitter;
        this.countShowing = 0;

        $modal.show = function (modal, params) {
            ModalPlugin.$emitter.emit('modal-show', { modal, params });
        };

        $modal.hide = function (modal) {
            ModalPlugin.$emitter.emit('modal-hide', { modal });
        }

        app.config.globalProperties.$modal = $modal;
    }
}

export function useModal() {
    return $modal;
}

export default ModalPlugin;
