import "./apps/navigation";
import "./apps/footer";

import minimal from "./apps/minimal";
import uiToolkit from "./apps/uiToolkit";
import home from "./apps/home";
import offices from "./apps/offices";
import officeDetails from "./apps/officeDetails";
import agents from "./apps/agents";
import agentSearch from "./apps/agentSearch";
import agentDetails from "./apps/agentDetails";
import listingDetails from "./apps/listingDetails";
import listingSearch from "./apps/listingSearch";
import account from "./apps/account";
import neighborhoodHome from "./apps/neighborhoodHome";
import neighborhoodDetails from "./apps/neighborhoodDetails";
import videoLibrary from "./apps/videoLibrary";
import register from "./apps/register";
import developments from "./apps/developments";
import teams from "./apps/teams";
import developmentDetails from "./apps/developmentDetails";
import developmentSearch from "./apps/developmentSearch";
import login from "./apps/login";
import passwordReset from "./apps/passwordReset";
import gdpr from "./apps/gdpr";
import staff from "./apps/staff";
import careers from "./apps/careers";
import simplePage from "./apps/simplePage";
import homeReport from "./apps/homeReport";
import mediaCenter from "./apps/mediaCenter";

import { provisionApp, registerGlobalComponents } from "../Vue/Global";
const requireContextComponents = require.context('../components', true, /\.vue$/i);
const requireContextWebsiteComponents = require.context('./components', true, /\.vue$/i);
const requireContextAgentsiteComponents = require.context('../agentsite/components', false, /\.vue$/i);

const app = $app.register({
    minimal,
    uiToolkit,
    offices,
    officeDetails,
    agents,
    agentSearch,
    agentDetails,
    listingDetails,
    listingSearch,
    home,
    neighborhoodHome,
    neighborhoodDetails,
    account,
    videoLibrary,
    register,
    developments,
    teams,
    developmentDetails,
    developmentSearch,
    login,
    passwordReset,
    gdpr,
    staff,
    careers,
    simplePage,
    homeReport,
    mediaCenter,
}).createApp();

provisionApp(app, {
    googleMaps: true
});

registerGlobalComponents(app, [
    requireContextComponents,
    requireContextWebsiteComponents,
    requireContextAgentsiteComponents,
]);

app.mount('#app');
