import Loader from './Loader';
import Alert from './Alert';

class Api {
    constructor() {
        this.v = 0;
    }

    version(version) {
        if (version) {
            this.v = version;
        }
        return this.v;
    }

    helper() {
        return new _apiHelper(this);
    }

    loader(loader, message) {
        return this.helper().loader(loader, message);
    }

    fullLoader(message) {
        return this.helper().fullLoader(message);
    }

    url(url) {
        if (!url) throw new Error('URL not specified in API request.');
        if (url.substr(0, 5) !== '/api/') {
            if (url.substr(0, 1) !== '/') {
                url = '/' + url;
            }
            url = '/api/v' + this.v + url;
        }
        return url;
    }

    request(config) {
        return this.helper().request(config);
    }

    get(url, config) {
        return this.helper().get(url, config);
    }

    delete(url, config) {
        return this.helper().delete(url, config);
    }

    post(url, data, config) {
        return this.helper().post(url, data, config);
    }

    put(url, data, config) {
        return this.helper().put(url, data, config);
    }

    patch(url, data, config) {
        return this.helper().patch(url, data, config);
    }

    upload(url, data, config, method) {
        return this.helper().upload(url, data, config, method);
    }
}

class _apiHelper {
    constructor(api) {
        this.api = api;
    }

    loader(loader, message) {
        this._loader = Loader.loader(loader, message);
        return this;
    }

    fullLoader(message) {
        this._loader = Loader.full(message);
        return this;
    }

    request(config) {
        return this.processRequest(
            axios.request(this._processConfig(config)),
        );
    }

    _processConfig(config) {
        if (typeof config === 'function') {
            config = config();
        }
        if (_.isObject(config) && config.url) {
            config.url = this.api.url(config.url);
        }

        config = this._addHeader(config, 'X-Client-Timezone-Offset', (new Date()).getTimezoneOffset());

        return config;
    }

    _addHeader(config, key, value) {
        config = config || {};

        if (!_.isObject(config.headers)) {
            config.headers = {};
        }

        config.headers[key] = value;

        return config;
    }

    processRequest(httpPromise) {
        return new Promise((resolve, reject) => {
            httpPromise
                .then(response => {
                    resolve(response.data);
                    if (typeof window.$$$onAjaxSuccess === 'function') {
                        window.$$$onAjaxSuccess();
                    }
                })
                .catch(e => {
                    let error = {};

                    const response = e.response;
                    if (!response) {
                        error = {
                            title: 'Unexpected Error',
                            message: 'Something unexpected happened. Please report this issue if it persists.',
                            httpCode: 500,
                        };
                        reject(error);
                        return;
                    }

                    response.data = response.data || {};

                    if (response.data.error) {
                        error = response.data.error;
                    }
                    if (response.data.data) {
                        error.data = response.data.data;
                    }
                    if (error.exception && error.exception === 'Illuminate\\Session\\TokenMismatchException') {
                        error.title = 'Session Token Expired';
                        error.message = 'Please refresh this page and try again!';
                    } else {
                        error.title = error.title || 'Unexpected Error';
                        error.message = error.message || 'Something unexpected happened. Please report this issue if it persists.';
                    }
                    error.httpCode = error.httpCode || response.status;

                    if (error.refreshPage) {
                        Alert.error(error).then(() => {
                            Loader.full('Redirecting to login form...');
                            window.location.reload();
                        });
                    } else {
                        reject(error);
                    }
                })
                .finally(() => {
                    if (this._loader) {
                        this._loader.done();
                    }
                });
        });
    }

    get(url, config) {
        url = this.api.url(url);
        config = this._processConfig(config);
        return this.processRequest(axios.get(url, config));
    }

    delete(url, config) {
        url = this.api.url(url);
        config = this._processConfig(config);
        return this.processRequest(axios.delete(url, config));
    }

    post(url, data, config) {
        url = this.api.url(url);
        config = this._processConfig(config);
        return this.processRequest(axios.post(url, data, config));
    }

    put(url, data, config) {
        url = this.api.url(url);
        config = this._processConfig(config);
        return this.processRequest(axios.put(url, data, config));
    }

    patch(url, data, config) {
        url = this.api.url(url);
        config = this._processConfig(config);
        return this.processRequest(axios.patch(url, data, config));
    }

    upload(url, data, config, method) {
        url = this.api.url(url);
        const formData = new FormData();
        for (const field in data) {
            formData.append(field, data[field]);
        }
        if (method) {
            formData.append('_method', method);
        }
        this._addHeader(config, 'Content-Type', 'multipart/form-data');
        config = this._processConfig(config);
        return this.processRequest(axios.post(url, formData, config));
    }
}

export default new Api();
