const _ = require('lodash');

function colorVariants(color) {
    const variants = {
        DEFAULT: `var(--color-${color})`,
    };

    _.range(100, 401, 100).forEach(num => {
        variants[num] = `var(--color-${color}-${num})`;
    });

    if (color === 'neutral') {
        variants[150] = `var(--color-${color}-150)`;
    }

    return variants;
}

module.exports = {
    content: [
        './resources/views/**/*.blade.php',
        './resources/assets/js/!(CMA)/**/*.vue',
        './resources/assets/js/!(CMA)/**/*.js',
    ],
    theme: {
        fontFamily: {
            sans: [
                'var(--font-family-default)',
                'system-ui',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                '"Noto Sans"',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"',
            ],
            alt: [
                'var(--font-family-alt)',
                'system-ui',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                '"Noto Sans"',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"',
            ],
            serif: [
                'var(--font-family-serif)',
                'Georgia',
                'Cambria',
                '"Times New Roman"',
                'Times',
                'serif',
            ],
            mono: ['Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
        },
        fontWeight: {
            thin: 'var(--font-weight-thin)',
            extralight: 'var(--font-weight-extra-light)',
            light: 'var(--font-weight-light)',
            normal: 'var(--font-weight-normal)',
            medium: 'var(--font-weight-medium)',
            semibold: 'var(--font-weight-semibold)',
            bold: 'var(--font-weight-bold)',
            extrabold: 'var(--font-weight-extra-bold)',
            black: 'var(--font-weight-black)',
        },
        colors: {
            neutral: colorVariants('neutral'),
            primary: colorVariants('primary'),
            secondary: colorVariants('secondary'),
            alternate: colorVariants('alternate'),

            transparent: 'transparent',
            black: 'var(--color-black)',
            white: 'var(--color-white)',
            'transparent-dark': 'var(--color-transparent-dark)',

            success: 'var(--color-success)',
            warning: 'var(--color-warning)',
            danger: 'var(--color-danger)',
        },
        borderWidth: {
            DEFAULT: '1px',
            0: '0',
            2: '2px',
            3: '3px',
            4: '4px',
            8: '8px',
        },
        extend: {
            height: {
                font: '1em',
            },
        },
        container: {
            center: true,
        },
    },
    plugins: [
        require('@tailwindcss/forms')({
            strategy: 'base',
        }),
    ],
};
