<template>
  <div>
    <div class="mb-9">
      <h3 class="text-4xl mb-5">Saved Searches</h3>
      <p class="text-sm" v-text="subtitle"></p>
    </div>
    <div class="saved-searches-container">
      <div class="searches-actions-container flex flex-row justify-between pt-2">
        <div class="self-end">
          <VDropMenu
              anchor="left"
              :items="[
                  {
                    label: 'Delete All Saved Searches',
                    onClick: deleteSavedSearches,
                  }
              ]"
          >
            <template v-slot:trigger>Actions</template>
          </VDropMenu>
        </div>
        <div class="text-right text-sm text-neutral">

          Showing {{ searches.length }} {{ searches.length === 1 ? 'search' : 'searches' }}

        </div>

      </div>
      <hr class="mt-2 mb-5">
      <SavedSearch v-for="(search, index) in searches"
                   :key="search.id"
                   :search="search"
                   :user="user"
                   @unsaved="removeSearch(index)"
      />
    </div>
  </div>

</template>

<script>
import Api from "../../../services/Api";
import Alert from "../../../services/Alert";
import VDropMenu from "../../../components/VDropMenu";
import SavedSearch from "./SavedSearch";

export default {
  components: {
    SavedSearch,
    VDropMenu,
  },

  props: {
    searches: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      form: {},
    }
  },

  methods: {
    removeSearch(index) {
      this.searches.splice(index, 1);
    },
    deleteSavedSearches() {
      Api.delete(`user/saved-searches`).then(() => {
        Alert.toastSuccess('Searches un-saved!');
        this.searches = [];
      });
    },
  },
}
</script>
