<template>
  <a
    v-lazy:background-image="item.image"
    :class="['hover-card', { 'always-show-info': isTouchDevice }]"
    :style="bgStyle"
    :href="item.url"
  >
    <div
      class="hover-overlay"
      :style="hoverOverlayStyle"
    />
    <div
      class="inner-border"
      :style="innerBorderStyle"
    >
      <div
        class="tile-cover"
        :style="tileCoverStyle"
      >
        <div
          class="w-full h-full flex items-center justify-center flex-col"
        >
          <div class="w-full flex flex-col items-center justify-center">
            <h3
              class="t-header mb-1 h-full items-center"
              :class="{
                'text-xl 2xl:text-2xl': textSize === 'sm',
                'text-2xl md:text-3xl': textSize === 'md',
              }"
              :style="headerTextStyle"
              v-text="item.name"
            />

            <div
              class="tile-content-divider"
              :style="tileContentDividerStyle"
            />

            <div class="info">
              <!-- eslint-disable -->
              <div
                class="mt-2 px-2"
                :class="{ 'text-sm': textSize === 'sm' }"
                v-html="item.summary"
              />
              <!-- eslint-enable -->
              <v-icon
                v-if="showIcon"
                class="text-5xl scale-up-on-hover mt-4"
                i="arrow-outline-circle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    useSquareAspectRatio: {
      type: Boolean,
      required: false,
      default: false,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    showContentDivider: {
      type: Boolean,
      required: false,
      default: false,
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    shadowOnText: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTile: {
      type: Boolean,
      required: false,
      default: false,
    },
    tileCoverColor: {
      type: String,
      required: false,
      default: 'rgba(0, 0, 0, 0)',
    },
    bgOverlayColor: {
      type: String,
      required: false,
      default: 'rgba(0, 0, 0, 0.75)',
    },
    innerBorderColor: {
      type: String,
      required: false,
      default: 'rgba(0, 0, 0, 0)',
    },
    textSize: {
      type: String,
      required: false,
      default: 'md',
    },
  },

  computed: {
    isTouchDevice() {
      return 'ontouchstart' in window || !!(navigator.msMaxTouchPoints);
    },
    bgStyle() {
      return {
        'padding-top': this.useSquareAspectRatio ? '100%' : 'inherit',
      };
    },
    innerBorderStyle() {
      return {
        border: '1px solid ' + this.innerBorderColor ?? 'transparent',
      };
    },
    hoverOverlayStyle() {
      return {
        'background-color': this.bgOverlayColor ?? 'transparent',
      };
    },
    tileCoverStyle() {
      return {
        'background-color': this.tileCoverColor ?? 'transparent',
        color: this.textColor,
        margin: this.showTile ? '' : '0',
        padding: this.showTile ? '' : '0',
        height: this.showTile ? '100%' : '',
      };
    },
    headerTextStyle() {
      return {
        'text-shadow': this.shadowOnText ? '1px 1px 2px #888' : 'none',
      };
    },
    tileContentDividerStyle() {
      return {
        color: this.showContentDivider ? 'var(--color-secondary)' : 'transparent',
        display: this.showContentDivider ? 'block' : 'none',
      };
    },
  },
};
</script>
<style lang="scss" scoped>

    // Note: This component is intentionally not opinionated about its own height, width, or margin.
    //       These properties are specified by the following container classes, defined in _misc.scss:
    //          - .hover-cards-grid-layout          (used in Neighborhoods and Developments Pages)
    //          - .hover-cards-three-in-row-layout  (used in Homepage, List With Us, and Agents: Join Us Pages)

    .hover-card {
        @apply flex grow-0 flex-shrink relative bg-cover bg-center bg-no-repeat bg-neutral text-white overflow-hidden;
        line-height: 1.2;

        .hover-overlay {
            @apply absolute block h-full w-full top-0 left-0 opacity-0;
            transition: all 0.3s ease-in-out;
        }

        .inner-border {
            @apply relative w-full top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center text-center m-4;
        }

        .tile-cover {
            @apply relative top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center text-center m-4 p-4 place-self-stretch;
            transition: background-color 0.3s ease;
        }

        h3 {
            @apply text-white flex justify-center;
            color: inherit;
            transition: margin-bottom 0.3s;
        }

        .tile-content-divider {
            @apply overflow-hidden opacity-0 h-0 w-0 m-1 border-t-2 border-solid;
            transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;

            @screen 2xl {
              @apply m-2;
            }
        }

        .info {
            @apply text-base opacity-0;
            height: 0;
            transition: height 0.3s ease, opacity 0.3s ease;
        }

        &:hover, &.always-show-info {
            .hover-overlay {
                @apply opacity-100;
            }

            h3 {
                text-shadow: none;
            }

            .tile-content-divider {
                @apply opacity-100 w-10;
            }

            .info {
                @apply opacity-100 h-full;
            }
        }
    }
</style>
