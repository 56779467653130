<template>
  <div>
    <div class="flex flex-col md:flex-row space-y-4 md:space-y-0  md:space-x-8 lg:justify-between">
      <a
        v-if="closingMilestone.image"
        :href="closingMilestoneUrl"
        target="_blank"
        class="flex-none"
      >
        <img
          :src="closingMilestone.image"
          :alt="`${closingMilestone.address} photo`"
          class="shadow-lg object-cover h-56 w-full md:w-64 lg:w-72"
        >
      </a>
      <div class="flex flex-col lg:flex-row w-full justify-between lg:space-x-12">
        <div class="mb-6 lg:mb-0 lg:w-2/5">
          <h5
            class="text-2xl mb-1 sm:text-lg lg:text-2xl font-bold leading-none"
          >
            <a
              :href="closingMilestoneUrl"
              target="_blank"
              v-text="closingMilestone.address"
            />
          </h5>
          <p
            class="sm:text-sm mb-4"
            v-text="closingMilestone.cityStateZip"
          />
          <p
            v-if="closingMilestone.createdDate"
            class="sm:text-sm mb-0"
          >
            Date Created: <span
              class="font-bold"
              v-text="closingMilestone.createdDate"
            />
          </p>
          <p
            v-if="closingMilestone.estClosingDate"
            class="sm:text-sm mb-2"
          >
            Estimated Close Date: <span
              class="font-bold"
              v-text="closingMilestone.estClosingDate"
            />
          </p>
        </div>
        <div class="w-full lg:w-2/5">
          <div class="flex flex-row space-x-2 lg:space-x-4">
            <div class="flex items-center">
              <img
                :src="closingMilestone.agent.profilePhoto"
                :alt="`${closingMilestone.agent.nameFML} photo`"
                class="w-16 h-16 rounded-full"
              >
            </div>

            <div class="flex flex-col">
              <h4
                class="text-xl mb-0.5 md:mb-0 font-bold"
                v-text="closingMilestone.agent.nameFML"
              />
              <p
                class="text-base mb-2 uppercase text-neutral-300 font-light"
                v-text="closingMilestone.agent.title"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end sm:justify-start">
        <a
          :href="closingMilestoneUrl"
          target="_blank"
        >
          <VIcon
            i="arrow-outline-circle"
            class="hover:text-neutral-400 text-5xl sm:text-3xl md:text-4xl"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from '../../../components/VIcon';

export default {
  components: { VIcon },
  props: {
    closingMilestone: {
      type: Object,
      required: true,
    },
  },

  computed: {
    closingMilestoneUrl () {
      return `${window.closingMilestoneUrl}${this.closingMilestone.identifier}`;
    },
    createdDate () {
      return this.closingMilestone.createdDate
        ? `Date Created: <span class="font-bold">${this.closingMilestone.createdDate}</span>`
        : '';
    },
    closingDate() {
      return this.closingMilestone.estClosingDate
        ? `Estimated Close Date: <span class="font-bold">${this.closingMilestone.estClosingDate}</span>`
        : '';
    },
  },
};
</script>
