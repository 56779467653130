<template>
  <div
    v-show="hasTabs"
    class="tabs-container"
    :class="{'vertical-layout': layout === 'vertical'}"
  >
    <template v-if="!(hideTabBarIfOnlyOneTab && state.tabs.length <= 1)">
      <div
        class="tabs"
        role="tablist"
      >
        <a
          v-for="(tab, $index) in state.tabs"
          :key="$index"
          :class="{ active: state.currentTab === $index, disabled: tab.isDisabled }"
          role="tab"
          @click="selectTab($index)"
        >
          <span v-text="tab.name" />
        </a>
      </div>
    </template>
    <div class="tabs-content">
      <div
        ref="tabSlot"
        class="relative"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { provide, reactive, onBeforeMount, onMounted, computed, useSlots } from 'vue';

const props = defineProps({
  layout: {
    type: String,
    default: null,
  },
  selectFirstTab: {
    type: Boolean,
    default: true,
  },
  hideTabBarIfOnlyOneTab: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['tab-selected']);

const slots = useSlots();

const state = reactive({
  currentTab: null,
  tabs: [],
});

const hasTabs = computed(() => state.tabs && state.tabs.length);

provide('TabsProvider', state);

onMounted(() => {
  if (hasTabs.value && props.selectFirstTab) {
    setTab(0);
  }
});

function selectTab(index) {
  if (state.tabs[index].isDisabled || index === state.currentTab) {
    return false;
  }
  setTab(index);
}

function setTab(index) {
  state.currentTab = index;

  emit('tab-selected', state.tabs[index]);
}
</script>

<style lang="scss" scoped>
.tabs-container {
  > .tabs {
    @apply flex flex-row;
    > a {
      @apply flex-1 py-4 px-8 cursor-pointer text-center uppercase border-b-4 border-neutral-100;
      &:hover {
        @apply border-neutral-200;
      }

      &.active {
        @apply border-black;
      }
    }
  }

  .tabs-content {
    @apply p-5;
    @screen lg {
      @apply p-10;
    }
  }
}

.tabs-container.vertical-layout {
  @apply flex items-start;
  > .tabs {
    @apply flex flex-col;
    @screen md {
      @apply w-1/4;
    }
    @screen xl {
      @apply w-1/5;
    }
    > a {
      @apply flex-1 py-4 pl-3 cursor-pointer text-left normal-case border-b-2 border-neutral-100 text-neutral;
      &.active {
        @apply font-bold text-black;
      }
    }
    > a:first-child {
      @apply border-t-2;
    }
  }

  .tabs-content {
    @apply pt-0 pr-0 flex-1;
    //@screen md {
    //  @apply w-3/4;
    //}
  }
}
</style>
