import { createApp } from 'vue';
import Api from '../../services/Api';
import Gallery from '../../services/Gallery';
import useMobileDetection from '../composables/useMobileDetection';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                similarListings: [],
                nearbyListings: [],
                errors: {},
                gallery: Gallery.create(appData.images),
                modal: {
                    title: null,
                    videoUrl: null,
                },
                contactFormPayload: payload => {
                    payload.listingMlsDetails = { LN: this.listing.LN, MLS: this.listing.MLS };
                    return payload;
                },
            });
        },
        setup() {
            const { isMobile } = useMobileDetection();

            return {
                isMobile,
            };
        },

        mounted() {
            // defer a second and a half
            // to allow app to fully render
            // before initiating API requests.
            // the app tends to want to draw
            // these results at the expense
            // of loading the top image gallery
            // and other above-the-fold elements
            setTimeout(() => {
                // Fetch nearby listings
                Api.get(`listings/${this.listing.MLS}/${this.listing.LN}/nearby`, {})
                    .then(response => {
                        this.nearbyListings = response.data;
                    });

                // Fetch similar listings
                Api.get(`listings/${this.listing.MLS}/${this.listing.LN}/similar`, {})
                    .then(response => {
                        this.similarListings = response.data;
                    });

                if (
                    window.location.hash === '#neighborhoods'
                    || window.location.hash === '#public-transit'
                    || window.location.hash === '#market-trends'
                ) {
                    this.openInfoTabs(0, window.location.hash);
                }
            }, 1500);

            if (this.videoLinkUrl && this.showVideoModalOnPageLoad) {
                this.openVideoModal();
            }
        },

        methods: {
            fullscreenGallery(event, showIdx = null) {
                if (showIdx !== null) {
                    this.gallery.setIndex(showIdx);
                }
                this.gallery.fullscreen(event.target);
            },
            initTab(tab) {
                if (tab.name === 'Market Trends') {
                    this.showMarketTrends = true;
                }
            },
            scheduleTour() {
                this.$modal.show('schedule-virtual-tour', { listing: this.listing });
            },
            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.linkUrl = modalData.linkUrl;
                this.modal.listingUrl = modalData.listingUrl;
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoTagline = modalData.tagline;
            },
            openVideoModal() {
                this.$modal.show('video-modal', {
                    url: this.videoLinkUrl,
                    linkUrl: null,
                    listingUrl: null,
                    title: this.listing.streetAddress,
                    description: null,
                    tagline: this.videoTagline || this.tagline || null,
                });
            },
            openFullFeatures() {
                return !this.$refs.fullFeaturesTab.$el.children[0].classList.contains('open')
                    ? this.$refs.fullFeaturesTab.$el.children[0].click()
                    : null;
            },
            openInfoTabs(index, hash) {
                if (history.pushState) {
                    history.pushState(null, null, `${hash}`);
                }
                window.scrollTo(0, this.$refs.infoTabs.$el.children[0].offsetTop - 50);
                if (this.$refs.infoTabs && this.$refs.infoTabs.$el.classList.contains('accordion-tabs')) {
                    return this.$refs.infoTabs.$el.children[index].children[0].click();
                }
                return !this.$refs.infoTabs.$el.children[0].children[index].classList.contains('active')
                    ? this.$refs.infoTabs.$el.children[0].children[index].click()
                    : null;
            },
        },
    });
}
