<template>
  <div
    class="picker-carousel select-none overflow-x-hidden"
    :class="[lastNavigated]"
    :style="{height: carouselHeight}"
  >
    <div class="nav-prev">
      <button
        type="button"
        :disabled="!hasPreviousPage"
        class="scale-up-on-hover"
        @click="prev"
      >
        <span class="sr-only">Previous</span>
        <VIcon
          i="caret-outline-rev-circle"
          :rotate="90"
        />
      </button>
    </div>
    <div class="nav-next">
      <button
        type="button"
        :disabled="!hasNextPage"
        class="scale-up-on-hover"
        @click="next"
      >
        <span class="sr-only">Next</span>
        <VIcon
          i="caret-outline-rev-circle"
          :rotate="-90"
        />
      </button>
    </div>
    <div
      :style="itemsStyle"
      @touchstart.prevent="startSwipe"
      @touchend.prevent="stopSwipe"
      @touchmove.prevent="moveHandler"
      @mousedown="startSwipe"
      @mouseup="stopSwipe"
      @mouseleave="stopSwipe"
      @mousemove="moveHandler"
    >
      <TransitionGroup
        tag="div"
        name="slide"
      >
        <div
          v-for="(item, index) in visibleItems"
          :key="item.id"
          :style="{'grid-area': 'd-' + index}"
        >
          <a
            role="button"
            class="option-card select-none"
            :class="{active: item.active}"
            @click="selectItem(index)"
          >
            <strong
              class="leading-4"
              v-text="item.dayOfWeek"
            />
            <div
              class="leading-4 mb-2 mt-3 text-2xl font-alt"
              v-text="item.dayOfMonth"
            />
            <div
              class="leading-4 uppercase text-xs"
              v-text="item.month"
            />
          </a>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>
<script>
function determinePerPage() {
  if (window.innerWidth < 450) {
    return 3;
  } else if (window.innerWidth < 575) {
    return 4;
  }
  return 7;
}

export default {
  emits: ['date-selected'],

  data() {
    return {
      items: [],
      currentPage: 0,
      perPage: 7,
      selectedItem: null,
      lastNavigated: 'next',
      carouselHeight: 'auto',
      xTransform: 0,
      xTouchStart: 0,
    };
  },

  computed: {
    nextPage() {
      return this.currentPage + 1;
    },
    visibleItems() {
      if (this.currentPage === this.totalPages - 1) {
        return this.items.slice(this.items.length - this.perPage);
      }
      return this.items.slice(
        this.currentPage * this.perPage,
        this.currentPage * this.perPage + this.perPage,
      );
    },
    nextItems() {
      if (!this.hasNextPage) {
        return [];
      }
      if (this.nextPage === this.totalPages - 1) {
        return this.items.slice(this.items.length - 4);
      }
      return this.items.slice(
        this.nextPage * this.perPage,
        this.nextPage * this.perPage + this.perPage,
      );
    },
    totalPages() {
      return Math.ceil(this.items.length / this.perPage);
    },
    hasPreviousPage() {
      return this.currentPage > 0;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages - 1;
    },
    itemsStyle() {
      return {
        transform: `translateX(${this.xTransform}px)`,
      };
    },
  },

  watch: {
    perPage() {
      this.currentPage = 0;
    },
  },

  created() {
    window.addEventListener('resize', _.debounce(this.init, 100));
  },

  mounted() {
    const min = new Date();

    if ((min.getHours() >= 21) || (min.getHours() === 20 && min.getMinutes() >= 30)) {
      min.setDate(min.getDate() + 1);
    }

    this.items = _.range(0, 14).map(days => {
      const date = new Date(min.valueOf());
      date.setDate(date.getDate() + days);
      return {
        id: days,
        active: false,
        date,
        dayOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()],
        dayOfMonth: date.getDate(),
        month: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        ][date.getMonth()],
        dateYMD: date.toISOString().substr(0, 10),
      };
    });

    this.init();
  },

  methods: {
    init() {
      this.perPage = determinePerPage();
    },

    selectItem(index) {
      this.items.forEach(item => {
        item.active = false;
      });
      const activeItem = this.visibleItems[index];
      activeItem.active = true;
      this.$emit('date-selected', activeItem.dateYMD);
    },

    startSwipe(event) {
      this.moving = true;
      this.xTouchStart = event.clientX || event.changedTouches[0].screenX;
    },

    stopSwipe(event) {
      // Introduced 5px buffer when swiping to accomodate more sensitive touch screens
      if (event.changedTouches && Math.abs(event.changedTouches[0].screenX - this.xTouchStart) < 5) {
        event.target.click();
      }
      this.moving = false;
      this.xTouchStart = 0;
      this.xTransform = 0;
    },

    handleSwipe(direction) {
      direction === 'left' ? this.next() : this.prev();
    },

    moveHandler: function (event) {
      if (this.moving) {
        let distance = (event.clientX || event.changedTouches[0].screenX) - this.xTouchStart;
        const multiplier = distance < 0 ? -1 : 1;
        distance = Math.abs(distance);
        if (distance > Math.min(Math.round(window.innerWidth * 0.3), 300)) {
          this.handleSwipe(multiplier > 0 ? 'right' : 'left');
          this.stopSwipe({});
        } else {
          this.xTransform = multiplier * Math.round(Math.sqrt(distance) * 2);
        }
      }
    },

    deselectItem() {
      this.selectedItem = null;
    },

    prev() {
      if (this.hasPreviousPage) {
        this.navigate('prev');
      }
    },

    next() {
      if (this.hasNextPage) {
        this.navigate('next');
      }
    },

    navigate(direction) {
      this.selectedItem = null;
      this.lastNavigated = direction;
      this.carouselHeight = this.$el.clientHeight + 'px';
      this.$nextTick(() => {
        this.carouselHeight = 'auto';
        direction === 'next' ? this.currentPage++ : this.currentPage--;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.picker-carousel {
  @apply mb-4 grid grid-cols-3 gap-2;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-areas: "prev-btn visible-items next-btn";

  > div > div {
    grid-area: visible-items;
    @apply grid grid-cols-3 gap-2;
    grid-template-areas: "d-0 d-1 d-2";

    @media (min-width: 450px) {
      @apply grid-cols-4 gap-2;
      grid-template-areas: "d-0 d-1 d-2 d-3"
    }
    @media (min-width: 575px) {
      @apply grid-cols-7;
      grid-template-areas: "d-0 d-1 d-2 d-3 d-4 d-5 d-6";
    }

    .option-card {
      @apply cursor-pointer rounded bg-neutral-100 h-24 text-neutral flex flex-col items-center justify-center;
      &.active {
        @apply bg-primary text-white;
      }
    }
  }

  .slide-leave-active, .slide-enter-active {
    transition: all 0.8s ease;
  }

  &.prev {
    .slide-leave-to {
      transform: translateX(95vw);
    }

    .slide-enter-from {
      transform: translate(-95vw);
    }
  }

  &.next {
    .slide-leave-to {
      transform: translateX(-95vw);
    }

    .slide-enter-from {
      transform: translateX(95vw);
    }
  }

  .nav-next, .nav-prev {
    button {
      @apply flex text-3xl text-black opacity-50;

      z-index: 1100;
      outline: none;

      &:disabled {
        @apply cursor-not-allowed opacity-25;
      }

      &:hover:not(:disabled) {
        @apply opacity-75;
      }
    }
  }

  .nav-prev {
    grid-area: prev-btn;
    @apply ml-2;
  }

  .nav-next {
    grid-area: next-btn;
    @apply mr-2;
  }

  .nav-next, .nav-prev {
    @apply flex flex-col justify-center items-center;
  }
}

.currently-sliding {
  @apply relative;
}
</style>
