<template>
  <div class="text-sm">
    <span v-text="remainingCharacters"></span> <span>characters left</span>
  </div>
</template>

<script>
export default {
  props: {
    maxCharacters: {
      type: Number,
      default: null,
    },
    passedCharacterCount: {
      type: Number,
      default: null,
    },
    itemToCount: {
      type: String,
      default: '',
    },
  },

  computed: {
    currentCharacterCount() {
      return this.itemToCount ? this.itemToCount.length : 0;
    },
    remainingCharacters() {
      return this.maxCharacters - this.currentCharacterCount;
    },
  },
}
</script>
