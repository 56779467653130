<template>
  <div
    class="icon-container"
    :class="containerClasses"
  >
    <SvgVue
      :style="{ transform: 'rotate(' + rotate + 'deg)', 'animation-duration': '0.6s' }"
      class="h-font block"
      :class="svgClasses"
      :icon="i"
      pointer-events="none"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import SvgVue from 'svg-vue3';

const props = defineProps({
  i: {
    type: String,
    required: true,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  spin: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the icon should be display inline with text (i.e. on top of the baseline)
   */
  inlineWithText: {
    type: Boolean,
    default: true,
  },
});

const containerClasses = computed(() => ({
  'inline-block leading-none': props.inlineWithText,
  block: !props.inlineWithText,
}));
const svgClasses = computed(() => ({
  'animate-spin': props.spin,
}));
</script>
