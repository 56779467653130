<template>
  <div class="super-search-container relative" v-click-away="hideDropdown">
    <label class="sr-only" :for="theInputId" v-text="labelText">
    </label>
    <input
        :id="theInputId"
        :name="theInputId"
        type="text"
        ref="searchInput"
        :placeholder="placeholder"
        :value="modelValue"
        @input="searchQueryChanged($event)"
        @keyup.down="nextResult"
        @change="handleChange"
        @keyup.esc="hideDropdown"
    >
    <div
        class="super-search-dropdown"
        ref="searchDropdown"
        :class="{
          'open-up': dropdownOverflowsViewport || position === 'top',
          'visible': showDropdown,
          'invisible': !showDropdown,
        }"
    >
      <div
          v-for="category in results"
          class="super-search-category"
          v-show="category.results.length"
      >
        <h4 class="px-4 py-2 font-bold">
          <VIcon class="text-2xl" :i="category.icon" />
          <span v-text="category.label"></span>
        </h4>
        <ul class="text-neutral">
          <li
              v-for="result in category.results"
              ref="result"
          >
            <a
                :href="result.url"
                @click="handleItemClick({...result, category: category ? category.category : ''}, $event)"
                :data-id="result.id"
                @keyup.down.prevent.stop="nextResult"
                @keydown.down.prevent
                @keyup.up.prevent.stop="prevResult"
                @keydown.up.prevent
            >
              <div
                  class="agent-team-headshot bg-image-cover"
                  v-if="result.headshot"
                  :style="{ backgroundImage: 'url(' + result.headshot + ')' }"
              ></div>
              <p v-text="result.label"></p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../../services/Api";
import Alert from "../../services/Alert";
import VIcon from "../../components/VIcon";

let activeResult = null;
let searchCount = 0;

export default {
  components: {
    VIcon
  },

  props: {
    categories: {
      type: Array,
      default: ['listing'],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Enter search term',
    },
    modelValue: {
      type: String,
      default: undefined,
    },
    position: {
      type: String,
      default: 'bottom',
      validator: function(value) {
        return ['auto', 'top', 'bottom'].indexOf(value) > -1;
      },
    },
    inputId: {
      type: String,
    },
    labelText: {
      type: String,
      default: 'Search input'
    },
  },

  emits: ['update:modelValue', 'item-selected'],

  data() {
    return {
      theInputId: null,
      selectedItem: null,
      inputVal: '',
      results: [],
      totalResults: 0,
      dropdownOverflowsViewport: false,
      viewportHeight: 0,
      viewportYPos: 0,
    };
  },

  computed: {
    searchQuery() {
      return this.modelValue === undefined ? this.inputVal : this.modelValue;
    },
    showDropdown() {
      return this.totalResults > 0 && this.searchQuery.length > 1;
    },
  },

  mounted() {
    if (this.position === 'auto') {
      window.addEventListener('resize', this.onResize);
      this.debouncedOnScroll = _.debounce(this.onScroll, 75);
      window.addEventListener('scroll', this.debouncedOnScroll);
    }

    this.theInputId = this.inputId || 'supersearch_' + Math.random().toString(36).substr(2);
  },

  beforeDestroy() {
    if (this.position === 'auto') {
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('scroll', this.debouncedOnScroll);
    }
  },

  methods: {
    searchQueryChanged($event) {
      this.$emit('update:modelValue', $event.target.value);
      this.debouncedSearch();
    },

    hideDropdown() {
      this.results = [];
      this.totalResults = 0;
    },

    debouncedSearch: _.debounce(function () {
      if (this.searchQuery.length > 1) {
        this.runSearch();
      } else {
        this.results = [];
        this.totalResults = 0;
      }
    }, 300),

    runSearch() {
      Api.post('super-search', {
        q: this.searchQuery,
        cat: this.categories,
        count: ++searchCount,
      }).then(response => {
        if (response.count < searchCount) {
          // delayed response, let's ignore
          return;
        }
        activeResult = null;
        this.results = response.data;
        this.totalResults = response.totalResults;
        setTimeout(() => this.updateDropdownOverflow());
      }).catch(err => {
        Alert.toastError(err);
      })
    },

    nextResult() {
      if (
          activeResult === null
          || activeResult === this.$refs.result.length - 1
      ) {
        activeResult = 0;
      } else {
        activeResult++;
      }
      $(this.$refs.result[activeResult]).find('a').focus();
    },

    prevResult() {
      if (activeResult < 1) {
        activeResult = null;
        $(this.$refs.searchInput).focus();
      } else {
        activeResult--;
        $(this.$refs.result[activeResult]).find('a').focus();
      }
    },

    handleItemClick(result, event) {
      event.preventDefault();
      this.selectResult(result);
    },

    selectResult(result) {
      this.$emit('update:modelValue', result.label);
      this.inputVal = result.label;
      this.$emit('item-selected', result);
      this.selectedItem = result;
      this.totalResults = 0; // note: this toggles the rendering of the typeahead results
    },

    handleChange(event) {
      if (this.results.length === 1 && this.results[0].results.length === 1) {
        let result = this.results[0].results[0];
        if (this.searchQuery === result.label) {
          this.selectResult(result);
          return;
        }
      }
      if (this.selectedItem !== null && this.searchQuery !== this.selectedItem.label) {
        this.$emit('item-selected', null);
        this.selectedItem = null;
      }
    },

    onResize() {
      this.viewportHeight = window.innerHeight;
    },

    onScroll() {
      this.viewportYPos = window.pageYOffset;
    },

    updateDropdownOverflow() {
      if (!this.$refs.searchDropdown || this.position !== 'auto') {
        this.dropdownOverflowsViewport = false;
        return;
      }

      let dropdownRect = this.$refs.searchDropdown.getBoundingClientRect();
      let inputRect = this.$refs.searchInput.getBoundingClientRect();

      if (inputRect.bottom + dropdownRect.height <= window.innerHeight || dropdownRect.top <= 0) {
        // if dropdown fits below input or overflows top of screen, show below
        this.dropdownOverflowsViewport = false;
      } else if (dropdownRect.bottom >= window.innerHeight && inputRect.top - dropdownRect.height >= 0) {
        // if dropdown overflows bottom of screen and fits above input, show above
        this.dropdownOverflowsViewport = true;
      }
    },
  },

  watch: {
    viewportHeight() {
      this.updateDropdownOverflow()
    },
    viewportYPos() {
      this.updateDropdownOverflow();
    },
  },
}
</script>
<style lang="scss">
.super-search-dropdown {
  @apply absolute z-10 left-0 right-0 bg-white border border-neutral-200 shadow;
  &.open-up {
    @apply bottom-full;
  }

  .super-search-category {
    @apply border-b border-neutral-200;
    &:last-child {
      @apply border-b-0;
    }
  }

  ul {
    @apply mb-2;
    > li {
      a {
        @apply block px-4 py-1 flex flex-row items-center;
        &:hover, &:active, &:focus {
          @apply bg-neutral-100;
        }
        .agent-team-headshot {
          @apply w-6 h-6 rounded-full mr-2;
        }
        p {
          @apply text-neutral;
        }
      }
    }
  }
}
</style>
