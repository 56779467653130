<template>
  <div>
    <div class="mb-6">
      <h3 class="text-2xl mb-2">Change Password</h3>
      <p class="text-sm" v-text="subtitle"></p>
    </div>
    <form class="password-form md:w-1/2" @submit.prevent="submit">
      <FormInput label="current password" :errors="errors.old_password" :required="true">
        <input type="password" v-model="form.old_password" placeholder="Enter current password">
      </FormInput>
      <FormInput label="new password" :errors="errors.new_password" :required="true">
        <input type="password" v-model="form.new_password" placeholder="Enter new password">
      </FormInput>
      <FormInput label="confirm" :errors="errors.confirm_password" :required="true">
        <input type="password" v-model="form.confirm_password" placeholder="Confirm new password">
      </FormInput>
      <div class="row">
        <button class="w-40 mt-4 btn btn-primary">
          Update
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import Alert from "../../../services/Alert";
import FormInput from "../../../components/FormInput";

export default {
  components: {
    FormInput,
  },

  props: {
    subtitle: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      form: {},
      errors: {
        old_password: null,
        new_password: null,
        confirm_password: null,
      }
    }
  },

  watch: (() => {
    let watchers = {};
    ['old_password', 'new_password', 'confirm_password'].map((value) => {
      watchers[`form.${value}`] = function (newVal, oldVal) {
        this.clearErrors(value);
      }
    })
    return watchers;
  })(),

  methods: {
    submit() {
      Api.put('user/account/password', this.form).then(response => {
        Alert.toastSuccess("Changes saved!");
        this.errors = {};
        this.form = {};
      }).catch(error => {
        this.errors = error.validation.errors;
        Alert.toastError('Whoops!', 'Something went wrong.');
      })
    },

    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-input {
  @apply w-full pr-2 py-1;
  @screen xl {
    @apply pr-36;
  }
  @screen 2xl {
    @apply w-4/5;
  }
}

.row {
  @apply flex w-full flex-col;
  @screen md {
    @apply flex-row;
  }
}
</style>
