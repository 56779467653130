<template>
  <!-- I am a dummy -->
</template>
<script>
export default {
  mounted() {
    switch (window.location.hash) {
      case '#saved-listings':
        this.$router.replace({name: 'account.listings'});
        break;
      case '#saved-searches':
        this.$router.replace({name: 'account.searches'});
        break;
      case '#change-password':
        this.$router.replace({name: 'account.password'});
        break;
      default: {
        this.$router.replace({name: 'account.contact'});
      }
    }
  }
}
</script>
