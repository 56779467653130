<template>
  <!--  binding attributes and listeners allows for passing props and listening to events from the parent component.
        e.g. <v-date-picker v-model="myDate" @change="updateOnChange"></v-date-picker>
        DatePicker documentation: https://github.com/mengxiong10/vue2-datepicker -->
  <div class="flex flex-row h-auto w-full">
    <DatePicker
        v-bind="$attrs"
        :value="modelValue"
        @update:value="$emit('update:modelValue', $event)"
        :clearable="false"
        ref="datePicker"
        @open="$modal.preventAutoClose = true"
        @close="$modal.preventAutoClose = false"
    >
      <template v-slot:icon-calendar>
        <div class="flex flex-row justify-center items-center"
             :class="{'invisible': $attrs.disabled}">
          <VIcon class="text-neutral cursor-pointer" i="calendar"/>
        </div>
      </template>
      <!-- Keeping clear icon here, but if date-picker clearable prop stays false, this will never show -->
      <template v-slot:icon-clear>
        <div class="flex flex-row justify-center items-center"
             :class="{'invisible': $attrs.disabled}">
          <VIcon class="text-neutral cursor-pointer text-sm" i="close"/>
        </div>
      </template>
      <template v-for="(_, name) in $slots" v-slot:[name]="scope">
        <slot :name="name" v-bind="scope || {}"/>
      </template>
    </DatePicker>
    <div class="flex flex-row justify-center items-center ml-2"
         v-show="showClearButton"
         @click="clear">
      <VIcon class="text-neutral cursor-pointer text-2xl" i="close-outline-circle"/>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

export default {
  components: {
    DatePicker,
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      //
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    showClearButton() {
      return this.clearable && !this.$attrs.disabled && this.$attrs.value;
    },
  },

  methods: {
    clear() {
      this.$emit('clear');
      this.$refs.datePicker.handleClear();
    },
  },
}
</script>
