<template>
  <button
    v-tippy="{
      onShow: showTippy,
      content: tippyContentToggle,
      zIndex: 30
    }"
    :class="{'flex flex-col justify-center items-center': showLabelBelowIcon}"
    @click.stop="toggleFavorite"
  >
    <VIcon
      v-if="isFavorited"
      key="solid"
      :class="{'mb-[0.6rem]': showLabelBelowIcon}"
      :i="favoriteIcon"
    />
    <VIcon
      v-else
      key="outline"
      :class="{'mb-[0.6rem]': showLabelBelowIcon}"
      :i="unFavoriteIcon"
    />
    <span
      class="sr-only"
      v-text="isFavorited ? 'Remove from favorites' : 'Add to favorites'"
    />
    <span
      v-if="includeTextLabel"
      :class="['text-xs md:text-sm text-center md:ml-3 md:uppercase', {'hidden lg:inline': compactButtons}]"
    >
      Favorite
    </span>
  </button>
</template>
<script>
import Alert from '../../services/Alert.js';
import { useFavoriting } from '../composables/useFavoriting.js';

export default {
  props: {
    listing: {
      type: Object,
      required: true,
    },
    favoriteIcon: {
      type: String,
      default: 'heart-solid-circle',
    },
    unFavoriteIcon: {
      type: String,
      default: 'heart-outline-circle',
    },
    tippyContent: {
      type: String,
      required: false,
      default: '',
    },
    includeTextLabel: {
      type: Boolean,
      default: false,
    },
    compactButtons: {
      type: Boolean,
      default: false,
    },
    showLabelBelowIcon: {
      type: Boolean,
      default: false,
    },

  },

  setup(props) {
    const {
      isFavorited,
      favorite,
      unfavorite,
    } = useFavoriting(props.listing);

    return {
      isFavorited,
      favorite,
      unfavorite,
    };
  },

  computed: {
    tippyContentToggle() {
      if (this.isFavorited) {
        return `Remove ${this.tippyContent}`;
      } else {
        return this.tippyContent;
      }
    },
  },

  methods: {
    async toggleFavorite() {
      try {
        this.isFavorited ? await this.unfavorite() : await this.favorite();
      } catch (error) {
        if (error.httpCode === 401) {
          this.$modal.show('login-modal', { callback: () => this.favorite() });
          this.$root.$refs.loginModal.loginPromise
            .catch(() => {
              this.reportError();
            });
        } else {
          this.reportError();
        }
      }
    },

    reportError() {
      Alert.toastError(
        'Error',
        this.isFavorited
          ? 'Could not remove listing from favorites.'
          : 'Could not favorite listing.',
      );
    },

    showTippy() {
      return !!this.tippyContent;
    },
  },
};
</script>
