<template>
  <div class="section-listing-info">
    <div class="image-container image-gallery-container mx-0">
      <div class="listing-image-overlay">
        <button
          v-if="listing.imageUrl"
          type="button"
          class="listing-image-button"
          @click="fullscreenGallery"
        >
          <span class="sr-only">Open photo gallery</span>
          <VIcon
            i="expand-photos"
            class="text-4xl text-white"
            :inline-with-text="false"
          />
          <span
            v-if="listing.images && listing.images.length > 1"
            class="listing-image-count"
          >
            {{ listing.images.length }}
          </span>
        </button>
      </div>
      <div v-if="listing.images">
        <SimpleSlider
          :items="listing.images"
          :gallery="gallery"
          :loop="listing.images.length > 1"
          fallback-alt=""
          :touch-drag="false"
        />
      </div>
      <div v-else>
        <img
          :src="listing.imageUrl"
          class="listing-detail-image"
          :alt="listing.address1"
        >
      </div>
      <div class="image-banners">
        <div class="hidden sm:block">
          {{ listing.status }}
        </div>
      </div>
    </div>

    <div class="p-6">
      <div class="flex flex-col xl:flex-row mb-3 items-center justify-center xl:justify-between flex-wrap xl:flex-nowrap">
        <div class="flex justify-center lg:justify-start text-primary-400 flex-wrap lg:flex-nowrap">
          <div
            v-if="listing.beds"
            class="text-center"
          >
            <div class="text-xs sm:text-sm uppercase">
              {{ listing.beds }} Bed
            </div>
          </div>
          <div
            v-if="listing.fullBaths"
            class="pl-1.5 sm:pl-3 border-l border-primary-400 ml-1.5 sm:ml-3 text-center"
          >
            <div class="text-xs sm:text-sm uppercase">
              {{ listing.fullBaths }} Bath
            </div>
          </div>
          <div
            v-if="listing.halfBaths"
            class="pl-1.5 sm:pl-3 border-l border-primary-400 ml-1.5 sm:ml-3 text-center"
          >
            <div class="text-xs sm:text-sm uppercase">
              {{ listing.halfBaths }} Half Bath
            </div>
          </div>
          <div
            class="pl-1.5 sm:pl-3 text-xs sm:text-sm border-l border-primary-400 ml-1.5 sm:ml-3"
            v-text="listing.formattedPrice"
          />
          <div
            v-if="listing.squareFootage"
            class="pl-1.5 sm:pl-3 border-l border-primary-400 ml-1.5 sm:ml-3 text-center"
          >
            <div class="text-xs sm:text-sm uppercase">
              {{ listing.squareFootage }} Sq Ft
            </div>
          </div>
        </div>

        <div class="flex flex-row items-center gap-x-1">
          <ScheduleTourButton
            :listing="listing"
            class="text-2xl text-neutral"
            tour-icon="calendar"
            tippy-content="Request Tour"
          />
          <FavoriteToggle
            :listing="listing"
            class="text-neutral hover:text-neutral-400 scale-up-on-hover ml-2 text-2xl"
            favorite-icon="heart-solid"
            un-favorite-icon="heart-outline"
            tippy-content="Favorite"
          />
        </div>
      </div>
      <hr class="mb-6">
      <h1 class="font-alt text-xl sm:text-2xl md:text-3xl text-center sm:text-left mb-2">
        {{ listing.address1 }}
      </h1>
      <div
        class="text-xs text-neutral-300 text-center sm:text-left sm:flex sm:flex-row sm:justify-between uppercase"
        v-text="listing.address2"
      />
      <p
        class="text-neutral-300 mt-3 mb-8"
        v-text="listing.shortDescription"
      />
      <a
        :href="listing.linkUrl"
        class="btn btn-light"
        target="_blank"
      >View Details</a>
    </div>
  </div>
</template>

<script>
import Gallery, { GalleryInstance } from '../../services/Gallery';

export default {
  props: {
    listing: {
      type: Object,
      required: true,
    },
    gallery: {
      type: GalleryInstance,
      default: () => new GalleryInstance(),
    },
  },

  data() {
    return {
      galleryBtn: Gallery.create(this.listing.images),
    };
  },

  methods: {
    fullscreenGallery(event) {
      this.galleryBtn.fullscreen(event.target);
    },
  },
};
</script>

<style scoped>
</style>
