<template>
  <div class="flex flex-col items-center w-full bg-neutral-100 py-4 pr-1 pl-4 lg:pl-8">
    <slot name="header" />
    <div
      class="flex flex-row items-center w-full"
      :class="isCentered ? 'justify-center' : 'justify-start'"
    >
      <div
        :class="headshotClasses"
        :style="headshotStyles"
      >
        <div v-if="loanOfficer.cardHeadshotUrl">
          <img
            v-lazy="loanOfficer.cardHeadshotUrl"
            class="w-full rounded-full"
            :alt="`${loanOfficer.name} photo`"
            width="90"
            height="90"
          >
        </div>
      </div>
      <div class="ml-4">
        <p
          class="t-header hover:opacity-75 text-lg md:text-xl lg:text-2xl"
          v-text="loanOfficer.name"
        />
        <div class="text-neutral uppercase text-xs mb-2">
          Proper Rate Loan Officer
        </div>
        <a
          v-if="loanOfficer.loanOfficerLink"
          :href="loanOfficer.loanOfficerLink"
          class="underline block hover:opacity-75"
          target="_blank"
        >
          Get approved today
        </a>
        <slot name="loan-officer-logo" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loanOfficer: {
      type: Object,
      required: true,
    },
    headshotClasses: {
      type: String,
      default: '',
    },
    headshotStyles: {
      type: Object,
      default: () => {
        return { flex: '0 0 33%' };
      },
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
