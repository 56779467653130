<template>
  <status-bar
    v-if="showPopup"
    bar-type="fixed-bottom"
    :action-buttons="[
      {text: 'I Accept', eventName: 'accept-gdpr'},
    ]"
    :center-content="true"
    class="border-t border-white"
    @close-status-bar="gdprAccepted"
    @accept-gdpr="gdprAccepted"
  >
    <template #content>
      <v-icon
        i="error"
        class="text-xl mx-2 md:mx-3 text-white"
      />
      <p class="text-sm max-w-xs sm:max-w-sm md:max-w-lg lg:max-w-2xl xl:max-w-4xl m-1 md:mx-4 md:my-1 text-left text-white">
        This website uses cookies in order to improve our user experience. If you accept or close this prompt, we
        will assume you agree with this. For more information about the cookies we use or to find out how you can
        disable cookies, click <a
          :href="policyUrl"
          class="hover:underline"
          rel="nofollow"
        >here</a>.
      </p>
    </template>
  </status-bar>
</template>

<script>
const COOKIE_ACCEPTANCE_COOKIE = 'cookie-consent-notice-acceptance';

export default {
  props: {
    policyUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showPopup: !this.$cookies.isKey(COOKIE_ACCEPTANCE_COOKIE),
    };
  },

  methods: {
    gdprAccepted() {
      this.$cookies.set(COOKIE_ACCEPTANCE_COOKIE, 1, '1y');
      this.showPopup = false;
    },
  },
};
</script>
