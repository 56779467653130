<template>
    <v-modal v-bind="$attrs">
        <slot></slot>
    </v-modal>
</template>
<script>
    export default {
        created() {
            console.error(
                "<modal/> is deprecated and replaced by <v-modal/> because " +
                "single word components are a bad idea. Blame Tikhon."
            );
        }
    }
</script>
