<template>
  <div class="star-ratings pt-1 pb-2 text-3xl text-center">
    <span v-for="star in [1,2,3,4,5]"
        :key="star"
        class="star text-black">
      <a
          role="button"
          @click.stop="selectRating(star)"
          @mouseover="setHoveredRating(star)"
          @mouseleave="clearHoveredRating"
      >
        <v-icon i="star-solid" v-show="showStarAsFilled(star)"></v-icon>
        <v-icon i="star-outline" v-show="!showStarAsFilled(star)"></v-icon>
      </a>
    </span>
  </div>
</template>
<script>

export default {
  props: {
    defaultRating: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      hoveredRating: null,
      selectedRating: null,
    };
  },

  methods: {
    clearHoveredRating() {
      this.hoveredRating = null;
    },

    selectRating(rating) {
      this.selectedRating = rating;
      this.$emit('rating-selected', rating);
    },

    setHoveredRating(rating) {
      this.hoveredRating = rating;
    },

    showStarAsFilled(star) {
      return (
        (this.hoveredRating !== null && this.hoveredRating >= star)
        ||
        (this.selectedRating !== null && this.selectedRating >= star)
      );
    }
  },

  mounted() {
    if (this.defaultRating) {
      this.selectRating(this.defaultRating);
    }
  },
}
</script>
