<template>
  <div class="expandable-card-container">
    <div
      ref="card"
      class="expandable-card"
      :class="[{expanded: expanded, 'is-first': isFirst, 'is-last': isLast }, expanded ? 'shadow-lg' : 'shadow-none', !passShadowClass? 'shadow-none border border-neutral-200' : '']"
      :style="{'z-index': expanded ? 39 : 39 - index}"
      @mouseenter="expand"
      @mouseleave="collapse"
      @touchstart.prevent="expand"
    >
      <div>
        <Transition
          name="image-fade"
          mode="out-in"
        >
          <div
            v-if="expanded"
            key="expandedImageContainer"
            class="expanded-image-content"
          >
            <div class="listing-image-container">
              <SimpleSlider
                v-if="item.images && item.images.length > 1"
                :items="item.images"
                :gallery="gallery"
                :touch-drag="false"
                :loop="item.images.length > 1"
              />
              <div v-else>
                <img
                  :src="item.imageUrl"
                  :alt="item.address1"
                  class="listing-image pointer-events-none"
                >
              </div>
              <div class="address-over-image">
                <div v-text="item.address1" />
                <div v-text="item.address2" />
              </div>
              <a
                :href="item.linkUrl"
                class="link-over-image"
              />
              <div
                v-if="showStatus"
                class="listing-status"
                v-text="item.status"
              />
              <img
                v-if="logoSrc"
                class="absolute bottom-1 right-1 h-6 bg-semi-transparent-gray-light p-1 rounded"
                :src="logoSrc"
                :alt="logoAlt"
              >
            </div>
          </div>
          <div
            v-else
            key="collapsedImageContainer"
            class="collapsed-image-content"
          >
            <div class="listing-image-container">
              <img
                :src="item.imageUrl"
                :alt="item.address1"
                class="listing-image pointer-events-none"
              >
              <img
                v-if="logoSrc"
                class="absolute bottom-1 right-1 h-6 bg-semi-transparent-gray-light p-1 rounded"
                :src="logoSrc"
                :alt="logoAlt"
              >
              <div
                v-if="showStatus"
                class="listing-status"
                v-text="item.status"
              />
            </div>
          </div>
        </Transition>
      </div>
      <div class="details-container">
        <Transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="expanded"
            key="expandedContainer"
            class="expanded-content"
          >
            <div class="flex flex-row justify-start items-center font-alt text-center">
              <div class="pr-3 xl:pr-5 border-r border-black">
                <div
                  class="text-lg 2xl:text-2xl"
                  v-text="item.beds"
                />
                <div class="text-xs 2xl:text-sm">
                  beds
                </div>
              </div>
              <div class="px-3 xl:px-5 border-r border-black">
                <div
                  class="text-lg 2xl:text-2xl"
                  v-text="item.fullBaths"
                />
                <div
                  class="text-xs 2xl:text-sm"
                  v-text="item.fullBathLabel"
                />
              </div>
              <div
                v-if="item.halfBaths"
                class="xl:px-5 border-r border-black"
                :class="item.halfBathLabel === 'half baths' ? `px-2`: `px-2.5`"
              >
                <div
                  class="text-lg 2xl:text-2xl"
                  v-text="item.halfBaths"
                />
                <div
                  class="text-xs 2xl:text-sm"
                  v-text="item.halfBathLabel"
                />
              </div>
              <div class="flex-1 pl-3 xl:pl-5 text-left">
                <div
                  class="text-lg 2xl:text-2xl"
                  v-text="item.formattedPrice"
                />
              </div>
            </div>
            <div class="text-neutral-400 mt-4 text-sm">
              <div><strong>Neighborhood:</strong> <span v-text="item.neighborhood" /></div>
              <div><strong>Property Type:</strong> <span v-text="item.propertyType" /></div>
              <div><strong>Square Footage:</strong> <span v-text="item.squareFootage" /></div>
            </div>
            <div class="mt-2 sm:absolute bottom-0 left-0 right-0 flex flex-row items-center gap-x-2">
              <FavoriteToggle
                :listing="item"
                class="hover:text-neutral-400 text-5xl scale-up-on-hover"
              />
              <ScheduleTourButton
                :listing="item"
                class="text-5xl"
              />
              <div class="flex-grow" />
              <a
                v-if="item.linkUrl"
                :href="item.linkUrl"
                class="text-5xl hover:text-neutral-400 scale-up-on-hover"
              >
                <VIcon i="arrow-outline-rev-circle" />
                <span class="sr-only">{{ item.address1 }} {{ item.address2 }} details</span>
              </a>
            </div>
          </div>
          <div
            v-else
            key="collapsedContainer"
            class="collapsed-content"
          >
            <div v-text="item.address1" />
            <div v-text="item.address2" />
            <div v-text="item.formattedPrice" />
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
<script>
import { GalleryInstance } from '../../services/Gallery';
import { useContentCard } from '../composables/useContentCard';

export default {
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'listing',
    },
    type: {
      type: String,
      default: '',
    },
    gallery: {
      type: GalleryInstance,
      default: () => new GalleryInstance(),
    },
    passShadowClass: {
      type: Boolean,
      default: false,
    },
    websiteLogo: {
      type: String,
      required: false,
      default: '',
    },
    tenantName: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props, { emit }) {
    const {
      expand,
      collapse,
      doExpand,
      doCollapse,
    } = useContentCard(props, emit);

    return {
      expand,
      collapse,
      doExpand,
      doCollapse,
    };
  },

  computed: {
    showStatus() {
      return this.item && this.item.status && this.item.status.toLowerCase() !== 'active';
    },
    logoSrc() {
      if (this.item) {
        if (this.item.isTenantListing) {
          return this.websiteLogo;
        } else if (this.item.brokerReciprocity && this.item.brokerReciprocity.required) {
          return this.item.brokerReciprocity.logo;
        }
      }
      return '';
    },
    logoAlt() {
      if (this.item) {
        if (this.item.isTenantListing) {
          return `${this.tenantName} logo`;
        } else if (this.item.brokerReciprocity && this.item.brokerReciprocity.required) {
          return 'Broker Reciprocity logo';
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.expandable-card-container {
  @apply relative m-auto;
  max-width: 330px;
  height: 30rem;
  @screen sm {
    height: 20rem;
  }
}

.expandable-card {
  @apply flex flex-col bg-white;

  .listing-image-container {
    @apply relative;

    .listing-image {
      @apply w-full object-cover object-center bg-neutral-100;
      height: 12rem;
      @screen md {
        height: 10rem;
      }
      @screen xl {
        height: 12rem;
      }
      transition: height 0.3s ease;
    }

    .listing-status {
      @apply absolute top-4 right-0 py-1 pl-2 pr-6 border-l-3 border-secondary bg-transparent-dark;
      @apply text-right text-white font-bold uppercase text-xs;
    }
  }

  @screen sm {
    @apply absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .details-container {
    @apply flex-1 m-4 relative;
    @screen sm {
      > div {
        @apply absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &:not(.expanded) {
    &:hover {
      @apply shadow-md;
    }

    @apply cursor-pointer;
  }

  transition: all 0.3s ease-in-out;

  &.expanded {
    top: -4rem;
    left: -4rem;
    right: -4rem;
    bottom: -8rem;

    &.is-last {
      left: -8rem;
      right: 0;
    }

    &.is-first {
      left: 0;
      right: -8rem;
    }

    @apply text-lg;
    transition: all 0.3s ease-in-out;

    .listing-image-container {
      .listing-image, :deep(.simple-slider-list > li) {
        @apply flex-1 border-b-4 border-black;
        height: 16rem;
        @screen md {
          height: 14rem;
        }
        @screen xl {
          height: 16rem;
        }
        img {
          @apply h-full object-cover w-full;
        }
      }

      > .listing-status {
        @apply absolute top-4 right-0 py-1 pl-2 pr-6 border-l-3 border-secondary bg-transparent-dark;
        @apply text-right text-white font-bold uppercase text-xs;
      }
    }
  }
}

.address-over-image {
  @apply absolute m-1 p-1 top-0 text-white text-base;
  text-shadow: 0 0 3px #222;
}

.link-over-image {
  @apply absolute top-0 left-1/4 w-1/2 z-10;
  height: 16rem;

  @screen md {
    height: 14rem;
  }

  @screen xl {
    height: 16rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.image-fade-enter-active, .image-fade-leave-active {
  transition: opacity 0.3s;
}

.image-fade-enter-from, .image-fade-leave-to {
  opacity: 1;
}
</style>
