<template>
  <div class="saved-listing-address-container flex flex-col  mb-4 border-b border-neutral-200">

    <div class="image-address-container flex flex-col">

      <div class="image-container sm:w-2/4 lg:w-4/12">
        <div class="relative">
          <div class="absolute top-2 right-0  py-1 pl-2 pr-6 border-l border-black bg-transparent-dark" v-if="showStatusTag">
            <span class="text-right text-white font-bold uppercase text-xs" v-text="listing.displayStatus"></span>
          </div>
          <a :href="listing.listingUrl">
            <img
                class="listing-image h-56 object-center bg-neutral"
                :src="listing.image"
            >
          </a>

        </div>
        <div class="flex flex-row justify-start items-center font-alt text-center py-3">
          <div class="pr-6 sm:pr-3 border-r border-neutral-300">
            <div class="text-xl sm:text-base leading-none" v-text="listing.bedrooms"></div>
            <div class="text-sm">beds</div>
          </div>
          <div class="px-6 sm:px-3 border-r border-neutral-300">
            <div class="sm:text-base text-xl leading-none" v-text="listing.bathrooms"></div>
            <div class="text-sm">baths</div>
          </div>
          <div class="flex-1 pl-6 sm:pl-3">
            <div class="text-2xl sm:text-base xl:text-xl text-right sm:text-center" v-text="listing.price"></div>
          </div>
        </div>
      </div>

      <div class="address-container sm:ml-5 lg:ml-10">
        <div class="mb-3">
          <a :href="listing.listingUrl">
            <div
                class="text-2xl mb-1 sm:text-lg lg:text-2xl font-bold leading-none"
                v-text="listing.streetAddress"
            ></div>
            <div class="sm:text-sm" v-text="listing.cityStateZip"></div>
          </a>
        </div>

        <div class="mb-5">
          <div class="sm:text-sm">
            Square Footage:
            <span class="font-bold" v-text="listing.sqft"></span>
          </div>
          <div class="sm:text-sm">
            Neighborhood:
            <span class="font-bold" v-text="listing.neighborhoodName"></span>
          </div>
          <div class="sm:text-sm">
            Property Type:
            <span class="font-bold" v-text="listing.type"></span>
          </div>
        </div>

        <FormInput label="GROUP UNDER" class="w-full lg:w-2/3">
          <select v-model="listing.savedListing.groupID" @change="onListingChange">
            <option :value="null" :selected="listing.savedListing.groupID === null">Ungrouped</option>
            <option
                v-for="group in user.groups"
                :value="group.id"
                v-text="group.name"
                :selected="listing.savedListing.groupID === group.id"
            ></option>
          </select>
        </FormInput>

      </div>

    </div>

    <div class="saved-listing-icon-container flex flex-col">
      <div class="saved-listing-icons inline-flex">
        <a
            @click="removeFavorite"
            class="hover:text-neutral-400 text-5xl sm:text-3xl md:text-4xl cursor-pointer"
            content="Click to remove from favorites"
            v-tippy
        >
          <VIcon i="heart-solid-circle" />
        </a>
        <a
            @click="scheduleTour"
            class="hover:text-neutral-400 text-5xl sm:text-3xl md:text-4xl cursor-pointer"
            content="Click to schedule a virtual tour"
            v-tippy
        >
          <VIcon i="calendar-outline-circle" />
        </a>
        <div class="flex-grow sm:hidden"></div>
        <a
            :href="listing.listingUrl"
            class="hover:text-neutral-400 text-5xl sm:text-3xl md:text-4xl cursor-pointer"
            content="View property details"
            v-tippy
        >
          <VIcon i="arrow-outline-circle" />
        </a>
      </div>

      <div class="py-2">
        <label
            class="form-toggle md:items-start"
            content="Select to be notified if this listing changes price or status"
            v-tippy
        >
          <input
              type="checkbox"
              v-model="listing.savedListing.alerts"
              :true-value="1"
              :false-value="0"
              @change="updateAlerts"
          >
          <span class="toggle-label sm:text-xs md:text-sm">
            Send Me Price/Status Alerts for this Listing
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import Alert from "../../../services/Alert";
import FormInput from "../../../components/FormInput";

export default {
  components: {
    FormInput,
  },

  props: {
    listing: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {},
      errors: {},
    }
  },

  computed: {
    showStatusTag() {
      return this.listing.displayStatus && this.listing.displayStatus.toLowerCase() !== "active";
    },
  },

  methods: {
    removeFavorite() {
      Api.delete(`user/saved-listings/${this.listing.savedListing.id}`).then(() => {
        Alert.toastSuccess('Listing un-favorited!');
          this.$emit('unfavorited');
      });
    },

    scheduleTour() {
      this.$emit('schedule-tour', this.listing);
    },

    updateAlerts() {
      Api.put(`user/saved-listings/${this.listing.savedListing.id}`, this.listing.savedListing)
        .then(() => {
          Alert.toastSuccess('Updated!', 'Price/Status alerts setting saved.');
        })
    },

    onListingChange() {
      Api.put(`user/saved-listings/${this.listing.savedListing.id}`, this.listing.savedListing)
          .then(() => {
            Alert.toastSuccess('Updated!', 'Group setting saved.');
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.saved-listing-address-container {
  @screen sm {
    @apply w-auto flex-row;
  }

  @screen md {
    @apply flex-row;
  }

  .image-address-container {
    @screen sm {
      @apply flex-row flex-grow;
    }

    .address-container {
      @screen sm {
        @apply flex-grow;
      }
    }

    .image-container {
      .listing-image {
        @apply object-cover w-full;

        @screen sm {
          @apply h-48;
        }
      }
    }
  }

  .saved-listing-icon-container {
    @screen sm {
      .saved-listing-icons{
        @screen md {
          @apply justify-end;
        }
      }
    }
  }
}
</style>
