<template>
    <section :style="sectionBackgroundStyle" class="bg-center bg-cover">
        <div class="container-outer">
            <div class="container-inner">
                <div class="banner-content mx-auto my-4 lg:my-8">
                    <h1 class="text-3xl lg:text-4xl hero-title-text">Let&rsquo;s Connect</h1>
                    <p class="text-xl md:text-2xl lg:text-3xl text-white font-alt">
                        For the best service and results when it comes to all of your real estate needs, reach out anytime.
                    </p>
                    <button class="btn hero-button uppercase mx-6 my-2 w-52 hover:opacity-75" @click="openContactModal">
                        {{ contactText }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        ownerType: {
            type: String,
            required: true,
            validator: (value) => {
                return ['team', 'personnel'].includes(value);
            },
        },
    },

    data() {
        return {
            backgroundImageUrl: "https://d2y1b8iotpw39u.cloudfront.net/images/website/atproperties/Contact-Banner.jpg",
        };
    },

    computed: {
        sectionBackgroundStyle() {
            return {
                backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("${this.backgroundImageUrl}")`,
            };
        },
        contactText() {
            return `Contact ${this.ownerType === 'team' ? 'Us' : 'Me'}`;
        },
    },

    methods: {
        openContactModal() {
            this.$modal.show('contact-modal');
        },
    },
}
</script>

<style lang="scss">
.banner-content {
    @apply flex flex-col items-center text-center;

    @screen lg {
        @apply w-3/5;
    }

    & > * {
        @apply mb-6;

        @screen md {
            @apply mb-8;
        }

        &:last-child {
            @apply mb-0;
        }
    }
}
</style>
