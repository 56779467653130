<template>
  <div class="slide-toggle-container">
    <div
        class="slide-toggle-label mr-2"
        v-if="leftLabel"
        :class="{
          muted: activePosition === 'both' && togglePosition === 'right',
          emphasized: activePosition === 'both' && togglePosition === 'left',
          disabled,
        }"
    >
      <p v-text="leftLabel"></p>
      <!-- Used to allot space for bold text to prevent content shifting upon style change -->
      <p v-if="activePosition === 'both'" class="font-bold text-transparent h-0" v-text="leftLabel"></p>
    </div>
    <div
        class="slide-toggle"
        :class="{
          active: activePosition !== 'both' && togglePosition === activePosition,
          'both-active': activePosition === 'both',
          disabled,
          'with-inner-label': showInnerLabel,
        }"
        @click="toggle"
    >
      <div class="slide-toggle-thumb-container">
        <div
            class="slide-toggle-label inner-label"
            :class="{disabled, 'label-right': togglePosition === 'left'}"
            v-if="showInnerLabel"
            v-text="innerLabel"
        ></div>
        <div
            class="slide-toggle-label placeholder"
            v-if="showInnerLabel"
            v-text="innerLabel"
        ></div>
        <div
            class="slide-toggle-thumb"
            :class="{'toggle-right': togglePosition === 'right'}"
        ></div>
      </div>
    </div>
    <div
        class="slide-toggle-label ml-2"
        v-if="rightLabel"
        :class="{
          muted: activePosition === 'both' && togglePosition === 'left',
          emphasized: activePosition === 'both' && togglePosition === 'right',
          disabled,
        }"
    >
      <p v-text="rightLabel"></p>
      <!-- Used to allot space for bold text to prevent content shifting upon style change -->
      <p v-if="activePosition === 'both'" class="font-bold text-transparent h-0" v-text="rightLabel"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftValue: {
      default: undefined,
    },
    rightValue: {
      default: undefined,
    },
    leftLabel: {
      type: String,
      default: "",
    },
    innerLabel: {
      type: String,
      default: "",
    },
    rightLabel: {
      type: String,
      default: "",
    },
    modelValue: {
      default: false,
    },
    activePosition: {
      type: String,
      default: "right",
      validator: function (value) {
        return ['left', 'right', 'both'].indexOf(value) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'toggled'],

  computed: {
    togglePosition() {
      return this.modelValue === this.valueRight ? 'right' : 'left';
    },
    valueLeft() {
      return this.leftValue !== undefined ? this.leftValue : this.activePosition === 'left';
    },
    valueRight() {
      return this.rightValue !== undefined ? this.rightValue : this.activePosition !== 'left';
    },
    showInnerLabel() {
      return this.innerLabel && this.activePosition !== 'both';
    },
  },

  methods: {
    toggle() {
      let newValue = (this.modelValue === this.valueLeft) ? this.valueRight : this.valueLeft;
      this.$emit('update:modelValue', newValue);
      this.$emit('toggled', newValue);
    },
  },
}
</script>

<style scoped lang="scss">
.slide-toggle-container {
  @apply flex flex-row items-center;
  .slide-toggle {
    @apply h-8 w-16 p-1 border border-neutral-200 rounded-2xl relative cursor-pointer;
    &.with-inner-label {
      @apply w-auto;
    }

    .slide-toggle-thumb-container {
      @apply w-full h-full flex flex-row justify-center items-center relative;
      .slide-toggle-thumb {
        @apply h-6 w-6 rounded-full bg-neutral-200 absolute;
        left: 0;
        transform: translate(0, 0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        &.toggle-right {
          left: 100%;
          transform: translate(-100%, 0);
        }
      }

      .inner-label {
        @apply absolute text-neutral-300 whitespace-nowrap px-3;
        left: 0;
        transform: translate(0, 0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        &.label-right {
          left: 100%;
          transform: translate(-100%, 0);
        }
      }
    }

    &.active {
      @apply bg-primary border-primary;
      &.disabled {
        @apply bg-neutral-300 border-neutral-300;
        .slide-toggle-thumb-container {
          .slide-toggle-thumb {
            @apply bg-neutral-150;
          }

          .inner-label {
            @apply text-neutral-150;
          }
        }
      }

      .slide-toggle-thumb-container {
        .slide-toggle-thumb {
          @apply bg-white;
        }

        .inner-label {
          @apply text-white;
        }
      }
    }

    &.both-active {
      @apply border-primary;
      &.disabled {
        @apply border-neutral-300 bg-neutral-150;
        .slide-toggle-thumb-container {
          .slide-toggle-thumb {
            @apply bg-neutral-300;
          }
        }
      }

      .slide-toggle-thumb-container {
        .slide-toggle-thumb {
          @apply bg-primary;
        }
      }
    }

    &.disabled {
      @apply cursor-default border-neutral-300 pointer-events-none bg-neutral-150;
      .slide-toggle-thumb-container {
        .slide-toggle-thumb {
          @apply bg-neutral-300;
        }
      }
    }
  }

  .slide-toggle-label {
    @apply text-neutral text-sm flex flex-col items-center justify-center;
    &.muted {
      @apply text-neutral;
    }

    &.emphasized {
      @apply text-primary font-bold;
    }

    &.disabled {
      @apply text-neutral-300;
    }

    &.placeholder {
      @apply h-0 text-transparent mx-6;
    }
  }
}
</style>
