class MediaCenterService {
    constructor() {
        this.resetVisibleNewsArticleCount();
        this.resetVisiblePressReleaseCount();
    }

    resetVisibleNewsArticleCount() {
        this.visibleNewsArticleCount = 5;
    }

    resetVisiblePressReleaseCount() {
        this.visiblePressReleaseCount = 5;
    }
}

export default new MediaCenterService();
