import { createApp } from 'vue';
import Alert from '../../services/Alert';
import Api from '../../services/Api';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                searchString: '',
            });
        },

        methods: {
            handleItemSelected(event) {
                if (event && event.url) {
                    window.location.href = event.url;
                }
            },

            submitSearch() {
                if (!this.searchString) {
                    return;
                }
                Api.post('/listings/search/init', {
                    searchString: this.searchString,
                }).then(response => {
                    setTimeout(() => {
                        window.location.href = response.data.url;
                    }, 100);
                }).catch(err => Alert.error(err));
            },

            scheduleTour(listing) {
                this.$modal.show('schedule-virtual-tour', { listing });
            },

            initVideoModal(modalData) {
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoUrl = modalData.url;
            },
        },
    });
};
