import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import AccountSimpleRouterView from './account/AccountSimpleRouterView';
import AccountApp from './account/AccountApp';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: AccountSimpleRouterView,
            children: [
                {
                    path: '',
                    component: AccountApp,
                },
                {
                    path: 'profile',
                    component: () => import('./account/AccountProfile'),
                },
                {
                    path: 'saved-listings',
                    component: () => import('./account/AccountSavedListings'),
                },
                {
                    path: 'saved-searches',
                    component: () => import('./account/AccountSavedSearches'),
                },
                {
                    path: 'searches',
                    redirect: '/saved-searches', // redirect to handle legacy path
                },
                {
                    path: 'closing-milestones',
                    component: () => import('./account/AccountClosingMilestones'),
                },
                {
                    path: 'cma-platform',
                    component: () => import('./account/AccountCmaReports'),
                },
                {
                    path: 'at-home-reports',
                    component: () => import('./account/AccountHomeReports'),
                },
                {
                    path: '/:notFound(.*)*',
                    redirect: '/', // redirect everything else to Account page
                },
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

export default function (data) {
    const app = createApp({
        data() {
            return data || {};
        },
    });

    app.use(router);

    return app;
}
