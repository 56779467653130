<template>
  <div class="agent-contact-form-container">
    <slot
      v-if="!submitted"
      name="header"
    >
      <h2 class="t-header text-3xl mb-4 text-center">
        <span>Contact Your Agent</span>
      </h2>
    </slot>
    <ContactForm
      endpoint="forms/contact-agent"
      :show-client-type-options="false"
      :show-area-of-interest="false"
      :[contactIdKey]="contact.id"
      :required-fields="['comments']"
      @submitted="submitted = true"
    >
      <template
        v-if="showAgentCard"
        #agents
      >
        <AgentCard
          :agent="contact"
          context="listing"
          class="mb-6"
          :is-centered="true"
        />
      </template>
    </ContactForm>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import ContactForm from './forms/ContactForm.vue';
import AgentCard from './AgentCard.vue';

const props = defineProps({
  contact: {
    type: Object,
    default: () => ({}),
  },
  contactType: {
    type: String,
    default: 'agent',
    validator: (value) => {
      return ['agent', 'team'].includes(value);
    },
  },
  showAgentCard: {
    type: Boolean,
    default: true,
  },
});

const submitted = ref(false);
const contactIdKey = computed(() => `${props.contactType}Id`);

</script>
