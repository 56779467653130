import CurrentUser from './CurrentUser';

class AppData {
    constructor() {
        _.forOwn(window.$appData || {}, (value, key) => {
            this[key] = value;
        });
    }

    currentUser() {
        return CurrentUser;
    }
}

export default new AppData;
