import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                searchQuery: '',
                showContactFormToggle: true,
                errors: {},
                contactFormPayload: {},
            });
        },

        methods: {
            handleItemSelected(event) {
                if (event === null) {
                    return;
                }
                if (['office', 'language'].includes(event.category)) {
                    window.location.href = `${this.baseUrl}/agents/search?page=1&${event.category}=${event.id}&order=shuffle`;
                } else if (event.url) {
                    window.location.href = event.url;
                }
            },

            search() {
                window.location.href = `${this.baseUrl}/agents/search?page=1&q=${this.searchQuery}&order=shuffle`;
            },

            scrollToContactForm() {
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },

            scrolled() {
                this.showContactFormToggle = window.scrollY < this.$refs.contactFormContainer.offsetTop - 240;
            },
        },

        created() {
            window.addEventListener('scroll', _.debounce(this.scrolled, 100));
        },
    });
}
