<template>
  <div class="p-5 lg:p-0 lg:w-1/2 lg:mb-10 mx-auto">
    <h2 class="text-center text-black text-3xl md:text-4xl t-header mb-5">
      In The News
    </h2>
    <!--    <p class="text-center mb-2 md:mb-4"></p>-->
  </div>

  <div class="flex flex-col lg:flex-row">
    <div class="flex-auto">
      <h3 class="text-center md:text-left text-2xl md:text-3xl t-header mb-6">
        Articles (<span v-text="articles.length" />)
      </h3>

      <MediaCenterItems
        v-slot="{ item }"
        :items="visibleArticles"
      >
        <MediaNewsArticle :article="item" />
      </MediaCenterItems>

      <MediaCenterLoader
        :source-items="articles"
        :initial-load="initialLoad"
        @add-item="loadItem"
      />
    </div>

    <MediaCenterTweets />
  </div>
</template>

<script setup>
import MediaCenterService from '../../apps/MediaCenter/MediaCenterService';
import MediaCenterLoader from './MediaCenterLoader';
import MediaCenterItems from './MediaCenterItems';
import MediaCenterTweets from './MediaCenterTweets';
import MediaNewsArticle from './MediaNewsArticle';
import { ref, inject, onBeforeMount } from 'vue';

const articles = inject('articles');
const visibleArticles = ref([]);

const initialLoad = MediaCenterService.visibleNewsArticleCount;

function loadItem(item) {
  visibleArticles.value.push(item);
}

onBeforeMount(() => {
  visibleArticles.value = articles.slice(0, initialLoad);
  MediaCenterService.resetVisiblePressReleaseCount();

  document.title = `${window.$siteContext.shortName} Real Estate Outlet News | @properties`;
});
</script>
