import { createApp } from "vue";
import { createRouter, createWebHistory, useRoute } from "vue-router";
import MediaCenterInTheNews from "../components/mediaCenter/MediaCenterInTheNews";
import MediaCenterMediaResources from "../components/mediaCenter/MediaCenterMediaResources";
import MediaCenterResourceShow from "../components/mediaCenter/MediaCenterResourceShow";

export default function (appData) {
    const app = createApp({
        provide() {
            return {
                articles: this.articles,
                pressReleases: this.pressReleases,
                staff: this.staff,
            }
        },

        data() {
            return _.merge(appData, {
                //
            });
        },

        computed: {
            showBanner() {
                return !(this.$route.meta?.hideTabs || false);
            },
        },
    });

    const router = createRouter({
        history: createWebHistory(),
        routes: [
            {
                path: '/',
                redirect: '/in-the-news',
            },
            {
                path: '/in-the-news',
                name: 'mediaCenter.inTheNews',
                component: MediaCenterInTheNews,
            },
            {
                path: '/media-resources',
                name: 'mediaCenter.mediaResources',
                component: MediaCenterMediaResources,
            },
            {
                path: '/:resource/:slug',
                name: 'mediaCenter.resource.show',
                component: MediaCenterResourceShow,
                meta: {
                    hideTabs: true,
                },
            },
        ],
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }

            if (to.name === 'mediaCenter.resource.show') {
                return { top: 0 };
            }

            return false;
        }
    });

    app.use(router);

    return app;
}
