<template>
  <div>
    <div class="mb-6">
      <h3 class="text-2xl mb-2">My Assigned Agent</h3>
      <p class="text-sm" v-text="subtitle"></p>
    </div>

    <form class="agent-form md:w-1/2">
      <AgentCard
          v-if="agent"
          :agent="agent"
          context="agent-search"
          class="my-6"
      >
        <template #contact-agent-link>
          <button type="button" @click="$modal.show('contact-agent-form');" class="underline hover:opacity-75">
            Contact Me
          </button>
        </template>
      </AgentCard>

      <FormInput label="SELECT A NEW AGENT:" class="form-input" :errors="errors['agentPID']">
        <SuperSearch
            :categories="['agent']"
            placeholder="Enter agent"
            position="bottom"
            @item-selected="agentSelected($event)"
            v-model="form.agentSearch"
        />
      </FormInput>

      <div class="row">
        <button type="button" @click="submit" class="w-40 mt-4 btn btn-primary">
          Update
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Api from "../../../services/Api";
import Alert from "../../../services/Alert";
import AgentCard from "../AgentCard";
import FormInput from "../../../components/FormInput";
import SuperSearch from "../SuperSearch";

export default {
  components: {
    SuperSearch,
    FormInput,
    AgentCard,
  },

  props: {
    agent: {
      type: Object,
      required: false,
    },
    agentPID: {
      type: Number,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },

  emits: ['agentUpdated'],

  data() {
    return {
      form: {
        agentPID: null,
        agentSearch: '',
      },
      errors: {
        agentPID: null,
      },
    }
  },

  watch: (() => {
    let watchers = {};
    ['agentPID'].map((value) => {
      watchers[`form.${value}`] = function (newVal, oldVal) {
        this.clearErrors(value);
      }
    })
    return watchers;
  })(),

  methods: {
    agentSelected(event) {
      this.form.agentPID = event ? event.id : null;
    },

    submit() {
      if (this.form.agentSearch.trim() === '') {
        this.form.agentPID = null;
      } else {
        Api.put('user/account/assigned-agent', this.form).then(response => {
          Alert.toastSuccess("Changes saved!");
          this.$emit('agentUpdated', response.data);
          this.errors = {};
        }).catch(error => {
          this.errors = error.validation.errors;
          Alert.toastError('Whoops!', 'Something went wrong.');
        })
      }
    },

    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },
  },

  mounted() {
    if (this.agentPID !== null) {
      this.agentSelected({ id: this.agentPID });
      this.form.agentSearch = this.agent?.name || '';
    }
  },
}
</script>

<style lang="scss" scoped>
.form-input {
  @apply w-full pr-2 py-1;

  @screen lg {
    @apply w-3/5;
  }
}

.row {
  @apply flex w-full flex-col;
  @screen md {
    @apply flex-row;
  }
}
</style>
