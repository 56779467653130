<template>
  <div class="flex-none" v-if="pressRelease.imageUrl">
    <RouterLink :to="resourceRoute">
      <img
          class="object-contain w-full h-72 md:w-64 md:h-44"
          :alt="pressRelease.headline"
          v-lazy="pressRelease.imageUrl"
      >
    </RouterLink>
  </div>
  <div class="flex-col flex-auto md:pl-6">
    <p
        class="text-sm text-neutral pt-4 md:pt-0 uppercase"
        v-text="pressRelease.publicationDate"
    ></p>
    <h6 class="text-xl font-bold mb-4">
      <a
          :href="pressRelease.pressReleaseUrl"
          v-text="pressRelease.headline"
      ></a>
    </h6>
    <p class="text-lg mb-4" v-text="pressRelease.excerpt"></p>
    <RouterLink
        class="uppercase"
        :to="resourceRoute"
    >
      Read more
      <VIcon class="ml-1" i="arrow"/>
    </RouterLink>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  pressRelease: {
    type: Object,
    required: true,
  },
})

const resourceRoute = computed(() => {
  return {
    name: 'mediaCenter.resource.show',
    params: {
      resource: props.pressRelease.id,
      slug: props.pressRelease.slug,
    },
  };
});
</script>
