import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                //
            });
        },

        methods: {
            selectFile(refName) {
                if (!refName || !this.$refs[refName]) return;
                this.$refs[refName].selectFile();
            },
            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoTagline = modalData.description;
            },
            getIconHTML(iconName) {
                return `<v-icon i="${iconName}"></v-icon>`;
            },
            getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            },
        },

        computed: {
            statusBarText() {
                switch (this.statusBarType) {
                    case 'sticky':
                        return 'This bar type will stick to the top and bottom of the screen when scrolled past.';
                    case 'sticky-top':
                        return 'This bar type will stick to the top of the screen when you scroll down past it.';
                    case 'sticky-bottom':
                        return 'This bar type will stick to the bottom of the screen when you scroll up past it.';
                    case 'fixed-top':
                        return 'This bar type will stick to the top of the screen at all times overlaying the content.';
                    case 'fixed-bottom':
                        return 'This bar type will stick to the bottom of the screen at all times overlaying the content.';
                    default:
                        return null;
                }
            },
        },
    });
}
