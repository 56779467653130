import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { createApp } from 'vue';
import { submitWithRecaptcha } from '../../recaptcha';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                form: {},
                errors: {},
                submitted: false,
                agentSearch: '',
            });
        },

        watch: (() => {
            const watchers = {};
            ['firstName', 'lastName', 'phoneNumber', 'email', 'password', 'password_confirmation', 'agentPID', 'acceptTermsOfUse'].forEach((value) => {
                watchers[`form.${value}`] = function (newVal, oldVal) {
                    this.clearErrors(value);
                };
            });
            return watchers;
        })(),

        methods: {
            clearErrors(fieldName) {
                if (this.errors && this.errors[fieldName]) {
                    this.errors[fieldName] = [];
                }
            },

            agentSelected(event) {
                this.form.agentPID = event ? event.id : null;
            },

            submitRegisterForm() {
                submitWithRecaptcha((recaptchaResponse) => {
                    const payload = {
                        recaptchaResponse,
                        ...this.form,
                    };
                    Api.post('/register', payload)
                        .then((response) => {
                            this.errors = {};
                            this.newUserId = response.data.id;
                            this.submitted = true;
                            window.scrollTo(0, 0);
                            Alert.toastSuccess('User Registered');
                        })
                        .catch((err) => {
                            if (err.validation && err.validation.errors) {
                                this.errors = this.prepareValidationMessages(err.validation.errors);
                            } else {
                                Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
                            }
                        });
                }, 'signup');
            },

            prepareValidationMessages(errors) {
                if (errors.password) {
                    errors.password.forEach((message, index) => {
                        if (message.toLowerCase().indexOf('password confirmation') > 0) {
                            errors.password_confirmation = [errors.password[index]];
                            errors.password.splice(index, 1);
                        }
                    });
                }
                return errors;
            },

            resendActivationEmail() {
                Api.post('/resend-activation', { userId: this.newUserId })
                    .then((response) => {
                        this.errors = {};
                        Alert.toastSuccess('Activation email has been sent again');
                    })
                    .catch((err) => {
                        if (err.validation && err.validation.errors) {
                            this.errors = err.validation.errors;
                        }
                    });
            },
        },

        mounted() {
            if (this.preselectedAgent) {
                this.agentSelected(this.preselectedAgent);
                this.agentSearch = this.preselectedAgent.nameFML;
            }
        },
    });
}
