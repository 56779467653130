<template>
  <VModal :name="modalName">
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <span class="sr-only">Close modal</span>
      <VIcon i="close" />
    </button>
    <div class="modal-body py-5">
      <AgentContactForm
        :contact="contact"
        :contact-type="contactType"
      >
        <template #header>
          <slot name="header" />
        </template>
      </AgentContactForm>
    </div>
  </VModal>
</template>
<script setup>
import { ref, inject } from 'vue';

const props = defineProps({
  modalName: {
    type: String,
    required: true,
  },
  contact: {
    type: Object,
    default: () => ({}),
  },
  contactType: {
    type: String,
    default: 'agent',
    validator: (value) => {
      return ['agent', 'team'].includes(value);
    },
  },
});
const modal = inject('modal');

const errors = ref({});

const closeModal = () => {
  errors.value = {};
  modal.hide(props.modalName);
};

</script>
