<template>
  <div class="p-5 lg:p-0 lg:w-1/2 lg:mb-10 mx-auto">
    <h2 class="text-center text-black text-3xl md:text-4xl t-header mb-5">Media Resources</h2>
    <!--    <p class="text-center mb-2 md:mb-4"></p>-->
  </div>

  <div class="flex flex-col lg:flex-row">
    <div class="flex-auto">
      <h3 class="text-center md:text-left text-2xl md:text-3xl t-header mb-6">
        Press Releases (<span v-text="pressReleases.length"/>)
      </h3>

      <MediaCenterItems :items="visiblePressReleases" v-slot="{ item }">
        <MediaPressRelease :press-release="item"/>
      </MediaCenterItems>

      <MediaCenterLoader
          :source-items="pressReleases"
          @add-item="loadItem"
          :initial-load="initialLoad"
      />
    </div>

    <MediaCenterPressKit :staff="staff" :hide-on-mobile="true"/>
  </div>
</template>

<script setup>
import MediaCenterService from "../../apps/MediaCenter/MediaCenterService";
import MediaCenterLoader from './MediaCenterLoader';
import MediaCenterItems from './MediaCenterItems';
import MediaPressRelease from './MediaPressRelease';
import MediaCenterPressKit from './MediaCenterPressKit';
import { onBeforeMount, ref, inject } from "vue";

const staff = inject('staff');

const pressReleases = inject('pressReleases');
const visiblePressReleases = ref([]);

const initialLoad = MediaCenterService.visiblePressReleaseCount;

function loadItem(item) {
  visiblePressReleases.value.push(item);
  MediaCenterService.visiblePressReleaseCount = visiblePressReleases.value.length;
}

onBeforeMount(() => {
  visiblePressReleases.value = pressReleases.slice(0, initialLoad);
  MediaCenterService.resetVisibleNewsArticleCount();

  document.title = 'Media Resources | @properties Media Center';
});
</script>
