<template>
  <TransitionRoot
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      @close="closeModal"
    >
      <div class="fixed inset-0 overflow-y-auto v-modal whitespace-nowrap">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              v-if="backdrop"
              class="fixed inset-0"
              style="background-color: rgba(80, 80, 80, 0.5);"
            />
            <div
              v-else
              class="fixed inset-0"
              style="background-color: rgba(80, 80, 80, 0.5);"
            />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl modal whitespace-normal"
              :class="modalSizeClasses"
            >
              <slot />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';
import ModalPlugin, { useModal } from '../Vue/ModalPlugin';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  // todo make this work
  backdrop: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    required: false,
    validator: (value) => {
      return ['sm', 'lg', 'xl', '2xl', '3xl'].indexOf(value) !== -1;
    },
  },
});

const modal = useModal();

const emit = defineEmits(['before-open']);

const isOpen = ref(false);

const modalSizeClasses = computed(() => ({
  'modal-sm': props.size === 'sm',
  'modal-lg': props.size === 'lg',
  'modal-xl': props.size === 'xl',
  'modal-2xl': props.size === '2xl',
  'modal-3xl': props.size === '3xl',
}));

function closeModal() {
  if (modal.preventAutoClose) {
    return;
  }
  isOpen.value = false;
}
function openModal() {
  isOpen.value = true;
}

function handleCloseRequest() {
  if (props.backdrop) {
    closeModal();
  }
}

onMounted(() => {
  ModalPlugin.$emitter.on('modal-show', open);
  ModalPlugin.$emitter.on('modal-hide', close);
});

onBeforeUnmount(() => {
  ModalPlugin.$emitter.off('modal-show', open);
  ModalPlugin.$emitter.off('modal-hide', close);
});
function open({ modal, params }) {
  if (modal !== props.name) return;
  emit('before-open', params);
  openModal();
  ModalPlugin.countShowing++;
}

function close(payload) {
  if (payload && payload.modal && payload.modal !== props.name) return;
  closeModal();
  ModalPlugin.countShowing--;
}
</script>
