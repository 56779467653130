// Function to sanitize HTML content and remove script tags from it

function removeScriptTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const scripts = div.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
}

export function useSanitizeContent() {
    const sanitizeContent = (content) => removeScriptTags(content);

    return { sanitizeContent };
}
