<template>
    <div class="card">
        <v-icon class="text-3xl text-black" i="quotes"></v-icon>
        <div class="testimonial w-4/5 lg:w-3/5" v-text="item.body"></div>
        <hr class="w-60 h-px border border-neutral-150 bg-neutral-150"/>
        <div class="rating" itemprop="Rating" itemscope itemtype="http://schema.org/Rating">
            <span v-for="star in stars"
                  :key="star"
                  :class="item.rating > star ? 'text-black' : 'text-neutral-200'"
                  class="star"
            >
                <v-icon i="star-solid"></v-icon>
            </span>
            <meta itemprop="worstRating" content="1">
            <meta itemprop="bestRating" content="5">
            <meta itemprop="ratingValue" :content="item.rating">
        </div>
        <div class="signature" v-html="item.initials"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stars: _.range(0, 5),
        };
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.card {
    @apply flex flex-col items-center text-center;

    .testimonial {
        @apply text-xl my-16 font-alt text-primary-400;
    }

    .rating {
        @apply my-6;

        .star {
            @apply mx-1;
        }
    }
}
</style>
