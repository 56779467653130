<template>
  <VModal
    class="map-modal-container"
    name="map-modal"
    size="xl"
    :backdrop="true"
  >
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <span class="sr-only">Close modal</span>
      <VIcon i="close" />
    </button>
    <div class="modal-header">
      <h3 class="modal-title">
        {{ title }}
      </h3>
      <p class="text-center mt-1 mb-10">
        {{ subtitle }}
      </p>
    </div>
    <div class="modal-content">
      <div class="m-6 h-full border border-black">
        <GMapMap
          ref="theMap"
          :zoom="zoom"
          :center="latLng"
          map-type-id="roadmap"
          :options="mapOptions"
          class="map-modal"
        >
          <template v-if="points">
            <GMapPolygon
              :paths="points"
              :options="polygonOptions"
            />
          </template>
          <template v-else>
            <GMapMarker :position="latLng" />
          </template>
        </GMapMap>
      </div>
    </div>
  </VModal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Interactive Map',
    },
    subtitle: {
      type: String,
      default: null,
    },
    latLng: {
      type: Object,
      required: true,
      default: null,
    },
    points: {
      type: Array,
      required: false,
      default: null,
    },
    zoom: {
      type: Number,
      required: false,
      default: 16,
    },
  },

  data() {
    return {
      mapOptions: {
        detectEvents: true,
        clickableIcons: false,
        disableDefaultUi: true,
        drawEnabled: false,
        fitBoundsOnDraw: true,
        fullscreenControl: false,
        mapTypeControl: false,
        maxZoom: 18,
        minZoom: 2,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: true,
      },
      polygonOptions: {
        strokeOpacity: '0.8',
        strokeWeight: '1',
        strokeColor: '#666666',
        fillColor: '#333333',
        fillOpacity: '0.4',
      },
    };
  },

  methods: {
    closeModal() {
      this.errors = {};
      this.$modal.hide('map-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
  .map-modal {
    height: 300px;
    @screen sm {
      height: 400px;
    }
    @screen lg {
      height: 500px;
    }
  }
</style>
