import { createApp } from 'vue';
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import DirBoundDictionary from '../../services/DirBoundDictionary';
import 'marker-clusterer-plus';
import { GMapCluster } from '@fawmi/vue-google-maps';
import gmapDefaultStyles from '../configs/GmapDefaultStyles';
import gmapMarkerIcon from '../configs/GmapMarkerIcon';
import gmapClusterStyles from '../configs/GmapClusterStyles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config.js';
import { clone } from '../../helpers';
import VueMultiselect from 'vue-multiselect';
const fullTailwindConfig = resolveConfig(tailwindConfig);

export default function (data) {
    let counter = 0;
    let searchCount = 0;
    let savedSearchPromiseResolver = null;

    return createApp({
        components: {
            GMapCluster,
            VueMultiselect,
        },
        data() {
            return _.merge(data, {
                showMap: true,
                mobileMapVisible: false,
                showMoreResultsOnScroll: true,
                showStickyBottomBar: true,
                infoWindow: {
                    open: false,
                    position: null,
                    options: { pixelOffset: { width: 0, height: -35 } },
                    item: {},
                },
                pauseMapSearch: true,
                showAdvancedFilters: false,
                neighborhoods: [],
                loadingNeighborhoods: false,
                loading: false,
                gmapMarkerIcon,
                gmapClusterStyles,
                mapOptions: {
                    zoomControl: true,
                    minZoom: 2,
                    maxZoom: 18,
                    mapTypeControl: false,
                    detectEvents: true,
                    drawEnabled: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: true,
                    styles: gmapDefaultStyles,
                },
                bounds: null,
                markers: [],
                listingsToMarkers: {},
                errors: {},
                contactFormPayload: {},
                schools: [],
                isPopoverOpen: false,
                loadingSchools: false,
                searchHeader: '',
                metaDescription: '',
                currentPage: 1,
                perPage: 20,
                totalResults: 0,
            });
        },

        computed: {
            schoolOptions() {
                return this.schools.map(s => s.name);
            },
            neighborhoodOptions() {
                return this.neighborhoods;
            },
            sortByLabel() {
                return this.sortBy.label || 'Sort By';
            },
            advancedFiltersToggleText() {
                return this.showAdvancedFilters ? 'Cancel' : 'More Filters';
            },

            labelBeds() {
                if (!this.appliedFilters.minBeds) {
                    return 'Beds';
                }

                return `${this.appliedFilters.minBeds}+ Beds`;
            },

            labelBaths() {
                if (!this.appliedFilters.minBaths) {
                    return 'Baths';
                }

                return `${this.appliedFilters.minBaths}+ Baths`;
            },

            labelPrice() {
                const formatPrice = new Intl.NumberFormat();
                const minPrice = this.appliedFilters.minPrice;
                const maxPrice = this.appliedFilters.maxPrice;

                if (!minPrice && !maxPrice) {
                    return 'Price';
                }

                return `${formatPrice.format(minPrice ?? 0)} - ${formatPrice.format(maxPrice ?? 0)}`;
            },

            sortedResults() {
                const sortByOption = this.sortByOptions.find(o => o.prop === this.sortBy.prop);

                if (!sortByOption) {
                    return this.searchResults;
                }

                return this.searchResults.sort((a, b) => {
                    if (a[sortByOption.sortProp] < b[sortByOption.sortProp]) {
                        return this.sortBy.dir === 'desc' ? 1 : -1;
                    }
                    if (a[sortByOption.sortProp] > b[sortByOption.sortProp]) {
                        return this.sortBy.dir === 'desc' ? -1 : 1;
                    }
                    return 0;
                });
            },

            priceOptionsMin() {
                return this[this.appliedFilters.listingType === 'rental' ? 'rentalPriceOptions' : 'priceOptions'].min;
            },

            priceOptionsMax() {
                return this[this.appliedFilters.listingType === 'rental' ? 'rentalPriceOptions' : 'priceOptions'].max;
            },

            selectedNeighborhoodsCount() {
                return this.appliedFilters.neighborhoods?.length || 0;
            },

            selectedNeigborhoods() {
                return this.appliedFilters.neighborhoods || [];
            },
            visibleSearchResults() {
                const startIndex = (this.currentPage - 1) * this.perPage;
                const endIndex = startIndex + this.perPage;
                return this.sortedResults.slice(startIndex, endIndex).map(this.formatSearchResult);
            },
        },
        watch: {
            'search.description': function (newVal, oldVal) {
                this.clearErrors('description');
            },
            'search.emailFrequency': function (newVal, oldVal) {
                this.clearErrors('emailFrequency');
            },
        },
        methods: {
            handleOptionChange(close) {
                close();
            },
            removeNeighborhood(id) {
                this.appliedFilters.neighborhoods = this.appliedFilters.neighborhoods.filter(n => n.id !== id);
            },
            initMap(customBounds) {
                const autoFitMap = !this.mapTriggersSearch || this.previousSearchId !== this.searchId;
                this.listingsToMarkers = {};
                this.markers = [];
                this.$refs.theMap.$mapPromise.then(map => {
                    const bounds = new google.maps.LatLngBounds();
                    this.searchResults.forEach((item, index) => {
                        item.markerID = index;
                        this.listingsToMarkers[item.id] = this.addMarker(item);
                        if (autoFitMap) bounds.extend({ lat: item.lat, lng: item.lng });
                    });

                    setTimeout(() => {
                        if (autoFitMap) {
                            this.pauseMapSearch = true;
                            map.fitBounds(bounds);
                        } else if (customBounds) {
                            map.fitBounds(customBounds);
                        }
                    });

                    this.showMap = this.mapTriggersSearch || this.searchResults.length > 0;
                });
            },

            mapTilesLoaded() {
                this.pauseMapSearch = false;
            },

            mapIdle() {
                if (!this.mapTriggersSearch) {
                    return;
                }

                if (this.pauseMapSearch) {
                    return;
                }

                if (this.infoWindow.open) {
                    return;
                }

                const bounds = this.getBoundingRectangle();

                if (this.previousBounds && _.isEqual(this.previousBounds, bounds)) {
                    return;
                }

                this.fetchResults();
                this.previousBounds = bounds;
            },
            goToTopOfResults() {
                window.scrollTo(0, this.$el.offsetTop);
                this.currentPage = 1;
            },
            makeUrlParams() {
                let urlParams = {};
                if (this.sortBy.prop) {
                    urlParams.sortBy = this.sortBy.prop;
                }
                if (this.sortBy.dir) {
                    urlParams.dir = this.sortBy.dir;
                }
                if (this.mapTriggersSearch) {
                    urlParams = _.merge(urlParams, this.getBoundingRectangle(true));
                }
                return (new URLSearchParams(urlParams)).toString();
            },

            prepareSearchFilters() {
                const filters = _.merge(
                    _.pickBy(this.appliedFilters, (value, key) => {
                        return !!value;
                    }), {
                        source: 'W',
                    },
                );

                if (filters.neighborhoods) {
                    filters.neighborhoods = filters.neighborhoods.map(n => n.id);
                }

                return filters;
            },

            getBoundingRectangle(forUrl = false) {
                const original = this.$refs.theMap.$mapObject.getBounds();
                if (!original) {
                    return null;
                }
                const json = original.toJSON();
                const bounds = {};
                ['south', 'north', 'east', 'west'].forEach(dir => {
                    bounds[forUrl ? dir.substring(0, 1) : DirBoundDictionary[dir]] = json[dir].toFixed(10);
                });
                return bounds;
            },

            mapTriggersSearchChanged() {
                // todo: not sure if anything needs to be done here
            },

            showMarkerCard(marker) {
                this.infoWindow.open = true;
                this.infoWindow.position = marker.position;
                this.infoWindow.item = marker.item;
            },

            openInfoWindow(item) {
                if (!this.showMap || window.innerWidth < parseInt(fullTailwindConfig.theme.screens.lg)) {
                    return;
                }
                const index = this.listingsToMarkers[item.id];
                if (this.markers[index]) {
                    this.showMarkerCard(this.markers[index]);
                }
            },

            fetchResults() {
                this.loading = true;
                this.goToTopOfResults();

                const searchFilters = this.prepareSearchFilters();
                const urlParams = this.makeUrlParams();

                const requestID = ++searchCount;

                this.searchResults = [];

                Api.post('listings/search', {
                    requestID,
                    searchString: this.searchString === this.appliedFilters.searchTerm ? '' : this.searchString,
                    searchFilters,
                    mbr: this.mapTriggersSearch ? this.getBoundingRectangle() : null,
                    commercial: this.commercial,
                })
                    .then(response => {
                        if (response.requestID < searchCount) {
                            // another search initiated, this response is stale
                            return;
                        }
                        const shouldPushToHistory = this.searchId !== response.searchId || urlParams !== this.urlParams;
                        this.previousSearchId = this.searchId;
                        this.searchId = response.searchId;
                        if (this.appliedFilters.neighborhoods && response.appliedFilters.neighborhoods) {
                            const existingNeighborhoodIds = new Set(this.appliedFilters.neighborhoods.map(n => n.id));
                            const newNeighborhoods = response.appliedFilters.neighborhoods.filter(n => !existingNeighborhoodIds.has(n.id));
                            this.appliedFilters.neighborhoods = [
                                ...this.appliedFilters.neighborhoods,
                                ...newNeighborhoods,
                            ];
                        } else {
                            this.appliedFilters = response.appliedFilters;
                        }
                        this.searchString = response.searchString;
                        this.urlParams = urlParams;
                        this.partialUrl = response.partialUrl;
                        this.searchHeader = response.searchHeader;
                        this.metaDescription = response.metaDescription;
                        this.searchResults = response.data;
                        this.totalResults = this.searchResults.length;

                        this.updateHeaderTitleAndMetaDescription();
                        this.initMap();

                        if (shouldPushToHistory) {
                            this.pushHistoryState();
                        }

                        this.loading = false;
                        this.changePage(1);
                    }).catch((error) => {
                        console.error(error);
                        Alert.toastError('Whoops!', 'Something went wrong.');
                    });
            },

            historyStateData() {
                return clone({
                    counter: ++counter,
                    searchId: this.searchId,
                    searchResults: this.searchResults,
                    appliedFilters: this.appliedFilters,
                    searchString: this.searchString,
                    sortBy: this.sortBy,
                });
            },

            pushHistoryState() {
                history.pushState(
                    this.historyStateData(),
                    '',
                    `${this.baseUrl}${this.partialUrl}` + (this.urlParams ? `?${this.urlParams}` : ''),
                );
            },

            saveSearch() {
                Api.post('listings/searches', {
                    searchID: this.searchId,
                    description: this.search.description,
                    source: 'W',
                    emailFrequency: this.search.emailFrequency,
                })
                    .then(() => {
                        Alert.toastSuccess('Search saved!');
                        this.$modal.hide('save-search-modal');
                        this.search.description = null;
                        this.search.emailFrequency = null;
                        if (savedSearchPromiseResolver) {
                            savedSearchPromiseResolver();
                            savedSearchPromiseResolver = null;
                        }
                    })
                    .catch(error => {
                        if (error.httpCode === 401) {
                            this.$modal.show('login-modal', {
                                callback: () => new Promise(resolve => {
                                    savedSearchPromiseResolver = resolve;
                                    this.saveSearch();
                                }),
                            });
                            this.$refs.loginModal.loginPromise.then(() => {
                                this.saveSearch();
                            });
                        } else if (error.validation && error.validation.errors) {
                            this.errors = error.validation.errors;
                        } else {
                            console.log(error);
                            Alert.toastError('Whoops!', 'Could not save search.');
                            this.$modal.hide('save-search-modal');
                        }
                    });
            },

            formatSearchResult(item) {
                item.url = item.listingUrl;
                item.imageAlt = `${item.streetAddress} photo`;
                return item;
            },

            setSortBy(option) {
                if (this.sortBy.prop === option.prop && this.sortBy.dir === option.dir) {
                    return;
                }

                this.sortBy = option;
                this.urlParams = this.makeUrlParams();
                this.pushHistoryState();
                this.goToTopOfResults();
            },

            addMarker(item) {
                if (
                    _.isUndefined(item.lat)
                    || _.isUndefined(item.lng)
                    || isNaN(parseFloat(item.lat))
                    || isNaN(parseFloat(item.lng))
                ) {
                    console.error('item missing lat or lng property');
                    return false;
                }

                this.markers.push({
                    position: { lat: item.lat, lng: item.lng },
                    item,
                });

                return this.markers.length - 1;
            },

            applyFilters() {
                this.fetchResults();
                this.showAdvancedFilters = false;
            },

            toggleAdvancedFilters() {
                this.showAdvancedFilters = !this.showAdvancedFilters;
                if (this.showAdvancedFilters) {
                    window.scrollTo(0, this.$el.offsetTop);
                }
            },

            toggleMobileMap() {
                this.mobileMapVisible = !this.mobileMapVisible;
                if (this.mobileMapVisible) {
                    window.scrollTo(0, 120);
                }
            },

            scrollToContactForm() {
                this.showMoreResultsOnScroll = false;
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },

            scrolled() {
                this.showStickyBottomBar = window.scrollY < this.$refs.contactFormContainer.offsetTop - 740;
            },

            handleSuperSearchSelected(event) {
                if (event && event.category && event.category === 'neighborhoodSearch') {
                    this.searchString = null;
                    if (!this.appliedFilters.neighborhoods.some(n => n.id === event.id)) {
                        this.appliedFilters.neighborhoods.push(event);
                    }
                    this.applyFilters();
                } else if (event && event.url) {
                    window.location.href = event.url;
                }
            },

            clearErrors(fieldName) {
                if (this.errors && this.errors[fieldName]) {
                    this.errors[fieldName] = [];
                }
            },

            scheduleTour(listing) {
                this.errors = {};
                this.$modal.show('schedule-virtual-tour', { listing });
            },

            lookupSchools(query) {
                if (!query) {
                    return;
                }

                this.loadingSchools = true;
                Api.get('schools', {
                    params: {
                        q: query,
                    },
                })
                    .then(response => {
                        this.schools = response.data;
                        this.loadingSchools = false;
                    });
            },

            lookupNeighborhoods(query) {
                if (!query) {
                    return;
                }

                this.loadingNeighborhoods = true;
                Api.get('neighborhoods/search', {
                    params: {
                        q: query,
                    },
                })
                    .then(response => {
                        this.neighborhoods = response.data;
                        this.loadingNeighborhoods = false;
                    });
            },

            resetPrice() {
                this.appliedFilters.minPrice = undefined;
                this.appliedFilters.maxPrice = undefined;
            },

            changePage(page) {
                this.currentPage = page;
            },

            updateHeaderTitleAndMetaDescription() {
                const metaTag = document.querySelector('meta[name="description"]');
                const headerPageTitle = document.querySelector('.search-page h1');

                document.title = this.metaDescription;

                if (headerPageTitle) {
                    headerPageTitle.innerText = this.searchHeader;
                }

                if (metaTag) {
                    metaTag.setAttribute('content', this.metaDescription);
                }
            },

        },

        mounted() {
            if (!this.searchId) {
                this.showMap = false;
                this.showAdvancedFilters = false;
            }
            this.previousSearchId = this.searchId;
            history.replaceState(this.historyStateData(), '');

            this.fetchResults();
            window.addEventListener('popstate', event => {
                this.searchId = event.state.searchId;
                this.searchResults = event.state.searchResults;
                this.appliedFilters = event.state.appliedFilters;
                this.searchString = event.state.searchString;
                this.sortyBy = event.state.sortBy;

                this.goToTopOfResults();
                this.initMap();
            });
        },
    });
}
