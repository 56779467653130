<template>
    <h3 class="slider-header text-3xl t-header">
        <span v-text="headerText"></span> 
        (<span v-text="listingCount"></span>)
    </h3>
</template>

<script>
export default {
    props: {
        headerText: {
            type: String,
            default: ''
        },
        listingCount: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style>
.slider-header {
    @apply text-center mb-10;
}
</style>
