<template>
  <div>
    <div class="flex flex-col md:flex-row space-y-4 md:space-y-0  md:space-x-8 lg:justify-between">
      <a
        v-if="cmaReport.listingImage"
        :href="cmaReportUrl"
        target="_blank"
        class="flex-none"
      >
        <img
          :src="cmaReport.listingImage"
          :alt="`${cmaReport.title} photo`"
          class="shadow-lg object-cover h-56 w-full md:w-64 lg:w-72"
        >
      </a>
      <div class="flex flex-col lg:flex-row w-full justify-between lg:space-x-12">
        <div class="mb-6 lg:mb-0 lg:w-2/5">
          <h5
            class="text-2xl mb-1 sm:text-lg lg:text-2xl font-bold leading-none"
          >
            <a
              :href="cmaReportUrl"
              target="_blank"
              v-text="cmaReport.title"
            />
          </h5>
          <p
            class="sm:text-sm mb-1"
            v-text="cmaReport.address"
          />
          <p
            class="sm:text-sm mb-4"
            v-text="cmaReport.cityStateZip"
          />
          <p
            v-if="cmaReport.createdDate"
            class="sm:text-sm mb-0"
          >
            Date Created: <span
              class="font-bold"
              v-text="cmaReport.createdDate"
            />
          </p>
        </div>
        <div
          class="w-full lg:w-2/5 space-y-8"
        >
          <div
            v-if="cmaReport.agent"
          >
            <div class="flex flex-row space-x-2 lg:space-x-4">
              <div class="flex items-center">
                <img
                  :src="cmaReport.agent.profilePhoto"
                  :alt="`${cmaReport.agent.nameFML} photo`"
                  class="w-16 h-16 rounded-full"
                >
              </div>

              <div class="flex flex-col">
                <h4
                  class="text-xl mb-0.5 md:mb-0 font-bold"
                  v-text="cmaReport.agent.nameFML"
                />
                <p
                  class="text-base mb-2 uppercase text-neutral-300 font-light"
                  v-text="cmaReport.agent.title"
                />
              </div>
            </div>
          </div>
          <div
            v-if="cmaReport.colistAgent"
          >
            <div class="flex flex-row space-x-2 lg:space-x-4">
              <div class="flex items-center">
                <img
                  :src="cmaReport.colistAgent.profilePhoto"
                  :alt="`${cmaReport.colistAgent.nameFML} photo`"
                  class="w-16 h-16 rounded-full"
                >
              </div>

              <div class="flex flex-col">
                <h4
                  class="text-xl mb-0.5 md:mb-0 font-bold"
                  v-text="cmaReport.colistAgent.nameFML"
                />
                <p
                  class="text-base mb-2 uppercase text-neutral-300 font-light"
                  v-text="cmaReport.colistAgent.title"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end sm:justify-start">
        <a
          :href="cmaReportUrl"
          target="_blank"
        >
          <VIcon
            i="arrow-outline-circle"
            class="hover:text-neutral-400 text-5xl sm:text-3xl md:text-4xl"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from '../../../components/VIcon';

export default {
  components: { VIcon },
  props: {
    cmaReport: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cmaReportUrl () {
      return `/sso/out/portal?p=cma/presentations/${this.cmaReport.id}`;
    },
  },
};
</script>
