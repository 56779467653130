<template>
  <div class="button-group-container" :class="{ error: hasErrors }">
    <label
        v-for="option in options"
        :class="{active: model === option[optionValueProp]}"
        class="flex-1"
    >
      <input
          type="radio"
          :value="option[optionValueProp]"
          v-model="model"
          @change="modelChanged"
      >
      <span v-text="option[optionLabelProp]"></span>
    </label>
    <div class="form-input-msg" v-if="errors">
      <div class="help-block mt-1 text-danger" v-for="(error, k) in errors" :key="k">
        <v-icon i="error"></v-icon>
        <span class="text-xs">{{ error }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errors: {default: null},
    options: {
      type: Array,
      required: true,
    },
    optionLabelProp: {
      type: String,
      default: 'label'
    },
    optionValueProp: {
      type: String,
      default: 'value'
    },
    modelValue: {
      required: true,
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      model: null,
    }
  },

  methods: {
    modelChanged() {
      this.$emit('update:modelValue', this.model);
    }
  },

  mounted() {
    this.model = this.modelValue;
  },

  computed: {
    hasErrors() {
      if (_.isArray(this.errors)) {
        return this.errors.length > 0;
      }
      if (_.isObject(this.errors)) {
        return this.errors.keys().length > 0;
      }
      return false;
    },
  }
}
</script>
