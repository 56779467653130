import { createApp } from "vue";
import Api from '../../services/Api';
import Alert from '../../services/Alert'
import { clone } from '../../helpers';

export default function (appData) {
    let counter = 0;

    return createApp({
        data() {
            return _.merge(appData, {
                modal: {
                    videoUrl: null,
                    linkUrl: null,
                    title: null,
                    videoDescription: '',
                    listingUrl: null,
                    videoTagline: ''
                },
                videoSearch: {
                    term: '',
                    typeID: ''
                },
                videoPaginator:  {},
                activeCard: null,
                cardsPerRow: 1
            });
        },

        computed: {
            filteredVideos() {
                if (!this.videoPaginator) {
                    return [];
                }
                return this.videoPaginator.data;
            }
        },

        methods: {
            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.linkUrl = modalData.linkUrl;
                this.modal.listingUrl = modalData.listingUrl;
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoTagline = modalData.tagline
            },

            search() {
                this.videoSearch.page = 1;
                this.fetchSearchResults();
            },

            fetchSearchResults() {
                let params = _.pickBy(this.videoSearch, (value, key) => {
                    if (!_.includes(['perPage', 'page', 'term', 'typeID'], key)) {
                        return false;
                    }
 
                    return !!value;
                });


                Api.post('/videos/search', params)
                    .then(response => {
                        this.videoPaginator = response.data;
                        history.pushState(
                            {
                                counter: ++counter,
                                videoSearch: clone(this.videoSearch),
                                paginator: clone(this.videoPaginator)
                            },
                            '',
                            '?' + (new URLSearchParams(params)).toString())

                    }).catch(error => Alert.toastError('Whoops!', 'Something went wrong.'));
            },

            changePage(page) {
                this.videoSearch.page = page;
                this.fetchSearchResults();
            },

            calculateCardsPerRow() {
                if (window.innerWidth < 640 ) {
                    this.cardsPerRow = 1;
                } else if (window.innerWidth < 768 ) {
                    this.cardsPerRow = 2;
                } else if (window.innerWidth < 1024 ) {
                    this.cardsPerRow = 3;
                } else {
                    this.cardsPerRow = 4;
                }
            }
        },

        mounted() {
            this.search();
            history.replaceState({
                counter: ++counter,
                videoSearch: clone(this.videoSearch),
                paginator: clone(this.videoPaginator),
            }, '');

            window.addEventListener('popstate', event => {
                this.videoSearch = event.state.videoSearch;
                this.videoPaginator = event.state.paginator;
            });
            window.addEventListener('resize', _.debounce(this.calculateCardsPerRow, 100));
            this.calculateCardsPerRow();
        }
    });
}
