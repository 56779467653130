<template>
  <div>
    <div class="large-quote">
      <VIcon i="quotes" />
    </div>
    <div class="flex flex-row flex-start items-center">
      <div
        class="h-10 w-10 rounded-full bg-neutral text-white flex items-center justify-center"
        v-text="teamMemberInitials"
      />
      <div
        class="stars flex-1 px-4"
        itemprop="Rating"
        itemscope
        itemtype="http://schema.org/Rating"
      >
        <span
          v-for="star in stars"
          :key="star"
          :class="rating > star ? 'text-black' : 'text-neutral-200'"
        >
          <VIcon i="star-solid" />
        </span>
        <meta
          itemprop="worstRating"
          content="1"
        >
        <meta
          itemprop="bestRating"
          content="5"
        >
        <meta
          itemprop="ratingValue"
          :content="rating"
        >
      </div>
      <div
        v-if="isFromZillow"
      >
        <img
          v-lazy="zillowLogo"
          class="w-20"
          alt="Provided by Zillow"
          width="80"
          height="28"
        >
      </div>
    </div>
    <div class="my-4">
      <span v-text="testimonialBody" />
      <a
        v-if="shouldBeLimited && !testimonialExpanded"
        class="cursor-pointer underline"
        @click="testimonialExpanded = true"
      >read more</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rating: {
      type: Number,
      required: false,
      default: null,
    },
    body: {
      type: String,
      required: false,
      default: '',
    },
    isFromZillow: {
      type: Boolean,
      required: false,
      default: false,
    },
    initials: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      stars: _.range(0, 5),
      testimonialExpanded: false,
      zillowLogo: '/images/ProvidedByZillow_140x50.gif',
    };
  },

  computed: {
    testimonialBody() {
      if (!this.shouldBeLimited || this.testimonialExpanded) {
        return this.body;
      }
      return this.body.substr(0, 300).trim() + '... ';
    },
    shouldBeLimited() {
      return this.body.length > 300;
    },
    teamMemberInitials() {
      const nameParts = this.initials.trim().split(/\s+/g);
      if (nameParts.length > 1) {
        const first = nameParts[0].trim().charAt(0).toUpperCase();
        const last = nameParts[nameParts.length - 1].trim().charAt(0).toUpperCase();
        return first + last;
      } else if (nameParts.length === 1) {
        return nameParts[0].trim().charAt(0).toUpperCase();
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.large-quote {
  @apply flex items-center justify-center text-2xl text-center relative text-primary top-2;
}
</style>
