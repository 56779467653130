<template>
  <!--    TODO: Add support for multiple file selection -->
  <div
      class="flex flex-row justify-between"
      :class="{'hidden': hidden}"
  >
    <input
        type="file"
        :id="inputId"
        hidden
        :required="required"
        ref="fileInput"
        :accept="accept"
        @change="fileSelected"
    />
    <input
        type="text"
        class="file-upload-input"
        :hidden="hidden"
        :value="uploadedFileName"
        :placeholder="placeholder"
        disabled
    />
    <div
        v-if="!hideButtons"
        class="h-full flex flex-row"
    >
      <button
          type="button"
          class="btn btn-primary"
          @click="selectFile"
      >
        Browse
      </button>
      <button
          type="button"
          class="btn btn-light"
          @click="clear"
          v-if="uploadedFile"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<script>
import Alert from "../services/Alert";

export default {
  name: "FileInput",

  props: {
    inputId: {
      type: String,
      default: null,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '*',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readType: {
      type: String,
      required: false,
      validator: function (value) {
        return ['arrayBuffer', 'binaryString', 'dataUrl', 'text'].indexOf(value) !== -1;
      },
    },
    placeholder: {
      type: String,
      default: "Select a file",
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    handleTypeValidation: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
      'file-data-read',
      'file-change',
      'file-clear',
      'invalid-file-type',
  ],

  data() {
    return {
      uploadedFile: null,
      uploadedFileReaderResult: null,
    }
  },

  computed: {
    uploadedFileName() {
      return this.uploadedFile ? this.uploadedFile.name : null;
    },
    invalidTypeErrorMessage() {
      return this.accept.includes(',')
        ? `File type must be one of: ${this.accept.replace(/\s*,\s*/g, ', ')}`
        : `File must be of type ${this.accept}`;
    },
  },

  methods: {
    validateFileType(file) {
      if (this.accept === "*") {
        return true;
      }

      //Form test regex from accept parameter
      let regexStr = this.accept
          .replace(/\s*,\s*/g, '|') // joining comma-separated values with | for capture group
          .replace(/\*/g, '[a-z0-9]+') // replacing MIME type wildcard with any lowercase alphanumeric extension
          .replace(/\./g, '\.'); // escaping dots in specific file types (e.g. ".jpeg")
      let regex = new RegExp(`(${regexStr})`);
      return regex.test(file.type);
    },

    fileSelected() {
      if (this.$refs.fileInput.files && this.$refs.fileInput.files.item(0)) {
        let file = this.$refs.fileInput.files.item(0);
        if (this.validateFileType(file)) {
          this.uploadedFile = file;
          if (this.readType) {
            this.getFileData();
          }
          this.$emit('file-change', this.uploadedFile);
        } else {
          if (this.handleTypeValidation) {
            Alert.error("Invalid File Type", this.invalidTypeErrorMessage);
          }
          this.$emit('invalid-file-type', file);
        }
      } else {
        this.uploadedFile = null;
        this.$emit('file-change', this.uploadedFile);
      }
    },

    getFileData() {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.uploadedFileReaderResult = event.target.result;
        this.$emit('file-data-read', this.uploadedFileReaderResult);
      };
      switch (this.readType) {
        case 'arrayBuffer':
          reader.readAsArrayBuffer(this.uploadedFile);
          return;
        case 'binaryString':
          reader.readAsBinaryString(this.uploadedFile);
          return;
        case 'dataUrl':
          reader.readAsDataURL(this.uploadedFile);
          return;
        case 'text':
          reader.readAsText(this.uploadedFile);
          return;
      }
    },

    selectFile() {
      this.$refs.fileInput.click();
    },

    clear() {
      this.$refs.fileInput.value = null;
      this.fileSelected();
      this.$emit('file-clear');
    },
  }
}
</script>
