export default [
    {
        textColor: '#fff',
        url: '/images/map/cluster/m1.png',
        height: 30,
        width: 30
    },
    {
        textColor: '#fff',
        url: '/images/map/cluster/m2.png',
        height: 56,
        width: 56
    },
    {
        textColor: '#fff',
        url: '/images/map/cluster/m3.png',
        height: 66,
        width: 66
    },
    {
        textColor: '#fff',
        url: '/images/map/cluster/m4.png',
        height: 78,
        width: 78
    },
    {
        textColor: '#fff',
        url: '/images/map/cluster/m5.png',
        height: 90,
        width: 90
    }
];
