import Swal from 'sweetalert2';

class Alert {
    constructor() {
        this.swal = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary mx-1',
                cancelButton: 'btn btn-light mx-1'
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            reverseButtons: true,
        });

        this.toaster = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
    }

    fire(...args) {
        return this.swal.fire(...args);
    }

    basic(title, message, level) {
        if (_.isObject(title)) {
            return this.basic(title.title, title.message, title.level || level)
        }
        return this.fire(title, message, level);
    }

    success(title, message) {
        return this.basic(title, message, 'success');
    }

    error(title, message) {
        return this.basic(title, message, 'error');
    }

    warning(title, message) {
        return this.basic(title, message, 'warning');
    }

    info(title, message) {
        return this.basic(title, message, 'warning');
    }

    confirm(title, message, options = {}) {
        let swalOptions = {
            title: title,
            text: message,
            showCancelButton: true
        };

        _.forOwn(options, (value, key) => {
            swalOptions[key] = value;
        });

        return this.fire(swalOptions);
    }

    confirmYesNo(title, message, options = {}) {
        options.confirmButtonText = 'Yes';
        options.cancelButtonText = 'No';
        return this.confirm(title, message, options);
    }

    close() {
        return this.swal.close();
    }

    toast(title, message, level) {
        if (_.isObject(title)) {
            return this.toast(title.title, title.message || message, level);
        }

        this.toaster.fire({
            title,
            text: message,
            icon: level
        });
    }

    quickToast(title, message, level) {
        if (_.isObject(title)) {
            return this.quickToast(title.title, title.message || message, level);
        }

        this.toaster.fire({
            title,
            text: message,
            icon: level,
            timer: 1000,
            timerProgressBar: false,
        });
    }

    toastSuccess(title, message) {
        if (_.isObject(title)) {
            return this.toast(title.title, title.message || message, 'success');
        }
        this.toast(title, message, 'success');
    }

    toastError(title, message) {
        if (_.isObject(title)) {
            return this.toast(title.title, title.message || message, 'error');
        }
        this.toast(title, message, 'error');
    }

    toastWarning(title, message) {
        if (_.isObject(title)) {
            return this.toast(title.title, title.message || message, 'warning');
        }
        this.toast(title, message, 'warning');
    }

    toastInfo(title, message) {
        if (_.isObject(title)) {
            return this.toast(title.title, title.message || message, 'info');
        }
        this.toast(title, message, 'info')
    }
}

export default new Alert;

