<template>
  <div>
    <div class="mb-9">
      <h3 class="text-4xl mb-2">
        Saved Listings
      </h3>
      <p
        class="text-sm"
        v-text="subtitle"
      />
    </div>
    <div class="saved-listings-container">
      <div class="listing-actions-container flex flex-row justify-between">
        <div class="self-end">
          <VDropMenu
            anchor="left"
            :items="[
              {
                label: 'Delete All Saved Listings',
                onClick: deleteSavedListings,
              },
              {
                label: 'Unsubscribe From All Alerts',
                onClick: unsubscribeFromStatus,
              }
            ]"
          >
            <template #trigger>
              Actions
            </template>
          </VDropMenu>
        </div>
        <div class="text-right">
          <a
            class="underline hover:opacity-75 text-sm text-neutral cursor-pointer"
            @click.prevent="$modal.show('manage-groups-modal')"
          >
            Manage Groups
          </a>

          <div class="flex flex-row">
            <div class="pr-2 text-sm text-neutral">
              Viewing Group:
            </div>
            <VDropMenu
              :items="viewingGroupMenu"
            >
              <template #trigger>
                <span v-text="viewingGroup.name" />
              </template>
            </VDropMenu>
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-5">

      <SavedListing
        v-for="listing in filteredListings"
        :key="listing.id"
        :listing="listing"
        :user="user"
        @unfavorited="removeListing(listing.id)"
        @schedule-tour="scheduleTour(listing)"
      />

      <VModal
        name="manage-groups-modal"
        size="lg"
        class="z-50"
      >
        <button
          type="button"
          class="close"
          @click="$modal.hide('manage-groups-modal')"
        >
          <span class="sr-only">Close modal</span>
          <VIcon i="close" />
        </button>
        <div class="modal-header">
          <h3 class="modal-title">
            Manage Groups
          </h3>
        </div>
        <div class="modal-body">
          <hr class="my-3">
          <ManageGroups
            :user="user"
            @listings-changed="handleListingsChanged"
            @user-groups-changed="handleUserGroupsChanged"
          />
        </div>
        <div class="modal-footer" />
      </VModal>

      <ScheduleTourModal />
    </div>
  </div>
</template>

<script>
import Api from '../../../services/Api';
import Alert from '../../../services/Alert';
import VDropMenu from '../../../components/VDropMenu';
import SavedListing from './SavedListing';
import VModal from '../../../components/VModal';
import ManageGroups from './ManageGroups';
import ScheduleTourModal from '../ScheduleTourModal';
import VIcon from '../../../components/VIcon';

export default {
  components: {
    VIcon,
    ScheduleTourModal,
    ManageGroups,
    VModal,
    SavedListing,
    VDropMenu,
  },

  props: {
    initialListings: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['user-groups-changed'],

  data() {
    return {
      listings: [],
      form: {},
      errors: {},
      viewingGroup: {
        name: 'All',
        id: null,
      },
      groups: [],
    };
  },

  computed: {
    filteredListings() {
      return this.listings.filter(listing => {
        return this.viewingGroup.name === 'All' || listing.savedListing.groupID === this.viewingGroup.id;
      });
    },

    viewingGroupMenu() {
      return [
        {
          label: 'All',
          onClick: () => this.filterByGroup({ id: 'all', name: 'All' }),
        },
      ].concat(this.user.groups.map(g => {
        return {
          label: g.name,
          onClick: () => this.filterByGroup(g),
        };
      }));
    },
  },

  mounted() {
    this.listings = this.initialListings;
    this.groups = this.user.groups;
  },

  methods: {
    handleListingsChanged(newListings) {
      this.listings = newListings;
    },

    handleUserGroupsChanged(groups) {
      this.$emit('user-groups-changed', groups);
    },

    deleteSavedListings() {
      Api.delete('user/saved-listings').then(() => {
        Alert.toastSuccess('Listings un-favorited!');
        this.listings = [];
      });
    },

    removeListing(id) {
      const index = this.listings.findIndex(l => l.id === id);
      if (index > -1) {
        this.listings.splice(index, 1);
      }
    },

    unsubscribeFromStatus() {
      Api.put('user/saved-listings', { alerts: 0 })
        .then((response) => {
          Alert.toastSuccess('Updated!', 'Price/Status alerts settings saved.');
          this.handleListingsChanged(response.data);
        });
    },

    filterByGroup(group) {
      this.viewingGroup = {
        id: group.id,
        name: group.name,
      };
    },

    scheduleTour(listing) {
      this.$modal.show('schedule-virtual-tour', { listing });
    },
  },
};
</script>
