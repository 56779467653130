export default function useShare(props) {
    const hasShareApi = typeof navigator.share === 'function';

    const share = () => {
        if (hasShareApi) {
            navigator.share({
                title: document.title,
                text: decodeURIComponent(props.shareMessageText),
                url: props.shareUrl,
            }).catch((error) => {
                console.error('Error:', error);
            });
        } else {
            console.warn('API Web Share is not available.');
        }
    };

    return {
        hasShareApi,
        share,
    };
}
