<template>
  <div class="hidden lg:flex flex-none w-full md:w-80 ml-0 md:ml-16 xl:ml-32">
    <div class="flex flex-1 flex-col items-center">
      <div class="border border-neutral p-6 h-auto mb-6">
        <a class="twitter-timeline" data-lang="en" data-width="300" data-height="1344" data-theme="light" href="https://twitter.com/properties?ref_src=twsrc%5Etfw">
          Tweets by @properties
        </a>
      </div>
      <a href="https://twitter.com/properties?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-lang="en" data-show-count="false">
        Follow @properties
      </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  if (typeof twttr !== "undefined") {
    twttr?.widgets.load()
  }
});
</script>
