<template>
  <button
    v-if="showRequestTourButton"
    v-tippy="{
      onShow: showTippy,
      content: tippyContent,
      zIndex: 30
    }"
    class="hover:text-neutral-400 scale-up-on-hover"
    @click="scheduleTour"
  >
    <VIcon :i="tourIcon" />
    <span class="sr-only">Request Tour</span>
  </button>
</template>
<script>
export default {

  props: {
    listing: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'listing',
    },
    tourIcon: {
      type: String,
      default: 'calendar-outline-circle',
    },
    tippyContent: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    showRequestTourButton() {
      return this.listing.isEligibleForLeadGeneration || this.mode === 'developments';
    },
  },
  methods: {
    scheduleTour() {
      this.$modal.show('schedule-virtual-tour', { listing: this.listing });
    },
    showTippy() {
      return !!this.tippyContent;
    },
  },
};
</script>
