<template>
  <li>
    <a
      v-if="hasShareApi"
      @click="share"
    >
      <VIcon
        i="send"
        class="mr-2"
      />
      <span>Share by Text</span>
    </a>
  </li>
  <li>
    <a>
      <VIcon
        i="clipboard"
        class="mr-2"
      />
      <span @click="copyToClipboard">Copy Link</span>
    </a>
  </li>
</template>

<script>
import useShare from '../composables/useShareApi';

export default {
  props: {
    shareMessageText: {
      type: String,
      required: true,
    },
    shareUrl: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { hasShareApi, share } = useShare(props);

    return {
      hasShareApi,
      share,
    };
  },
  methods: {
    copyToClipboard() {
      const tempInput = document.createElement('input');
      document.body.appendChild(tempInput);

      tempInput.value = this.shareUrl;
      tempInput.select();
      document.execCommand('copy');

      document.body.removeChild(tempInput);
    },

  },
};
</script>
