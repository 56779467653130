import { isArray } from 'lodash';

class LeadConversion {
    send() {
        if (typeof window.fbq === 'function') {
            window.fbq('track', 'Lead', {});
        }

        if (isArray(window.dataLayer) && isArray(window.idsForGtmConversionEvents) && typeof window.gtag === 'function') {
            window.idsForGtmConversionEvents.forEach(gtagID => {
                window.gtag('event', 'conversion', {
                    send_to: gtagID,
                });
            });
        }
    }
}

export default new LeadConversion();
