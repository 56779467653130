<template>
  <div ref="loader">
    <div v-show="showLoader">
      <div class="border-t border-neutral-200 mt-2 mb-4 flex flex-col md:flex-row py-8">
        <div class="flex-none bg-neutral-200 animate-pulse mx-0">
          <div class="w-full h-72 md:w-64 md:h-44 -mt-24">
            <LoadingIndicatorBlock/>
          </div>
        </div>

        <div class="flex-col flex-auto md:pl-6  bg-white animate-pulse p-6">
          <div class="flex flex-row my-3 items-center">
            <div class="h-4 bg-neutral-200 rounded w-full"></div>
          </div>
          <hr class="mb-6">
          <div class="h-6 bg-neutral-200 rounded w-full mt-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LoadingIndicatorBlock from "../../components/LoadingIndicatorBlock";
import { onMounted, onUnmounted, ref } from "vue";

const props = defineProps({
  sourceItems: {
    type: Array,
    required: true,
  },
  initialLoad: {
    type: Number,
    default: 5,
  },
  increment: {
    type: Number,
    default: 5,
  },
});

const emit = defineEmits(['add-item']);

const throttledLoadItems = _.throttle(() => {
  loadMoreItems();
}, 500);

onMounted(() => {
  window.addEventListener('scroll', throttledLoadItems);
});

onUnmounted(() => {
  window.removeEventListener('scroll', throttledLoadItems);
});

let loaderTop;
const loader = ref();
const showLoader = ref(false);
let nextIndex = props.initialLoad;

function loadMoreItems() {
  let counter = 0;
  let candidate;

  loaderTop = loader.value.getBoundingClientRect().top;
  if (loaderTop < window.innerHeight) {
    showLoader.value = true;

    while (++counter <= props.increment) {
      candidate = props.sourceItems[nextIndex++];
      if (candidate) {
        emit('add-item', candidate)
      } else {
        showLoader.value = false;
        loaderTop = null;
        return;
      }
    }
  }
}
</script>
