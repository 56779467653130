<template>
  <div>
    <label
        class="form-toggle"
        :class="colorContext"
    >
      <slot></slot>
      <span
          v-show="label"
          class="toggle-label"
          v-text="label"
      ></span>
      <slot name="label-with-html"></slot>
    </label>
    <div class="form-input-msg" v-if="errors">
      <div class="help-block mt-1 mb-4 text-danger" v-for="(error, k) in errors" :key="k">
        <v-icon i="error"></v-icon>
        <span class="text-xs">{{ error }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    colorContext: {
      type: String,
      default: 'primary',
      validator: v => ['primary', 'secondary', 'alternate', 'neutral'].indexOf(v) > -1
    },
    errors: {default: null},
    label: {
      type: String,
      default: '',
    },
  }
}
</script>
