import Gallery from '../../services/Gallery';
import { createApp } from 'vue';

export default function (data) {
    return createApp({
        data() {
            return _.merge(data, {
                showContactFormToggle: true,
                gallery: Gallery.create(data.images),
                contactFormPayload: payload => {
                    payload.neighborhoodID = this.neighborhoodID;
                    return payload;
                },
            });
        },

        methods: {
            initTab(tab) {
                if (tab.name === 'Market Report') {
                    this.showMarketReport = true;
                }
            },
            scrollToContactForm() {
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },
            scrolled() {
                this.showContactFormToggle = !this.checkVisible(this.$refs.contactFormContainer);
            },
            checkVisible(elm) {
                const rect = elm.getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
            },
            handleItemSelected(event) {
                window.location.href = event.url;
            },

            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoTagline = modalData.description;
            },
        },

        created() {
            window.addEventListener('scroll', _.debounce(this.scrolled, 100));
        },
    });
}
