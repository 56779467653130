<template>
  <div class="listing-map-info-card-container">
    <div
      ref="card"
      class="listing-map-info-card"
    >
      <a
        class="listing-image-container"
        :href="item.listingUrl"
        target="_blank"
      >
        <img
          :src="item.imageUrl"
          class="listing-image pointer-events-none"
          :alt="item.fullAddress"
        >
        <img
          v-if="logoSrc"
          class="absolute bottom-1 right-1 h-6 bg-semi-transparent-gray-light p-1 rounded"
          :src="logoSrc"
          :alt="logoAlt"
        >
        <div
          v-if="showStatus"
          class="listing-status"
          v-text="item.status"
        />
      </a>

      <div class="details-container">
        <div>
          <div class="beds-baths-price-container">
            <div
              v-for="({ containerClasses, label, labelClasses, valueClasses, value, wrapText }, index) in detailItems"
              :key="index"
              :class="[ ...containerClasses, {
                'border-r': index !== detailItems.length - 1,
                'border-r-0': index === detailItems.length - 1,
                'whitespace-nowrap': !wrapText
              }]"
            >
              <div :class="valueClasses">
                {{ value }}
              </div>
              <div
                v-if="label"
                :class="labelClasses"
              >
                {{ label }}
              </div>
            </div>
          </div>

          <div
            class="address-container"
            :class="mode === 'developments' ? 'development-address' : null"
          >
            <div v-text="item.streetAddress || item.name" />
            <div
              class="city-state-zip"
              v-text="item.cityStateZip"
            />
          </div>

          <div class="mt-2 bottom-0 left-0 right-0 flex flex-row">
            <FavoriteToggle
              v-if="mode !== 'developments'"
              :key="'favorite_' + item.MLS + item.LN"
              :listing="item"
              class="hover:text-neutral-400 text-3xl scale-up-on-hover mr-2"
            />
            <ScheduleTourButton
              :listing="item"
              :mode="mode"
              class="text-3xl"
            />
            <div class="flex-grow" />
            <a
              :href="item.listingUrl"
              target="_blank"
              class="text-3xl hover:text-neutral-400 scale-up-on-hover cursor-pointer"
            >
              <VIcon i="arrow-outline-rev-circle" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: 'listing',
    },
    websiteLogo: {
      type: String,
      required: true,
      default: '',
    },
    tenantName: {
      type: String,
      required: true,
      default: '',
    },
  },

  computed: {
    showStatus() {
      return this.item && this.item.status && this.item.status.toLowerCase() !== 'active';
    },
    logoSrc() {
      if (this.item) {
        if (this.item.isTenantListing) {
          return this.websiteLogo;
        } else if (this.item.brokerReciprocity && this.item.brokerReciprocity.required) {
          return this.item.brokerReciprocity.logo;
        }
      }
      return '';
    },
    logoAlt() {
      if (this.item) {
        if (this.item.isTenantListing) {
          return `${this.tenantName} logo`;
        } else if (this.item.brokerReciprocity && this.item.brokerReciprocity.required) {
          return 'Broker Reciprocity logo';
        }
      }
      return '';
    },
    itemBathrooms() {
      return (!this.item.isCommercial && this.item.bathrooms && this.item.bathrooms !== 0) ? this.item.bathrooms : null;
    },
    itemFullBathrooms() {
      return (!this.item.isCommercial && this.item.fullBathrooms && this.item.fullBathrooms !== 0) ? this.item.fullBathrooms : null;
    },
    itemHalfBathrooms() {
      return (!this.item.isCommercial && this.item.halfBathrooms && this.item.halfBathrooms !== 0) ? this.item.halfBathrooms : null;
    },
    itemBedrooms() {
      return (!this.item.isCommercial && this.item.bedrooms && this.item.bedrooms !== 0) ? this.item.bedrooms : null;
    },
    itemSqft() {
      return (this.item.isCommercial && this.item.sqft && this.item.sqft !== 0) ? this.item.sqft : null;
    },
    detailItems() {
      const items = [];
      if (this.itemBedrooms) {
        items.push({
          containerClasses: ['beds-baths'],
          label: 'beds',
          labelClasses: ['beds-baths-label'],
          valueClasses: ['beds-baths-value'],
          value: this.item.bedrooms,
          wrapText: false,
        });
      }
      if (this.itemFullBathrooms) {
        items.push({
          containerClasses: ['beds-baths'],
          label: this.item.fullBathLabel,
          labelClasses: ['beds-baths-label'],
          valueClasses: ['beds-baths-value'],
          value: this.item.fullBathrooms,
          wrapText: false,
        });
      }
      if (this.itemHalfBathrooms) {
        items.push({
          containerClasses: ['beds-baths'],
          label: this.item.halfBathLabel,
          labelClasses: ['beds-baths-label'],
          valueClasses: ['beds-baths-value'],
          value: this.item.halfBathrooms,
          wrapText: false,
        });
      }
      if (this.itemSqft) {
        items.push({
          containerClasses: ['beds-baths'],
          label: 'sq. ft.',
          labelClasses: ['beds-baths-label'],
          valueClasses: ['beds-baths-value'],
          value: this.item.sqft,
          wrapText: false,
        });
      }
      if (this.item.price !== '') {
        items.push({
          containerClasses: ['price', { 'pl-0': this.priceStandalone, 'pl-2': !this.priceStandalone }],
          label: null,
          labelClasses: [],
          valueClasses: [],
          value: this.item.price,
          wrapText: true,
        });
      }
      return items;
    },
    priceStandalone() {
      return (this.itemBathrooms === null && this.itemBedrooms === null && this.itemSqft === null);
    },

  },
};
</script>

<style lang="scss" scoped>
.listing-map-info-card-container {
  @apply relative;
  @apply w-auto;
  @media (min-height: 686px) {
    @screen sm {
      @apply w-72;
    }
  }

  .listing-map-info-card {
    @apply flex flex-col bg-white h-full;
    > .listing-image-container {
      @apply relative;
      > img.listing-image {
        @apply w-full object-cover object-center bg-neutral-400;
        height: 6rem;
        @media (min-height: 686px) {
          height: 11rem;
        }
      }
      > .listing-status {
        @apply absolute top-4 right-0 py-1 pl-2 pr-6 border-l-3 border-secondary bg-transparent-dark;
        @apply text-right text-white font-bold uppercase text-xs;
      }
    }

    .details-container {
      @apply flex-1 m-2 relative;
    }
    .beds-baths-price-container {
      @apply flex flex-row justify-start items-center font-alt text-center text-sm;
      .beds-baths {
        @apply px-2 border-black;
        &:first-child {
          @apply pl-0 pr-2;
        }
        > div {
          @apply inline;
        }
      }
      .price {
        @apply flex-1 text-left;
      }
      @media (min-height: 686px) {
        .beds-baths {
          .beds-baths-value {
            @apply text-base leading-none;
          }
          > div {
            @apply block;
          }
        }
        .price {
          @apply text-lg;
        }
      }
    }
    .address-container {
      @apply my-1;
      .city-state-zip {
        display: none;
      }
      @media (min-height: 686px) {
        @apply my-2;
        &.development-address{
          @apply my-1.5;
        }
        .city-state-zip {
          display: block;
        }
      }
    }
  }
}
</style>
