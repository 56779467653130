import Alert from '../../services/Alert';
import { createApp } from 'vue';
import gmapDefaultStyles from '../configs/GmapDefaultStyles';

export default function (data) {
    return createApp({
        data() {
            return _.merge(data, {
                searchString: '',
                needsToAutofit: false,
                showContactFormToggle: true,
                contactFormPayload: {},
                selectedRegionID: null,
                hoveredIndex: null,
                mapOptions: {
                    detectEvents: true,
                    clickableIcons: false,
                    disableDefaultUi: true,
                    drawEnabled: false,
                    fitBoundsOnDraw: true,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    maxZoom: 18,
                    minZoom: 2,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    styles: gmapDefaultStyles,
                },
            });
        },

        computed: {
            paths() {
                let pathsToReturn = [];
                if (this.selectedRegionID !== null) {
                    const selectedRegion = this.webRegions.find((region) => region.id === this.selectedRegionID);
                    if (selectedRegion.neighborhoods && selectedRegion.neighborhoods.length > 0) {
                        pathsToReturn = this.getPathsFromNeighborhoods(selectedRegion.neighborhoods);
                    }
                    return pathsToReturn;
                }
                if (this.webRegions === null || this.webRegions.length === 0) {
                    if (this.neighborhoods && this.neighborhoods.length > 0) {
                        const neighborhoodIDs = this.neighborhoods.map((n) => n.id);
                        pathsToReturn = this.getPathsFromNeighborhoods(neighborhoodIDs);
                    }
                    return pathsToReturn;
                }
                if (this.webRegions.length === 1) {
                    if (this.webRegions[0].neighborhoods && this.webRegions[0].neighborhoods.length > 0) {
                        pathsToReturn = this.getPathsFromNeighborhoods(this.webRegions[0].neighborhoods);
                    }
                    return pathsToReturn;
                }
                this.webRegions.forEach((region) => {
                    pathsToReturn.push({
                        id: region.id,
                        center: {
                            lat: region.center ? parseFloat(region.center.lat) : null,
                            lng: region.center ? parseFloat(region.center.lng) : null,
                        },
                        points: region.paths,
                        name: region.name,
                    });
                });
                return pathsToReturn;
            },
            tileNeighborhoods() {
                return this.neighborhoods.filter(n => n.webFeatured).slice(0, this.tileLimit);
            },
        },

        updated() {
            if (this.needsToAutofit) {
                this.initMap();
            }
        },

        mounted() {
            this.$nextTick(function () {
                this.initMap();
            });
        },

        methods: {
            polygonOptions(index) {
                const colors = ['#333333', '#666666', '#999999', '#cccccc'];
                return {
                    strokeOpacity: '0.8',
                    strokeWeight: '1',
                    strokeColor: '#666666',
                    fillColor: colors[index % colors.length],
                    fillOpacity: '0.4',
                };
            },
            getPathsFromNeighborhoods(neighborhoods) {
                const pathsToReturn = [];
                neighborhoods.forEach((neighborhoodId) => {
                    const neighborhood = this.neighborhoods.find((n) => {
                        return n.id === neighborhoodId;
                    });
                    if (neighborhood && neighborhood.approximateSquareMileage > 0) {
                        pathsToReturn.push({
                            id: neighborhood.id,
                            center: {
                                lat: neighborhood.center ? parseFloat(neighborhood.center.lat) : null,
                                lng: neighborhood.center ? parseFloat(neighborhood.center.lng) : null,
                            },
                            points: neighborhood.decodedPoints,
                            name: neighborhood.name,
                        });
                    }
                });
                return pathsToReturn;
            },
            initMap() {
                if (!this.$refs.theMap) {
                    return;
                }
                this.$refs.theMap.$mapPromise.then((map) => {
                    const markerBounds = new google.maps.LatLngBounds();
                    this.paths.forEach(path => {
                        path.points.forEach((point) => {
                            const newPoint = new google.maps.LatLng(point.lat, point.lng);
                            markerBounds.extend(newPoint);
                        });
                    });
                    map.fitBounds(markerBounds);
                    this.needsToAutofit = false;
                });
            },
            handleRegionClick(id, index) {
                if (this.isTouchDevice()) {
                    this.handleRegionTap(id, index);
                } else {
                    this.handleRegionSelect(id);
                }
            },
            handleRegionTap(id, index) {
                this.handlePolygonMouseover(index);
            },
            handleRegionSelect(id) {
                if (this.selectedRegionID === null) {
                    const selectedIndex = this.webRegions.findIndex((r) => {
                        return r.id === id;
                    });
                    const neighborhoods = this.webRegions[selectedIndex].neighborhoods;
                    if (!neighborhoods || neighborhoods.length <= 0) {
                        Alert.toastInfo('There are no neighborhoods to display in this region.');
                        return;
                    }
                    this.selectedRegionID = id;
                    this.hoveredIndex = null;
                    this.needsToAutofit = true;
                }
            },
            handlePolygonMouseover(index) {
                this.hoveredIndex = index;
            },
            handlePolygonMouseout(index) {
                if (this.hoveredIndex === index) {
                    this.hoveredIndex = null;
                }
            },
            handleNeighborhoodClick(id, index) {
                if (this.isTouchDevice()) {
                    this.handleNeighborhoodTap(id, index);
                } else {
                    this.handleNeighborhoodSelect(id);
                }
            },
            handleNeighborhoodTap(id, index) {
                this.handlePolygonMouseover(index);
            },
            handleNeighborhoodSelect(id) {
                const selectedNeighborhood = this.neighborhoods.find((neighborhood) => {
                    return neighborhood.id === id;
                });
                window.location.href = selectedNeighborhood.url;
            },
            handleBackClick() {
                this.needsToAutofit = true;
                this.selectedRegionID = null;
                this.hoveredIndex = null;
            },
            scrollToContactForm() {
                window.scrollTo(0, this.$refs.contactFormContainer.offsetTop);
            },
            scrolled() {
                this.showContactFormToggle = !this.checkVisible(this.$refs.contactFormContainer);
            },
            checkVisible(elm) {
                const rect = elm.getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
            },
            handleItemSelected(event) {
                window.location.href = event.url;
            },
            isTouchDevice() {
                return 'ontouchstart' in window || !!(navigator.msMaxTouchPoints);
            },
        },

        created() {
            window.addEventListener('scroll', _.debounce(this.scrolled, 100));
        },
    });
}
