<template>
  <Popover
    v-slot="{ close }"
    class="relative"
  >
    <div>
      <PopoverButton
        :class="{'w-full': wideTrigger, 'focus:ring-1': focusRing}"
        class="ring-offset-2 ring-neutral-200"
      >
        <span class="sr-only">Open popover</span>
        <slot
          name="trigger"
          class="font-bold"
        />
        <VIcon
          v-if="triggerIcon"
          :i="triggerIcon"
          class="w-8"
        />
      </PopoverButton>
    </div>

    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <PopoverPanel
        :class="anchorClass"
        class="absolute mt-3 shadow-xl border-neutral-100 border bg-white text-neutral-400 focus:outline-none z-10"
      >
        <slot :close="close">
          <div class="h-8 w-12" />
        </slot>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { dropdownProps } from '../Vue/UiConfigs';
import { useDropdown } from '../website/composables/useDropdown';

const props = defineProps({
  ...dropdownProps,
});

const { anchorClass } = useDropdown(props);

</script>
