<template>
    <v-tabs v-bind="$attrs">
        <slot></slot>
    </v-tabs>
</template>

<script>
    export default {
        created() {
            console.error(
                "<tab-set/> is deprecated and replaced by <v-tabs/> " +
                "to be consistent with similar component <v-accordion/>"
            );
        }
    }
</script>
