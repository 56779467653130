<template>
  <div
    class="content-limiter relative overflow-y-hidden"
    :class="{expanded: !isCollapsible || expanded}"
  >
    <div
      class="content-slot overflow-y-visible"
      :style="style"
    >
      <div ref="content">
        <slot />
      </div>
    </div>
    <button
      v-if="isCollapsible"
      v-cloak
      type="button"
      class="read-more flex flex-col w-full absolute bottom-0 left-0 right-0 p-2 pt-20 hover:underline"
      :style="toggleStyle"
      @click="expanded = !expanded"
      v-text="expanded ? 'Read Less' : 'Read More'"
    />
  </div>
</template>
<script>
import Color from '../../services/Color';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';

const twConfig = resolveConfig(tailwindConfig);
const breakpoints = ['default', 'sm', 'md', 'lg', 'xl', '2xl'];

function fadeColor(color) {
  return {
    start: Color.applyAlpha(color, 0),
    end: Color.applyAlpha(color, 1),
  };
}

export default {
  props: {
    remHeightLimit: {
      type: [Number, Object],
      default: () => ({
        default: 6,
        xl: 12,
      }),
    },
    fadeColor: {
      type: [String, Object],
      default: 'white',
    },
  },
  data() {
    return {
      expanded: false,
      oneRemInPx: null,
      contentHeight: null,
      breakpoint: 'default',
    };
  },
  computed: {
    style() {
      if (!this.isCollapsible || this.expanded) {
        return { height: this.contentHeight ? `${this.contentHeight}px` : 'auto' };
      }
      return {
        height: this.heightLimits[this.breakpoint] + 'rem',
      };
    },
    fadeColors() {
      const fadeColors = {};
      let previous = fadeColor('white');
      _.forEach(breakpoints, size => {
        if (_.isString(this.fadeColor)) {
          fadeColors[size] = fadeColor(this.fadeColor);
          return;
        }
        fadeColors[size] = this.fadeColor[size] ? fadeColor(this.fadeColor[size]) : previous;

        previous = fadeColors[size];
      });
      return fadeColors;
    },
    heightLimits() {
      const heightLimits = {};
      let previous = 6;
      _.forEach(breakpoints, size => {
        if (_.isNumber(this.remHeightLimit)) {
          heightLimits[size] = this.remHeightLimit;
          return;
        }
        heightLimits[size] = this.remHeightLimit[size] || previous;
        previous = heightLimits[size];
      });
      return heightLimits;
    },
    contentHeightInRems() {
      return this.contentHeight / this.oneRemInPx;
    },
    contentMaxHeightInRems() {
      return this.heightLimits[this.breakpoint];
    },
    isCollapsible() {
      return this.contentHeightInRems > this.contentMaxHeightInRems;
    },
    toggleStyle() {
      if (this.isCollapsible && !this.expanded) {
        return { 'background-image': 'linear-gradient(' + this.fadeColors[this.breakpoint].start + ', ' + this.fadeColors[this.breakpoint].end + ' 80%)' };
      }
      return null;
    },
  },
  created() {
    this.oneRemInPx = parseInt(
      window
        .getComputedStyle(document.getRootNode().body)
        .getPropertyValue('font-size'),
    );
  },
  mounted() {
    window.addEventListener('resize', _.debounce(this.init, 100));
    setTimeout(() => this.init(), 100);
  },
  updated() {
    this.init();
  },
  methods: {
    init() {
      this.contentHeight = this.$refs.content.clientHeight;

      let breakpoint = 'default';
      _.forEach(['sm', 'md', 'lg', 'xl', '2xl'], size => {
        if (window.innerWidth >= parseInt(twConfig.theme.screens[size])) {
          breakpoint = size;
        }
      });
      this.breakpoint = breakpoint;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-limiter {
  .content-slot {
    transition: height 0.3s ease;
  }

  .read-more {
    background-image: linear-gradient(transparent, white 80%);
  }

  &.expanded {
    .read-more {
      @apply relative block pt-2;
      background-image: unset;
    }
  }
}
</style>
