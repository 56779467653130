<template>
  <p class="text-xs mt-6 mb-3">
    I consent for <span v-text="fullBrandName" />, its franchisees, affiliates and/or agents to call or text me for any purpose at any number provided including via automatic telephone dialing system and/or artificial or prerecorded voice, and such consent is not required to purchase goods or services as I may always call directly
    <a
      :href="officeLink"
      class="underline hover:opacity-75"
      target="_blank"
    >here</a>.
  </p>
</template>

<script>
export default {
  data() {
    return {
      siteContextName: window.$siteContext.name,
      shortName: window.$siteContext.shortName,
      officeLink: `${window.$siteContext.baseUrl}/offices`,
    };
  },
  computed: {
    fullBrandName() {
      if (['chicagoland', 'nw indiana', 'sw michigan', 'se wisconsin'].includes(this.shortName.toLowerCase())) {
        return `@properties Christie’s International Real Estate ${this.shortName}`;
      }
      return `${this.siteContextName}`;
    },
  },
};
</script>
