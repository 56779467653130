<template>
  <div>
    <div class="mb-4 md:mb-9 lg:mb-12">
      <h3
        class="text-4xl mb-2"
        v-text="heading"
      />
      <p
        class="text-sm"
        v-text="subtitle"
      />
    </div>
    <div
      v-if="cmaReports.length"
      class="flex flex-col space-y-4 border-t border-neutral-200"
    >
      <CmaReport
        v-for="cmaReport in cmaReports"
        :key="cmaReport.id"
        :cma-report="cmaReport"
        class="py-8 border-b border-neutral-200"
      />
    </div>
    <div v-else>
      <h2>No Comparative Market Analysis available.</h2>
    </div>
  </div>
</template>

<script>
import CmaReport from './CmaReport';
import AppData from '../../../services/AppData';

export default {
  components: {
    CmaReport,
  },

  props: {
    heading: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      cmaReports: AppData.cmaReports ?? {},
    };
  },
};

</script>
