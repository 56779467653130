<template>
  <form-toggle :label="label">
    <input
        type="checkbox"
        @change="$emit('update:modelValue', $event.target.checked ? 1 : 0)"
        :checked="modelValue"
        :true-value="1"
        :false-value="0"
    >
  </form-toggle>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    modelValue: {
      //
    }
  },

  emits: ['update:modelValue'],
}
</script>
