<template>
  <div>
    <div class="mb-4 md:mb-9 lg:mb-12">
      <h3
        class="text-4xl mb-2"
        v-text="heading"
      />
      <p
        class="text-sm"
        v-text="subtitle"
      />
    </div>
    <div
      v-if="closingMilestones.length"
      class="flex flex-col space-y-4 border-t border-neutral-200"
    >
      <ClosingMilestone
        v-for="closingMilestone in closingMilestones"
        :key="closingMilestone.id"
        :closing-milestone="closingMilestone"
        :agent="agent"
        class="py-8 border-b border-neutral-200"
      />
    </div>
  </div>
</template>

<script>
import ClosingMilestone from './ClosingMilestone';
import AppData from '../../../services/AppData';

export default {
  components: {
    ClosingMilestone,
  },

  props: {
    heading: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    agent: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      closingMilestones: AppData.closingMilestones,
    };
  },
};
</script>
