<template>
  <div>
    <div class="mb-4 md:mb-9 lg:mb-12">
      <h3
        class="text-4xl mb-2"
        v-text="heading"
      />
      <p
        class="text-sm"
        v-text="subtitle"
      />
    </div>
    <div
      v-if="homeReports.length"
      class="flex flex-col space-y-4 border-t border-neutral-200"
    >
      <HomeReport
        v-for="homeReport in homeReports"
        :key="homeReport.id"
        :home-report="homeReport"
        class="py-8 border-b border-neutral-200"
      />
    </div>
  </div>
</template>

<script>
import HomeReport from './HomeReport';
import AppData from '../../../services/AppData';

export default {
  components: {
    HomeReport,
  },

  props: {
    heading: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      homeReports: AppData.atHomeReports ?? {},
    };
  },
};
</script>
