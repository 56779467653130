<template>
  <VModal
    class="login-modal"
    name="login-modal"
    :backdrop="true"
    size="xl"
    @before-open="init"
  >
    <button
      type="button"
      class="close"
      @click="$modal.hide('login-modal')"
    >
      <span class="sr-only">Close modal</span>
      <VIcon i="close" />
    </button>
    <div class="modal-header">
      <div
        class="modal-title"
        v-text="headerText"
      />
    </div>
    <div class="login-modal-body modal-body">
      <div class="grid gap-y-6 grid-cols-1 lg:grid-cols-2 border-t border-neutral-200">
        <div class="flex items-center justify-center mt-0 min-w-full sm:min-w-[500px]">
          <form
            class="pl-4 pr-4 mt-8 mb-8 w-full max-w-[550px] md:pl-12 pr-12 lg:mt-0 mb-0"
            @submit.prevent="submit"
          >
            <h3 class="text-2xl t-header text-center mb-4">
              Sign In
            </h3>

            <template v-if="errors.activation">
              <div class="mb-6 px-5 italic text-left text-neutral-400">
                Your account has not been activated yet. Please follow the activation
                link that was emailed to you previously or
                <button
                  type="button"
                  class="text-left italic underline hover:opacity-75"
                  @click="resendActivationEmail"
                >
                  click here to resend the activation link.
                </button>
              </div>
            </template>

            <FormInput
              class="form-input"
              :required="true"
              :errors="errors.email"
            >
              <input
                v-model="loginForm.email"
                type="email"
                placeholder="Email Address*"
                autofocus
              >
            </FormInput>

            <FormInput
              class="form-input"
              :required="true"
              :errors="errors.password"
            >
              <input
                v-model="loginForm.password"
                type="password"
                placeholder="Password*"
              >
            </FormInput>

            <label class="form-toggle mt-5 mb-4">
              <input
                v-model="loginForm.remember"
                type="checkbox"
                name="remember"
              >
              <span class="toggle-label text-neutral">Remember Me</span>
            </label>

            <button
              type="submit"
              class="btn btn-primary w-full mb-5"
            >
              Sign In
            </button>

            <a
              class="block text-center underline hover:opacity-75"
              href="/password/reset"
              rel="nofollow"
            >
              Forgot Password?
            </a>

            <div class="text-center mt-2">
              Don't have an account?
              <a
                class="underline hover:opacity-75"
                href="/register"
                rel="nofollow"
              >
                Register here
              </a>
            </div>
          </form>
        </div>
        <div class="none lg:flex relative justify-self-end lg:min-h-[500px] lg:min-w-[460px] xl:min-w-[500px]">
          <div
            class="bg-no-repeat bg-cover bg-center w-full absolute left-0 top-0 z-[1] h-full"
            :style="{ backgroundImage: 'url(' + loginImageUrl + ')' }"
          />
          <div class="bg-[#000]/[.5] w-full h-full left-0 top-0 absolute z-[2]" />
          <div class="flex w-full h-full items-center left-0 top-0 absolute z-[3] justify-center">
            <div class="hidden lg:flex flex-col items-center justify-center">
              <div class="text-3xl font-alt text-left mb-6 text-white w-[415px] lg:w-[365px]">
                More properties. More up-to-date listings. Basically, you get a lot. And it's free.
              </div>
              <a
                class="btn btn-light btn-lg"
                href="/register"
                rel="nofollow"
              >
                Create an account
                <VIcon i="arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VModal>
</template>
<script>
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { useRefreshCSRFToken } from '../composables/useRefreshCSRFToken';

let resolveLogin;

export default {
  props: {
    loginImageUrl: {
      type: String,
      default: '',
      required: true,
    },
  },

  setup() {
    const { updateCSRFToken } = useRefreshCSRFToken();

    const updateTokenFromLogin = (newToken) => {
      updateCSRFToken(newToken);
    };

    return { updateTokenFromLogin };
  },

  data() {
    return {
      loginForm: {},
      loginPromise: null,
      userId: null,
      errors: {},
      headerText: 'Sign In / Register',
    };
  },

  watch: (() => {
    const watchers = {};
    ['email', 'password'].forEach((value) => {
      watchers[`loginForm.${value}`] = function () {
        this.clearErrors(value);
      };
    });
    return watchers;
  })(),

  methods: {
    init(params = {}) {
      this.reset();
      if (params.headerText) {
        this.headerText = params.headerText;
      }

      this.loginPromise = new Promise((resolve) => {
        resolveLogin = resolve;
      });
      this.loginPromise.then(() => {
        if (params.callback) {
          params.callback().then(() => location.reload());
        } else {
          location.reload();
        }
      });
    },
    reset() {
      this.loginForm = {
        email: '',
        password: '',
        remember: false,
      };
      this.errors = {};
    },

    submit() {
      this.errors = {};
      return Api.post('/login', this.loginForm)
        .then((response) => {
          this.errors = {};
          this.$modal.hide('login-modal');
          this.updateTokenFromLogin(response.data.csrfToken);
          resolveLogin();
        })
        .catch((err) => {
          if (!err.validation || !err.validation.errors) {
            this.errors = {};
            this.userId = null;
          }

          this.errors = err.validation.errors;

          if (err.validation.errors.activation && err.validation.errors.activation.length > 0) {
            this.userId = err.validation.errors.activation[0];
          }
        });
    },

    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },

    resendActivationEmail() {
      if (!this.userId) {
        return;
      }
      Api.post('/resend-activation', { userId: this.userId })
        .then(() => {
          this.errors = {};
          Alert.toastSuccess('Activation email sent');
        })
        .catch((err) => {
          if (err.validation && err.validation.errors) {
            this.errors = err.validation.errors;
          }
          Alert.toastError('Whoops!', 'Something went wrong.');
        });
    },
  },
};
</script>
