<template>
  <div class="flex flex-col items-center w-full">
    <slot name="header" />
    <div
      class="flex flex-row items-center w-full"
      :class="isCentered ? 'justify-center' : 'justify-start'"
    >
      <div
        :class="headshotClasses"
        :style="headshotStyles"
      >
        <a
          v-if="agent.cardHeadshotUrl"
          :href="agent.profileUrl"
        >
          <img
            class="w-full rounded-full bg-cover bg-center"
            :alt="`${agent.name} photo`"
            :style="{'background-image': 'url(' + agent.cardHeadshotUrl + ')'}"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
            width="90"
            height="90"
          >
        </a>
      </div>
      <div class="ml-4">
        <a
          :href="agent.profileUrl"
          class="hover:opacity-75"
        >
          <p
            :class="[
              't-header',
              agentNameClasses,
              context === 'listing' ? 'text-lg md:text-xl lg:text-2xl' : 'text-lg',
            ]"
            v-text="agent.name"
          />
        </a>
        <div v-if="context === 'agent-search'">
          <div class="text-neutral uppercase text-xs mb-2">
            <div v-text="agent.title" />
            <div v-if="agent.licensesString?.length">
              License No. {{ agent.licensesString }}
            </div>
          </div>
          <div class="text-sm">
            <div v-if="agent.officePhone">
              <span class="uppercase">Office</span>:
              <a
                class="hover:underline hover:opacity-75"
                :href="`tel:+1.${agent.officePhone}`"
                v-text="agent.officePhone"
              />
            </div>
            <div v-if="agent.mobilePhone">
              <span class="uppercase">Mobile</span>:
              <a
                class="hover:underline hover:opacity-75"
                :href="`tel:+1.${agent.mobilePhone}`"
                v-text="agent.mobilePhone"
              />
            </div>
            <div v-if="!agent.officePhone && !agent.mobilePhone && agent.phone">
              <span
                class="uppercase"
                v-text="agent.agentType === 'team' ? `Team` : `Office`"
              />:
              <a
                class="hover:underline hover:opacity-75"
                :href="`tel:+1.${agent.phone}`"
                v-text="agent.phone"
              />
            </div>
            <div v-if="contactAgentLink">
              <slot name="contact-agent-link" />
            </div>
            <a
              :href="agent.profileUrl"
              class="underline block hover:opacity-75"
            >
              View <span v-text="possessivePronoun" /> Profile
            </a>
          </div>
        </div>
        <div v-else>
          <div
            v-if="buySide || agent.licensesString?.length"
            class="uppercase text-neutral text-sm mb-1"
          >
            <div v-if="buySide">
              Represented Buyer
            </div>
            <div v-if="agent.licensesString?.length">
              License No. {{ agent.licensesString }}
            </div>
          </div>
          <div v-if="agent.officePhone">
            <span class="uppercase">Office</span>:
            <a
              :href="`tel:+1.${agent.officePhone}`"
              v-text="agent.officePhone"
            />
          </div>
          <div v-if="agent.mobilePhone">
            <span class="uppercase">Mobile</span>:
            <a
              :href="`tel:+1.${agent.mobilePhone}`"
              v-text="agent.mobilePhone"
            />
          </div>
          <div v-if="agent.agentType === 'team' && agent.phone && !agent.officePhone">
            <span class="uppercase">Team</span>:
            <a
              :href="`tel:+1.${agent.phone}`"
              v-text="agent.phone"
            />
          </div>
          <div v-if="contactAgentLink">
            <slot name="contact-agent-link" />
          </div>
          <div v-if="agent.profileUrl">
            <a
              :href="agent.profileUrl"
              class="underline hover:opacity-75"
            >
              View <span v-text="possessivePronoun" /> profile
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  buySide: {
    type: Boolean,
    default: false,
  },
  agent: {
    type: Object,
    required: true,
  },
  context: {
    type: String,
    default: 'agent-search',
  },
  headshotClasses: {
    type: String,
    default: '',
  },
  headshotStyles: {
    type: Object,
    default: () => {
      return { flex: '0 0 33%' };
    },
  },
  isCentered: {
    type: Boolean,
    default: false,
  },
  agentNameClasses: {
    type: String,
    default: '',
  },
  contactAgentLink: {
    type: Boolean,
    default: true,
  },
});

const possessivePronoun = computed(() => props.agent.agentType === 'agent' ? 'my' : 'our');

</script>
