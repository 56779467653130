<template>
  <div class="relative">
    <ul class="simple-slider-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        :data-thumb="item[urlProp]"
      >
        <img
          :key="index"
          :data-src="item[urlProp]"
          :class="{vertical: item.height > item.width}"
          :alt="item.alt || fallbackAlt"
          :width="item.width ?? undefined"
          :height="item.height ?? undefined"
        >
      </li>
    </ul>
    <button
      v-if="hasMultipleItems"
      class="slider-nav prev"
      @click="prev"
    >
      <span class="sr-only">Previous</span>
      <VIcon
        i="arrow"
        :rotate="180"
      />
    </button>
    <button
      v-if="hasMultipleItems"
      class="slider-nav next"
      @click="next"
    >
      <span class="sr-only">Next</span>
      <VIcon i="arrow" />
    </button>
  </div>
</template>
<script>
import { GalleryInstance } from '../../services/Gallery';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    fallbackAlt: {
      type: String,
      default: '',
    },
    gallery: {
      // todo: Figure out why we need the generic Object type
      type: [Object, GalleryInstance],
      default: () => new GalleryInstance(),
    },
    touchDrag: {
      type: Boolean,
      default: true,
    },
    numberOfSlides: {
      type: Number,
      default: 1,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    urlProp: {
      type: String,
      default: 'url',
    },
  },

  data() {
    return {
      slider: null,
      currentSlide: 0,
    };
  },

  computed: {
    hasMultipleItems() {
      return this.items.length > 1;
    },
  },

  mounted() {
    this.slider = $(this.$el.children[0]).lightSlider({
      item: this.numberOfSlides,
      pager: false,
      controls: false,
      enableTouch: this.touchDrag,
      enableDrag: this.touchDrag,
      loop: this.loop,
      onAfterSlide: this.handleSlideChanged,
      onBeforeStart: this.handleBeforeStart,
    });
  },

  methods: {
    next() {
      this.slider.goToNextSlide();
    },
    prev() {
      this.slider.goToPrevSlide();
    },
    handleSlideChanged(el) {
      this.currentSlide = this.slider.getCurrentSlideCount() - 1;
      this.gallery.setIndex(this.currentSlide);
      this.loadImageAndNeighboringImages(el, this.currentSlide);
    },
    handleBeforeStart(el) {
      const children = el[0].children;
      if (children.length === 1) {
        this.loadImage(el, 0);
      }
      if (children.length > 1) {
        this.loadImageAndNeighboringImages(el, 0);
      }
    },
    loadImageAndNeighboringImages(el, index) {
      const [prevIndex, nextIndex] = this.getNextAndPreviousIndices(el, index);

      this.loadImage(el, index);
      this.loadImage(el, prevIndex);
      this.loadImage(el, nextIndex);
    },
    getNextAndPreviousIndices(el, index) {
      const children = el[0].querySelectorAll('li:not(.clone)');
      const prevIndex = index === 0 ? children.length - 1 : (index - 1);
      const nextIndex = index === (children.length - 1) ? 0 : (index + 1);
      return [prevIndex, nextIndex];
    },
    loadImage(el, index) {
      if (el[0].children.length <= index) {
        return;
      }
      const children = el[0].querySelectorAll('li:not(.clone)');
      const imgEl = children[index].querySelector('img');
      if (!imgEl.getAttribute('src') && imgEl.getAttribute('data-src')) {
        imgEl.setAttribute('src', imgEl.getAttribute('data-src'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slider-nav {
  @apply absolute top-1/2 transform -translate-y-1/2 z-20;
  @apply w-10 h-10 rounded-full text-xl bg-transparent-dark text-white cursor-pointer flex flex-row items-center justify-center;
  &.prev {
    @apply left-3;
  }
  &.next {
    @apply right-3;
  }
  &:disabled {
    @apply cursor-not-allowed opacity-25;
  }
}
</style>
