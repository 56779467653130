import { createApp } from "vue";
import VIcon from "../../components/VIcon";
import SvgVue from 'svg-vue3';
import VueLazyloadNext from "vue-lazyload-next";

const app = createApp({
    data() {
        return {
            //
        }
    }
});

app.use(VueLazyloadNext);
app.use(SvgVue);
app.component('VIcon', VIcon);

app.config.compilerOptions.whitespace = 'preserve';

app.mount('#footer');
