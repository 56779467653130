<template>
  <VModal
    :name="modalName"
  >
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <span class="sr-only">Close modal</span>
      <VIcon i="close" />
    </button>
    <div class="modal-body py-5">
      <ContactForm
        endpoint="forms/contact-agent"
        heading="Request More Information"
        :show-client-type-options="false"
        :show-area-of-interest="false"
        :form-defaults="contactFormDefaults"
        class=" border-none md:border md:border-neutral-300 p-2"
        :payload="payload"
      />
    </div>
  </VModal>
</template>
<script>
export default {
  props: {
    modalName: {
      type: String,
      required: true,
    },
    payload: {
      type: [Function, Object],
      default: () => ({}),
    },
    currentListing: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      listing: this.currentListing,
      development: null,
    };
  },
  computed: {
    contactFormDefaults() {
      return {
        comments: this.initialMessage,
      };
    },
    initialMessage() {
      if (this.listing && this.listing.fullAddress) {
        return "I'm interested in " + this.listing.fullAddress;
      }
      if (this.development && this.development.fullAddress) {
        return "I'm interested in " + this.development.fullAddress;
      }
      return '';
    },
  },
  methods: {
    closeModal() {
      this.errors = {};
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
