<template>
  <div class="mb-12">
    <h1 class="mb-12 mt-10 lg:mt-0 text-4xl text-center">
      My Account
    </h1>
    <LoadingIndicatorBlock
      v-if="showLoader"
    />
    <div
      v-else
      class="grid w-auto gap-8 m-auto justify-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <div
        v-for="tile in tiles"
        :key="tile.id"
        :class="['w-full', tile.eligibleCard ? '' : 'hidden' ]"
      >
        <button
          v-if="tile.eligibleCard"
          type="button"
          class="h-full w-full"
          @click="goToLink(tile.link)"
        >
          <div class="w-auto p-6 bg-neutral-100 h-full text-left">
            <VIcon
              class="text-5xl mt-12 mb-3 text-alternate"
              :i="tile.icon"
            />
            <div
              class="text-2xl"
              v-text="tile.title"
            />
            <p
              class="text-sm mt-1 mb-4"
              v-text="tile.description"
            />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from '../../../components/VIcon';
import AppData from '../../../services/AppData';
import LoadingIndicatorBlock from '../../../website/components/LoadingIndicatorBlock';

export default {
  components: {
    VIcon,
    LoadingIndicatorBlock,
  },

  data() {
    return {
      showLoader: false,
      tiles: [
        {
          id: 1,
          icon: 'user',
          title: 'My Profile',
          description: 'Manage your contact information and password here.',
          link: '/profile',
          eligibleCard: true,
        },
        {
          id: 2,
          icon: 'star-outline-circle',
          title: 'Closing Milestones',
          description: 'Track your home purchase from contract to close and access transaction documents here.',
          link: '/closing-milestones',
          eligibleCard: !!AppData.closingMilestones.length,
        },
        {
          id: 3,
          icon: 'heart-outline-circle',
          title: 'Saved Listings',
          description: 'Keep tabs on all the listings you’ve saved as you search our website.',
          link: '/saved-listings',
          eligibleCard: true,
        },
        {
          id: 4,
          icon: 'search-outline-circle',
          title: 'Saved Searches',
          description: 'Save your search criteria and get notified when new listings match your needs.',
          link: '/saved-searches',
          eligibleCard: true,
        },
        {
          id: 5,
          icon: 'chart-circle',
          title: 'Comparative Market Analysis',
          description: 'Get real-time insights into local market trends with our digital Comparative Market Analysis.',
          link: '/cma-platform',
          eligibleCard: !!AppData.cmaReports.length,
        },
        {
          id: 6,
          icon: 'house-outline-circle',
          title: '@home Reports',
          description: 'Selling or buying a home?  View web stats and comparable properties in your area.',
          link: '/at-home-reports',
          eligibleCard: !!AppData.atHomeReports.length,
        },
      ],
    };
  },

  methods: {
    goToLink(link) {
      if (AppData.closingMilestones.length === 1 && link === '/closing-milestones') {
        this.showLoader = true;
        window.location.assign(window.closingMilestoneUrl + AppData.closingMilestones[0].identifier);
      } else if (AppData.cmaReports.length === 1 && link === '/cma-platform') {
        this.showLoader = true;
        window.location.assign(`/sso/out/portal?p=cma/presentations/${AppData.cmaReports[0].id}`);
      } else if (AppData.atHomeReports.length === 1 && link === '/at-home-reports') {
        this.showLoader = true;
        window.location.assign(`/sso/out/portal?p=cma/presentations/${AppData.atHomeReports[0].id}`);
      } else {
        this.$router.push(link);
      }
    },
  },
};
</script>
