import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                formSubmitted: false,
                modal: {
                    linkUrl: null,
                    listingUrl: null,
                    title: null,
                    videoDescription: null,
                    videoTagline: null,
                    videoUrl: null,
                },
            });
        },

        methods: {
            initVideoModal(modalData) {
                this.modal.linkUrl = modalData.linkUrl;
                this.modal.listingUrl = modalData.listingUrl;
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoTagline = modalData.tagline;
                this.modal.videoUrl = modalData.url;
            },
            openVideoModal() {
                this.$modal.show('video-modal', {
                    description: null,
                    linkUrl: null,
                    listingUrl: null,
                    tagline: this.videoTagline || this.tagline || null,
                    title: this.videoTitle,
                    url: this.videoLinkUrl,
                });
            },
        },
    });
}
