<template>
    <v-tabs-panel v-bind="$attrs">
        <slot></slot>
    </v-tabs-panel>
</template>

<script>
    export default {
        created() {
            console.error(
                "<tab/> is deprecated and replaced by <v-tabs-panel/> " +
                "to comply with VueJS style guide for tightly coupled components."
            );
        }
    }
</script>
