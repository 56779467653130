import { createApp } from 'vue';
import Api from "../../services/Api";
import Alert from "../../services/Alert";

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                searchString: '',
            });
        },

        methods: {
            handleItemSelected(event) {
                if (event && event.label && event.category === 'neighborhood') {
                    this.searchString = event.label;
                    this.appliedFilters.neighborhoodID = event.id;
                } else if (event && event.url) {
                    return window.location.href = event.url;
                }
            },

            submitSearch() {
                Api.post('/developments/search/init', {
                    searchString: this.searchString,
                    searchFilters: this.appliedFilters
                }).then(response => {
                    window.location.href = response.data.url;
                }).catch(err => Alert.error(err));
            },

        }
    });
}
