<template>
  <div>
    <div
      v-if="submitted"
      class="my-8 flex flex-col justify-center"
    >
      <div class="space-y-4">
        <h2 class="text-4xl text-center t-header">
          Thank you!
        </h2>
        <p class="text-center">
          A member of our team will be in touch soon.
        </p>
        <div
          v-if="primaryElement"
          class="text-center"
        >
          <a
            href="/"
            class="btn btn-primary btn-lg"
          >
            Back To Home
          </a>
        </div>
      </div>
    </div>
    <div
      v-else
      class="space-y-4"
    >
      <component
        :is="primaryElement ? 'h2' : 'h3'"
        v-if="heading"
        class="t-header text-center"
        :class="primaryElement ? 'text-4xl' : 'text-2xl'"
      >
        {{ heading }}
      </component>
      <p
        v-if="text"
        class="text-center"
        v-text="text"
      />

      <slot name="description" />
      <slot name="staff" />
      <slot name="agents" />

      <form
        class="flex-1 flex flex-col gap-3"
        @submit.prevent="submit"
      >
        <div class="grid grid-cols-1 gap-3">
          <FormInput
            class="!mb-0"
            label="Name"
            :required="true"
            :errors="errors.firstName || errors.lastName"
          >
            <input
              v-model="form.fullName"
              type="text"
              placeholder="Enter your full name"
              @change="clearErrors('firstName') || clearErrors('lastName')"
            >
          </FormInput>
        </div>
        <FormInput
          class="!mb-0"
          label="Email"
          :required="true"
          :errors="errors.email"
        >
          <input
            v-model="form.email"
            type="email"
            placeholder="Enter your email address"
            @change="clearErrors('email')"
          >
        </FormInput>
        <FormInput
          class="!mb-0"
          label="Phone (Optional)"
          :errors="errors.phone"
        >
          <input
            v-model="form.phone"
            v-mask="'(###) ###-####'"
            type="tel"
            placeholder="(   ) ___ - ____"
            @change="clearErrors('phone')"
          >
        </FormInput>
        <div
          v-if="showClientTypeOptions"
          class="flex mt-5"
        >
          <div>
            <span class="flex justify-start mr-1 sm:mr-3">I am</span>
          </div>
          <FormToggle
            label="Buying"
            class="flex-1"
          >
            <input
              v-model="form.buying"
              type="checkbox"
            >
          </FormToggle>
          <FormToggle
            label="Selling"
            class="flex-1"
          >
            <input
              v-model="form.selling"
              type="checkbox"
            >
          </FormToggle>
          <FormToggle
            label="Renting"
            class="flex-1"
          >
            <input
              v-model="form.renting"
              type="checkbox"
            >
          </FormToggle>
        </div>
        <div v-if="showNeighborhoods">
          <FormInput
            class="!mb-0"
            label="Neighborhoods"
            :required="requiredFields.includes('neighborhoods')"
            :errors="errors.neighborhoods"
          >
            <VueMultiselect
              v-model="form.neighborhoods"
              :options="neighborhoodNames"
              :multiple="true"
              :taggable="true"
              @tag="addNeighborhoodTag"
              @select="errors.neighborhoods = null"
            >
              <template #caret>
                <VIcon
                  class="text-xs m-2 my-4 hover:text-neutral"
                  i="caret"
                  style="position: absolute; right: 0"
                />
              </template>
            </VueMultiselect>
          </FormInput>
        </div>
        <FormInput
          v-else-if="showAreaOfInterest"
          label="Area of Interest (Optional)"
          class="!mb-0"
        >
          <input
            v-model="form.areaOfInterest"
            type="text"
            placeholder="Buy, Rent, Sell, General Inquiry, Join Us "
          >
        </FormInput>
        <div v-if="showOffices">
          <FormInput
            class="!mb-0"
            label="Office Location"
            :required="requiredFields.includes('office')"
            :errors="errors.office"
          >
            <VueMultiselect
              v-model="form.office"
              :options="officeOptions"
              :multiple="false"
              :taggable="false"
              @select="errors.office = null"
            >
              <template #caret>
                <VIcon
                  class="text-xs m-2 my-4 hover:text-neutral"
                  i="caret"
                  style="position: absolute; right: 0"
                />
              </template>
            </VueMultiselect>
          </FormInput>
        </div>
        <FormInput
          v-if="showComments"
          class="!mb-0 flex flex-col flex-1"
          :required="requiredFields.includes('comments')"
          label="Message"
          :errors="errors.comments"
        >
          <textarea
            v-model="form.comments"
            :placeholder="commentsPlaceholderText"
            class="flex-1"
            :rows="primaryElement ? 8 : 3"
            @change="clearErrors('comments')"
          />
        </FormInput>
        <FormUserAgreement v-if="showUserAgreement" />
        <button
          type="button"
          class="mt-2 btn btn-primary btn-lg w-full"
          @click="submit"
          v-text="submitButtonLabel"
        />
        <slot name="disclaimer" />
        <p
          v-if="showRequiredHint"
          class="text-sm"
        >
          * Indicates required field.
        </p>
        <FormUserConsent v-if="showUserConsent" />
      </form>
    </div>
  </div>
</template>
<script>
import CurrentUser from '../../../services/CurrentUser';
import FormInput from '../../../components/FormInput';
import FormToggle from '../../../components/FormToggle';
import Api from '../../../services/Api.js';
import Alert from '../../../services/Alert.js';
import { merge } from 'lodash';
import LeadConversion from '../../services/LeadConversion.js';
import FormUserAgreement from '../FormUserAgreement.vue';
import FormUserConsent from '../FormUserConsent.vue';
import VIcon from '../../../components/VIcon.vue';
import VueMultiselect from 'vue-multiselect';
import { submitWithRecaptcha } from '../../../recaptcha';

const currentUser = CurrentUser.isGuest
  ? {}
  : {
      fullName: `${CurrentUser.firstName} ${CurrentUser.lastName}`,
      email: CurrentUser.email,
    };

export default {
  components: {
    VIcon,
    FormUserAgreement,
    FormUserConsent,
    FormToggle,
    FormInput,
    VueMultiselect,
  },

  props: {
    contactType: {
      type: String,
      default: 'default',
    },
    heading: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    primaryElement: {
      type: Boolean,
      default: false,
    },
    submitButtonLabel: {
      type: String,
      default: 'Submit',
    },
    showClientTypeOptions: {
      type: Boolean,
      default: true,
    },
    showOffices: {
      type: Boolean,
      default: false,
    },
    showNeighborhoods: {
      type: Boolean,
      default: false,
    },
    showAreaOfInterest: { // todo: phase out in favor of neighborhoods
      type: Boolean,
      default: true,
    },
    showUserAgreement: {
      type: Boolean,
      default: true,
    },
    showUserConsent: {
      type: Boolean,
      default: true,
    },
    showRequiredHint: {
      type: Boolean,
      default: true,
    },
    showComments: {
      type: Boolean,
      default: true,
    },
    commentsPlaceholder: {
      type: String,
      default: 'Special needs or preferences',
    },
    agentId: {
      type: Number,
      default: null,
    },
    teamId: {
      type: Number,
      default: null,
    },
    formDefaults: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: [Function, Object],
      default: () => ({}),
    },
    endpoint: {
      type: String,
      default: 'forms/contact-us',
    },
    requiredFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submitted'],
  data() {
    return {
      form: {
        ...currentUser,
        ...this.formDefaults,
        neighborhoods: [],
      },
      submitted: false,
      errors: {},
      neighborhoods: [],
      offices: [],
    };
  },
  computed: {
    commentsPlaceholderText() {
      return this.commentsPlaceholder;
    },
    neighborhoodNames() {
      return this.neighborhoods.map(n => n.name);
    },
    officeOptions() {
      return this.offices.map(o => o.name);
    },
  },

  mounted() {
    if (this.showNeighborhoods) {
      this.form.neighborhoods = [];
      this.fetchNeighborhoods();
    } else {
      this.form.neighborhoods = null;
    }
    if (this.showOffices) {
      this.fetchOffices();
    }
  },

  methods: {
    submit() {
      submitWithRecaptcha((recaptchaResponse) => {
        const form = {
          recaptchaResponse,
          ...this.form,
        };
        if (this.agentId) {
          form.agentID = this.agentId;
          form.agentType = 'agent';
        } else if (this.teamId) {
          form.agentID = this.teamId;
          form.agentType = 'team';
        }

        let payload;
        try {
          payload = typeof this.payload === 'function' ? this.payload(form) : merge(form, this.payload);
        } catch (error) {
          Alert.error(error);
          return;
        }

        if (!this.showOffices) {
          payload._ignoreOffice = true;
        }
        if (!this.showComments) {
          payload._ignoreComments = true;
        }
        payload.contactType = this.contactType;

        Api.post(this.endpoint, payload)
          .then(() => {
            Alert.toastSuccess('Form submitted successfully!');
            this.submitted = true;
            this.$emit('submitted');
            LeadConversion.send();
          })
          .catch(err => {
            if (err.validation && err.validation.errors) {
              this.errors = err.validation.errors;
            } else {
              Alert.toastError('Whoops!', 'Something went wrong. Please refresh and try again.');
            }
          });
      });
    },
    clearErrors(fieldName) {
      if (this.errors && this.errors[fieldName]) {
        this.errors[fieldName] = [];
      }
    },
    fetchNeighborhoods() {
      Api.get('/neighborhoods').then(response => {
        this.neighborhoods = response.data;
      });
    },
    addNeighborhoodTag(neighborhood) {
      this.neighborhoods.push(neighborhood);
      this.form.neighborhoods.push(neighborhood);
    },
    fetchOffices() {
      Api.get('/offices').then(response => {
        this.offices = response.data;
      });
    },
  },
};
</script>
