import { computed } from "vue";

export function useDropdown(props) {
    const anchorClass = computed(() => {
        if (props.anchor === 'right') {
            return 'right-0 origin-top-right';
        }
        if (props.anchor === 'left') {
            return 'left-0 origin-top-left';
        }
        return 'origin-top centered-popover';
    });

    return {
        anchorClass,
    }
}
