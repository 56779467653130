import { createApp } from 'vue';
import Gallery from '../../services/Gallery';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                errors: {},
                gallery: Gallery.create(appData.images),
                contactFormPayload: payload => {
                    payload.listingDevelopmentDetails = { id: this.development.id };
                    return payload;
                },
            });
        },

        methods: {
            fullscreenGallery(event) {
                this.gallery.fullscreen(event.target);
            },
            initTab(tab) {
                if (tab.name === 'Market Trends') {
                    this.showMarketTrends = true;
                }
            },

            scheduleTour() {
                this.$modal.show('schedule-virtual-tour', { development: this.development });
            },
        },
    });
}
