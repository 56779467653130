<template>
    <div v-if="items.length > 0">
        <div
            class="carousel select-none"
            :class="[lastNavigated, maxWidthClass]"
            :style="{height: carouselHeight, 'grid-template-columns': outerGridCols}"
        >
            <div class="nav-prev">
                <button
                    type="button"
                    :disabled="!hasPreviousPage"
                    @click="prev"
                    :class="[iconSizeClass, 'scale-up-on-hover']"
                    v-show="arrowButtonsVisible"
                >
                    <v-icon
                        :i="navButtonIcon"
                        :rotate="90"
                    ></v-icon>
                </button>
            </div>
            <div class="nav-next">
                <button
                    type="button"
                    :disabled="!hasNextPage"
                    @click="next"
                    :class="[iconSizeClass, 'scale-up-on-hover']"
                    v-show="arrowButtonsVisible"
                >
                    <v-icon
                        :i="navButtonIcon"
                        :rotate="-90"
                    ></v-icon>
                </button>
            </div>

            <div
                @touchstart.prevent="startSwipe"
                @touchend.prevent="stopSwipe"
                @touchmove.prevent="moveHandler"
                @mousedown="startSwipe"
                @mouseup="stopSwipe"
                @mouseleave="stopSwipe"
                @mousemove="moveHandler"
                :style="{transform: 'translateX(' + xTransform + 'px)'}"
            >
                <transition-group
                    tag="div"
                    :name="animation"
                    :class="[colsClass, 'carousel-content']"
                    :style="{'grid-template-areas': `'${innerGridAreas}'`}"
                    ref="carouselContent"
                >
                    <div
                        v-for="(item, index) in visibleItems"
                        :key="'v-carousel-item-' + item._uniqueKey"
                        :style="{'grid-area': 'card-' + index}"
                    >
                        <slot :item="item"></slot>
                    </div>
                </transition-group>
            </div>
            <div v-if="preloadImages" class="absolute overflow-hidden h-0 w-0">
                <img
                    v-for="item in nextItems"
                    :src="item[imageKey]"
                    :key="'v-carousel-preloaded-img-' + item._uniqueKey"
                    alt=""
                >
            </div>
        </div>
        <section v-if="useDotPagination">
            <v-carousel-dots :current-page="currentPage" :totalCount="items.length" :dots="dotCount"/>
        </section>
    </div>
</template>
<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
const fullTailwindConfig = resolveConfig(tailwindConfig);

export default {
    props: {
        items: {
            type: Array,
            required: true,
            default: [],
        },
        countPerPage: {
            type: [Number, Object],
            default: () => ({xs: 1, sm: 2, md: 3, lg: 4}),
            validator: function (value) {
                if (typeof value == "number") {
                    return Number.isInteger(value) && value > 0;
                }

                let breakpoints = ['xs', 'sm', 'md', 'lg'];
                return Object.keys(value).every(k =>
                    breakpoints.includes(k) && Number.isInteger(value[k]) && value[k] > 0
                );
            },
        },
        animation: {
            type: String,
            default: 'slide',
            validator: function (value) {
                return [
                    'slide',
                    'none'
                ].indexOf(value) !== -1
            }
        },
        showDots: {
            type: Boolean,
            default: null,
        },
        dots: {
            type: Number,
            default: null,
        },
        navButtonIcon: {
            type: String,
            default: "caret-outline-rev-circle",
        },
        navButtonIconSize: {
            type: String,
            default: "3xl",
            validator: (value) => {
                return ['xs', 'sm', 'md', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'].indexOf(value) !== -1;
            }
        },
        preloadImages: {
            type: Boolean,
            default: false,
        },
        imageKey: {
            type: String,
            default: 'imageUrl',
        },
    },

    data() {
        return {
            currentPage: 0,
            perPage: 1,
            lastNavigated: 'next',
            carouselHeight: 'auto',
            xTransform: 0,
            xTouchStart: 0,
            arrowButtonsVisible: false,
            colsClass: '',
            maxWidthClass: '',
            showDotsDefault: false,
            maxDotsForScreen: 0,
        }
    },

    computed: {
        nextPage() {
            return this.currentPage + 1;
        },
        keyedItems() {
            return this.items.map((item, itemIdx) => ({...item, _uniqueKey: item.id ?? itemIdx}));
        },
        visibleItems() {
            if (this.items.length === 0) {
                return [];
            }
            if (this.currentPage === this.totalPages - 1) {
                return this.keyedItems.slice(this.items.length - this.perPage);
            }
            return this.keyedItems.slice(
                this.currentPage * this.perPage,
                this.currentPage * this.perPage + this.perPage
            );
        },
        nextItems() {
            if (!this.hasNextPage) {
                return [];
            }
            if (this.nextPage === this.totalPages - 1) {
                return this.keyedItems.slice(this.items.length - 4);
            }
            return this.keyedItems.slice(
                this.nextPage * this.perPage,
                this.nextPage * this.perPage + this.perPage
            );
        },
        totalPages() {
            return Math.ceil(this.items.length / this.perPage);
        },
        hasPreviousPage() {
            return this.currentPage > 0;
        },
        hasNextPage() {
            return this.currentPage < this.totalPages - 1;
        },
        useDotPagination() {
            return this.showDots === null ? this.showDotsDefault : this.showDots;
        },
        dotCount() {
            return Math.min(...[
                ...(this.maxDotsForScreen ? [this.maxDotsForScreen] : []),
                this.dots ?? this.totalPages,
            ]);
        },
        outerGridCols() {
            let buttonGridWidth = "";
            switch (this.navButtonIconSize) {
                case 'xs':
                case 'sm':
                case 'base':
                case 'md':
                    buttonGridWidth = '1rem';
                    break;
                case 'lg':
                case 'xl':
                    buttonGridWidth = '1.5rem';
                    break;
                case '2xl':
                case '3xl':
                    buttonGridWidth = '2rem';
                    break;
                case '4xl':
                    buttonGridWidth = '2.75rem';
                    break;
                case '5xl':
                case '6xl':
                    buttonGridWidth = '3.5rem';
                    break;
                default:
                    buttonGridWidth = '2rem';
                    break;
            }
            return `${buttonGridWidth} 1fr ${buttonGridWidth}`;
        },
        innerGridAreas() {
            return _.range(0,this.perPage).map((v, idx) => `card-${idx}`).join(' ');
        },
        iconSizeClass() {
            let fontSize = this.navButtonIconSize === 'md' ? 'base' : this.navButtonIconSize
            return `text-${fontSize}`;
        },
        maxCountPerPage() {
            if (typeof this.countPerPage === 'number') {
                return {
                    xs: this.countPerPage,
                    sm: this.countPerPage,
                    md: this.countPerPage,
                    lg: this.countPerPage,
                };
            }

            let breakpoints = ['xs', 'sm', 'md', 'lg'];
            if (breakpoints.every(bp => bp in this.countPerPage)) {
                return this.countPerPage;
            }

            let retObj = {...this.countPerPage};
            breakpoints.forEach((bp, bpIdx) => {
                if (bp in retObj) {
                    return;
                }
                // If value not provided at breakpoint, copy value from next smallest breakpoint, otherwise set to 1.
                retObj[bp] = (bpIdx > 0) ? retObj[breakpoints[bpIdx-1]] : 1;
            });
            return retObj;
        },
    },

    watch: {
        perPage() {
            this.currentPage = 0;
        },
    },

    methods: {
        initPerPage() {
            this.perPage = this._determinePerPage();
        },

        startSwipe(event) {
            this.moving = true;
            this.xTouchStart = event.clientX || event.changedTouches[0].screenX;
        },

        stopSwipe(event) {
            // Introduced 5px buffer when swiping to accommodate more sensitive touch screens
            if (event.changedTouches && Math.abs(event.changedTouches[0].screenX - this.xTouchStart) < 5) {
                event.target.click();
            }
            this.moving = false;
            this.xTouchStart = 0;
            this.xTransform = 0;
        },

        handleSwipe(direction) {
            direction === 'left' ? this.next() : this.prev();
        },

        moveHandler: function (event) {
            if (this.moving) {
                let distance = (event.clientX || event.changedTouches[0].screenX) - this.xTouchStart;
                let multiplier = distance < 0 ? -1 : 1;
                distance = Math.abs(distance);
                if (distance > Math.min(Math.round(window.innerWidth * 0.3), 300)) {
                    this.handleSwipe(multiplier > 0 ? 'right' : 'left');
                    this.stopSwipe({});
                } else {
                    this.xTransform = multiplier * Math.round(Math.sqrt(distance) * 2);
                }
            }
        },

        _determinePerPage() {
            let perPage;
            let totalCount = this.items.length;
            let isMobileScreen = window.innerWidth < parseInt(fullTailwindConfig.theme.screens.sm);
            let columnGap = '';
            let maxWidth = '';

            if (isMobileScreen) {
                perPage = Math.min(this.maxCountPerPage['xs'], totalCount);
                this.arrowButtonsVisible = false;
                this.showDotsDefault = totalCount > perPage;
                this.maxDotsForScreen = 12;
            } else if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.md)) {
                let maxPerPage = this.maxCountPerPage['sm'];
                this.maxDotsForScreen = 24;
                if (totalCount <= maxPerPage) {
                    perPage = totalCount;
                    this.arrowButtonsVisible = false;
                    this.showDotsDefault = false;
                } else {
                    perPage = maxPerPage;
                    this.arrowButtonsVisible = true;
                    this.showDotsDefault = false;
                    columnGap = 'gap-2';
                }
            } else if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.lg)) {
                let maxPerPage = this.maxCountPerPage['md'];
                this.maxDotsForScreen = 30;
                if (totalCount <= maxPerPage) {
                    perPage = totalCount;
                    this.arrowButtonsVisible = false;
                    this.showDotsDefault = false;
                    columnGap = (totalCount === 1) ? '' : 'gap-4';
                    maxWidth = (totalCount <= maxPerPage/2) ? 'max-w-2xl' : 'max-w-3xl';
                } else {
                    perPage = maxPerPage;
                    this.arrowButtonsVisible = true;
                    this.showDotsDefault = false;
                    columnGap = 'gap-2';
                }
            } else {
                let maxPerPage = this.maxCountPerPage['lg'];
                this.maxDotsForScreen = 40;
                if (totalCount < maxPerPage) {
                    perPage = totalCount;
                    this.arrowButtonsVisible = false;
                    this.showDotsDefault = false;
                    columnGap = (totalCount === 1) ? '' : 'gap-8';
                    maxWidth = (totalCount <= maxPerPage/2) ? 'max-w-3xl' : 'max-w-6xl';
                } else if (totalCount === maxPerPage) {
                    perPage = totalCount;
                    this.arrowButtonsVisible = false;
                    this.showDotsDefault = false;
                    columnGap = 'gap-2';
                } else {
                    perPage = maxPerPage;
                    this.arrowButtonsVisible = true;
                    this.showDotsDefault = false;
                    columnGap = 'gap-2';
                }
            }

            this.colsClass = isMobileScreen ? '' : `grid-cols-${perPage} ${columnGap}`;
            this.maxWidthClass = maxWidth.length ? `m-auto ${maxWidth}` : '';
            return perPage;
        },

        prev() {
            if (this.hasPreviousPage) {
                this.navigate('prev');
            }
        },

        next() {
            if (this.hasNextPage) {
                this.navigate('next');
            }
        },

        navigate(direction) {
            this.lastNavigated = direction;
            this.carouselHeight = this.$el.clientHeight + 'px';
            this.$nextTick(() => {
                this.carouselHeight = 'auto';
                direction === 'next' ? this.currentPage++ : this.currentPage--;
            });
        },
    },

    created() {
        window.addEventListener('resize', _.debounce(() => this.initPerPage(), 100));
    },

    beforeUpdate() {
        this.initPerPage();
    },

    mounted() {
        this.initPerPage();
    },
}
</script>
<style lang="scss" scoped>
.carousel {
    @screen sm {
        @apply grid grid-cols-3 gap-2;
        grid-template-areas: "prev-btn visible-items next-btn";
    }

    .carousel-content {
        grid-area: visible-items;
        @apply grid;
    }

    .slide-leave-active, .slide-enter-active {
        transition: all 0.3s ease;
    }
    &.prev {
        .slide-leave-to {
            transform: translateX(95vw);
        }
        .slide-enter-from {
            transform: translateX(-95vw);
        }
    }
    &.next {
        .slide-leave-to {
            transform: translateX(-95vw);
        }
        .slide-enter-from {
            transform: translateX(95vw);
        }
    }

    .nav-next, .nav-prev {
        button {
            @apply text-black opacity-50;
            @screen sm {
                @apply flex;
            }
            z-index: 995;
            outline: none;

            &:disabled {
                @apply cursor-not-allowed opacity-25;
            }

            &:hover:not(:disabled) {
                @apply opacity-75;
            }
        }
    }
    .nav-prev {
        grid-area: prev-btn;
    }
    .nav-next {
        grid-area: next-btn;
    }
    .nav-next, .nav-prev {
        @apply flex flex-col justify-center items-center;
    }
}
.currently-sliding {
    @apply relative;
}
</style>
