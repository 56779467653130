<template>
  <div class="expandable-card-container">
    <div class="expandable-card"
         :class="{expanded: expanded, 'is-first': isFirst, 'is-last': isLast}"
         @mouseenter="expand"
         @mouseleave="collapse"
         @click="toggle"
         ref="card"
         :style="{'z-index': expanded ? 39 : 39 - index}"
    >
      <img
          :src="item.imageUrl || item.thumbnail"
          class="pointer-events-none"
          alt=""
      >
      <FadeTransition>
        <div v-if="expanded">
          <div class="video-icon absolute text-center w-full">
            <button
                @click="openVideoModal"
                class="text-white text-6xl hover:text-neutral scale-up-on-hover"
            >
              <v-icon i="youtube"></v-icon>
              <span class="sr-only">Open Video Modal</span>
            </button>
          </div>
        </div>
      </FadeTransition>
      <div class="details-container">
        <FadeTransition mode="out-in">
          <div v-if="expanded" class="expanded-content" key="expandedContainer">
            <div class="text-xl font-bold pb-1" v-text="item.title" key="expandedTextSize"></div>
            <div v-text="tagline"></div>
          </div>
          <div v-else class="collapsed-content" key="collapsedContainer">
            <div class="text-lg font-bold pb-1 leading-6" v-text="item.title" key="collapsedTextSize"></div>
            <div v-text="tagline"></div>
          </div>
        </FadeTransition>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, computed } from 'vue';
import { useContentCard } from "../composables/useContentCard";

export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    listingTitle: {
      type: String,
      default: '',
      required: false
    },
    type: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    const modal = inject('modal');

    function openVideoModal() {
      modal.show('video-modal', {
        url: props.item.videoLinkUrl || props.item.url,
        linkUrl: props.item.linkUrl,
        listingUrl: (props.item && props.item.listing) ? props.item.listing.listingUrl : null,
        title: props.item.title,
        description: props.item.videoDescription || props.item.description,
        tagline: props.item.videoTagline || props.item.tagline
      });
    }

    const tagline = computed(() => {
      const line = props.item.videoTagline || props.item.tagline;
      const size = 75;
      if (!line) {
        return '';
      }

      return line.length <= size ? line : line.substring(0, size).trim() + '...'
    });

    const {
      expand,
      collapse,
      doExpand,
      doCollapse,
      toggle,
    } = useContentCard(props, emit);

    return {
      openVideoModal,
      expand,
      collapse,
      doExpand,
      doCollapse,
      toggle,
      tagline,
    }
  }
}
</script>

<style lang="scss" scoped>
.expandable-card-container {
  @apply relative m-auto;
  max-width: 360px;
  @screen sm {
    height: 20rem;
  }
  @screen md {
    height: 24rem;
  }
}

.expandable-card {
  @apply flex flex-col bg-white;
  > img {
    @apply w-full object-cover object-center bg-neutral-400;
    height: 12rem;
    @screen md {
      height: 10rem;
    }
    @screen xl {
      height: 12rem;
    }
    transition: height 0.3s ease;
  }

  .video-icon {
    top: 20%;
  }

  @screen sm {
    @apply absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  .details-container {
    @apply flex-1 m-4 relative;
    @screen sm {
      > div {
        @apply absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &:not(.expanded) {
    &:hover {
      @apply shadow-md;
    }

    @apply cursor-pointer;
  }

  transition: all 0.3s ease-in-out;

  &.expanded {
    top: -4rem;
    left: -4rem;
    right: -4rem;
    bottom: -8rem;

    &.is-last {
      left: -8rem;
      right: 0;
    }

    &.is-first {
      left: 0;
      right: -8rem;
    }

    @apply shadow-lg text-lg;
    transition: all 0.3s ease-in-out;

    > img {
      @apply border-b-4 border-black;
      height: 16rem;
      @screen md {
        height: 14rem;
      }
      @screen xl {
        height: 16rem;
      }
    }
  }
}
</style>
