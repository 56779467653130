<!-- max-h-24 -->
<template>
  <div
    :class="['media-card-container', { 'scale-with-media': scaleWithMedia }]"
    :style="{ 'paddingBottom': scaleWithMedia ? cardAspectRatioPadding : 'inherit' }"
  >
    <div :class="[flexDirection, 'media-card-content-container']">
      <div
        :class="[mediaClass, 'media-card-image', {
          'lg:h-full lg:flex-1 lg:px-12': scaleWithMedia,
          'w-full mb-4 lg:w-1/2 lg:mb-0': !scaleWithMedia,
        }]"
        :style="scaleWithMedia ? thumbnailStyleObj : {}"
        :data-aos="mediaAnimationAttr"
      >
        <img
          v-if="!scaleWithMedia"
          v-lazy="imgSrc"
          class="w-full px-0 bg-neutral-100"
          :data-aos="mediaAnimationAttr"
          :alt="heading"
        >
        <button
          v-if="mediaItem.type === 'video' && mediaItem.src"
          class="media-card-video-button"
          :class="mediaCardVideoButtonClass"
          @click="openVideoModal"
        >
          <VIcon
            class="media-card-video-button-icon"
            i="youtube-outline-circle"
          />
          <span class="sr-only">Open video modal</span>
        </button>
      </div>
      <div
        class="lg:flex-1 p-4 sm:px-8 md:px-10 lg:px-12 lg:h-full flex flex-col justify-center"
        :class="cardClass"
      >
        <slot>
          <div
            v-if="heading"
            class="flex flex-row items-center mb-4"
          >
            <div class="w-20 border-b border-primary-100 mr-4" />
            <div
              class="italic text-secondary font-alt"
              v-text="heading"
            />
          </div>
          <h1
            class="text-2xl lg:text-3xl xl:text-4xl t-header text-left"
            v-text="title"
          />
          <div v-if="formattedText">
            <!-- eslint-disable -->
            <div
              v-if="description"
              class="basic-html text-sm xl:text-base mt-4 xl:mt-8 text-left"
              v-html="description"
            />
            <!-- eslint-enable -->
          </div>
          <div v-else>
            <p
              v-if="description"
              class="text-sm xl:text-base mt-4 xl:mt-8 text-center lg:text-left"
              v-text="description"
            />
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';

const fullTailwindConfig = resolveConfig(tailwindConfig);

export default {
  props: {
    scaleWithMedia: {
      type: Boolean,
      default: true,
    },
    formattedText: {
      type: Boolean,
      default: false,
    },
    mediaItem: {
      type: Object,
      required: true,
      validator: (val) => {
        const requiredProps = ['src', 'type'];
        return requiredProps.every(prop => val[prop]);
      },
    },
    heading: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    mediaSpacing: {
      type: String,
      default: '',
    },
    mediaPosition: {
      type: String,
      default: 'left',
      validator: (val) => {
        return ['left', 'right'].indexOf(val) > -1;
      },
    },
    cardClass: {
      type: String,
      default: '',
    },
    mediaCardVideoButtonClass: {
      type: String,
      default: 'text-white',
    },
    mediaClass: {
      type: String,
      default: '',
    },
    mediaBackgroundPosition: {
      type: String,
      default: '',
    },
    breakpointAspectRatios: {
      type: Object,
      default: () => ({}),
      validator: (value) => {
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
        return Object.keys(value).every(bp => breakpoints.includes(bp) && !isNaN(value[bp]) && value[bp] > 0);
      },
    },
    mediaAnimation: {
      type: String,
      default: 'none',
      validator: (val) => {
        return ['none', 'slideLeft', 'slideRight', 'slideUp'].indexOf(val) > -1;
      },
    },
  },

  data() {
    return {
      cardAspectRatioPadding: '28.125%',
      imageAspectRatioPadding: '0px',
    };
  },

  computed: {
    flexDirection() {
      if (this.mediaSpacing !== '') {
        return this.mediaPosition === 'right' ? 'lg:flex-row-reverse lg:space-x-2 lg:space-x-reverse' : 'lg:flex-row space-x-0 lg:space-x-2';
      }
      return this.mediaPosition === 'right' ? 'lg:flex-row-reverse' : 'lg:flex-row';
    },
    imgSrc() {
      let imageSrc;
      if (this.mediaItem.type === 'video') {
        imageSrc = this.mediaItem.thumbnail
          ? this.mediaItem.thumbnail
          : '';
      } else {
        imageSrc = this.mediaItem.src ? this.mediaItem.src : '';
      }
      return imageSrc;
    },
    thumbnailStyleObj() {
      let bgImage;
      if (this.mediaItem.type === 'video') {
        bgImage = this.mediaItem.thumbnail
          ? `url('${this.mediaItem.thumbnail}')`
          : 'none';
      } else {
        bgImage = this.mediaItem.src ? `url('${this.mediaItem.src}'` : 'none';
      }

      return {
        backgroundImage: bgImage,
        backgroundColor: 'black',
        backgroundPosition: this.mediaBackgroundPosition,
        paddingBottom: this.imageAspectRatioPadding,
      };
    },
    mediaAnimationAttr() {
      if (this.mediaAnimation === 'none') {
        return '';
      }
      if (this.mediaAnimation === 'slideLeft') {
        return 'slide-left';
      } else if (this.mediaAnimation === 'slideRight') {
        return 'slide-right';
      } else if (this.mediaAnimation === 'slideUp') {
        return 'fade-up';
      }
      return '';
    },
  },

  created() {
    window.addEventListener('resize', _.debounce(() => this.determineAspectRatio(), 100));
  },

  mounted() {
    this.determineAspectRatio();
  },

  methods: {
    openVideoModal() {
      this.$modal.show('video-modal', {
        url: this.mediaItem.src,
        title: this.title,
        description: this.description,
      });
    },
    determineAspectRatio() {
      let aspectRatio;

      if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.sm)) {
        aspectRatio = this.breakpointAspectRatios.xs;
      } else if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.md)) {
        aspectRatio = this.breakpointAspectRatios.sm;
      } else if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.lg)) {
        aspectRatio = this.breakpointAspectRatios.md;
      } else if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.xl)) {
        aspectRatio = this.breakpointAspectRatios.lg;
      } else {
        aspectRatio = this.breakpointAspectRatios.xl;
      }
      aspectRatio = aspectRatio || (16 / 9);

      if (window.innerWidth < parseInt(fullTailwindConfig.theme.screens.lg)) {
        this.cardAspectRatioPadding = '0px';
        this.imageAspectRatioPadding = `${(1 / aspectRatio) * 100}%`;
      } else if ((window.innerWidth >= parseInt(fullTailwindConfig.theme.screens.xl)) && this.formattedText === true) {
        this.cardAspectRatioPadding = `${(1 / aspectRatio) * 60}%`;
      } else {
        this.cardAspectRatioPadding = `${(1 / aspectRatio) * 50}%`;
        this.imageAspectRatioPadding = '0px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-card-container {
  @apply relative p-0;

  &.scale-with-media {
    .media-card-content-container {
      @screen lg {
        @apply absolute top-0 right-0 h-full w-full flex items-center;
      }
    }
  }

  .media-card-content-container {
    @screen lg {
      @apply flex items-center;
    }
  }

  .media-card-image {
    @apply relative bg-center bg-cover;
  }

  .media-card-video-button {
    @apply absolute top-0 left-0 h-full w-full flex flex-row items-center justify-center;
    @apply cursor-pointer transition;
    font-size: 4.5rem;

    @screen xl {
      font-size: 5.5rem;
    }

    .media-card-video-button-icon {
      @apply transition;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);

      .media-card-video-button-icon {
        @apply transform scale-110;
      }
    }
  }
}
</style>
