<template>
  <VModal
    name="gallery-grid-modal"
    size="3xl"
    :backdrop="true"
    @after-leave="clear"
  >
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <VIcon i="close" />
      <span class="sr-only">Close modal</span>
    </button>
    <div class="modal-body py-5">
      <ListingDetailToolbar
        :gallery-layout="true"
        :compact-buttons="true"
        :virtual-tour-url="virtualTourUrl"
        :video-url="videoUrl"
        :brochure-url="brochureUrl"
        :has-real-vision-virtual-tour="hasRealVisionVirtualTour"
        :listing="listing"
      />
      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center"
        :class="{'hidden': isOpen}"
      >
        <button
          v-for="(item, index) in galleryItems"
          :key="index"
          :data-thumb="item.url"
          class="bg-neutral-100"
          :disabled="isMobile"
          @click="openGallery(index)"
        >
          <img
            :key="index"
            v-lazy="item.url"
            :class="item.height > item.width ? 'object-contain' : 'object-cover'"
            :alt="item.alt || fallbackAlt"
            class="w-[24rem] xl:w-[30rem] 2xl:w-full aspect-[3/2.25]"
          >
          <span class="sr-only">Open photo</span>
        </button>
      </div>

      <div
        class="grid grid-cols-1"
        :class="{'hidden': !isOpen}"
      >
        <div>
          <button
            class="sm:mb-4 mb-1 text-sm hover:text-neutral-300 ml-10 sm:ml-0"
            @click="toggle"
          >
            <VIcon
              i="arrow"
              class="mr-2"
              :rotate="180"
            />
            BACK
            <span class="sr-only">Back to photo gallery</span>
          </button>
        </div>
        <div
          class="flex flex-row justify-between"
        >
          <button
            class="slider-nav prev text-3xl scale-up-on-hover opacity-50 hover:opacity-100"
            @click="prev"
          >
            <VIcon
              i="caret-outline-rev-circle"
              :rotate="90"
            />
            <span class="sr-only">Previous photo</span>
          </button>
          <div>
            <img
              :src="galleryItems[selectedImageIndex].url"
              :alt="galleryItems[selectedImageIndex].alt || fallbackAlt"
              class="w-auto max-w-full px-2"
            >
          </div>
          <button
            class="slider-nav next text-3xl scale-up-on-hover opacity-50 hover:opacity-100"
            @click="next"
          >
            <VIcon
              i="caret-outline-rev-circle"
              :rotate="-90"
            />
            <span class="sr-only">Next photo</span>
          </button>
        </div>
      </div>
    </div>
  </VModal>
</template>

<script setup>
import { inject, ref } from 'vue';
import { GalleryInstance } from '../../services/Gallery';
import ListingDetailToolbar from './ListingDetailToolbar.vue';
import useMobileDetection from '../composables/useMobileDetection';

const props = defineProps({
  items: {
    type: String,
    required: true,
  },
  fallbackAlt: {
    type: String,
    default: '',
  },
  gallery: {
    type: [Object, GalleryInstance],
    default: () => new GalleryInstance(),
  },
  virtualTourUrl: {
    type: String,
    required: true,
  },
  brochureUrl: {
    type: String,
    required: true,
  },
  isFavoriteVisible: {
    type: Boolean,
    default: true,
  },
  hasRealVisionVirtualTour: {
    type: Boolean,
    default: false,
  },
  listing: {
    type: Object,
    required: true,
  },
  videoUrl: {
    type: String,
    required: true,
  },
});

const modal = inject('modal');
const isOpen = ref(false);
const selectedImageIndex = ref(0);
const { isMobile } = useMobileDetection();

const closeModal = () => {
  modal.hide('gallery-grid-modal');
};

const galleryItems = JSON.parse(props.items);

const openGallery = (index) => {
  isOpen.value = true;
  selectedImageIndex.value = index;
};

const toggle = () => {
  isOpen.value = !isOpen.value;
};

const next = () => {
  if (galleryItems.length - 1 > selectedImageIndex.value) {
    selectedImageIndex.value += 1;
    return;
  }
  selectedImageIndex.value = 0;
};

const prev = () => {
  if (selectedImageIndex.value > 0) {
    selectedImageIndex.value -= 1;
    return;
  }
  selectedImageIndex.value = galleryItems.length - 1;
};

const clear = () => {
  selectedImageIndex.value = 0;
  isOpen.value = false;
};

</script>
