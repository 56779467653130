import { createApp } from 'vue';
import Alert from '../../services/Alert';

const COOKIE_ACCEPTANCE_COOKIE = 'cookie-consent-notice-acceptance';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                modal: {
                    title: null,
                    videoUrl: null,
                    videoDescription: null,
                    videoTagLine: null,
                },
            });
        },

        methods: {
            openContactModal() {
                this.$modal.show('contact-modal');
            },
            openVideoModal() {
                this.$modal.show('video-modal');
            },
            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.title = modalData.title;
                this.modal.videoDescription = modalData.description;
                this.modal.videoTagline = modalData.description;
            },
            revokeCookieConsent() {
                this.$cookies.set(COOKIE_ACCEPTANCE_COOKIE, 0, '1y');
                Alert.toastSuccess('You&rsquo;ve revoked your consent.');
            },
        },
        mounted() {
            const revokeLink = document.querySelectorAll("a[href='#revoke-cookie-consent']");
            if (revokeLink.length) {
                revokeLink.forEach(link => {
                    link.target = '_self';
                });
            }
        },
    });
}
