<template>
  <component
    :is="panelComponent"
    v-bind="$attrs"
    :id="id"
    :name="name"
    :is-shown="state.isActive"
    @expanded="accordionPanelExpanded"
  >
    <slot />
  </component>
</template>

<script>
import { inject, reactive, computed } from 'vue';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: () => 'tabpanel_' + Math.random().toString().substring(2),
    },
  },

  emits: [
    'collapsed',
    'expanded',
  ],

  setup(props) {
    const MorphTabsProvider = inject('MorphTabsProvider');

    const state = reactive({
      isActive: false,
    });

    function accordionPanelExpanded(tab) {
      // this.$parent.$parent => MorphTabs
      MorphTabsProvider.tabSelected(tab);
    }

    const panelComponent = computed(() => {
      switch (MorphTabsProvider.state.tabContainer) {
        case 'v-accordion':
        case 'accordion':
          return 'v-accordion-panel';
        default:
          return 'v-tabs-panel';
      }
    });

    return {
      state,
      accordionPanelExpanded,
      panelComponent,
    };
  },
};
</script>
