<template>
  <div class="relative w-auto sm:w-72">
    <div
      ref="card"
      class="flex flex-col bg-white h-full"
    >
      <a
        class="relative"
        :href="item.detailsUrl"
        target="_blank"
      >
        <img
          :src="item.image"
          class="h-[11rem] w-full object-cover object-center bg-neutral-400"
          :alt="item.fullAddress"
        >
        <div
          v-if="showStatus"
          class="status"
          v-text="showStatus"
        />
      </a>
      <div class="flex-1 m-2 relative">
        <div class="flex flex-col">
          <div class="my-1 text-base">
            <a
              :href="item.detailsUrl"
              target="_blank"
            >
              <p
                class="font-bold text-base"
                v-text="item.name"
              />
            </a>
            <div
              class="text-base"
              v-text="item.streetAddress"
            />
            <div
              class="flex"
              v-text="item.cityStateZip"
            />
            <div>
              <span>PHONE:</span>
              <a
                v-if="item.phone"
                class="hover:underline hover:opacity-75"
                :href="'tel:' + item.phone"
              >
                <span v-text="item.phone" />
              </a>
            </div>
          </div>
          <div class="mt-0 md:mt-2 flex flex-row-reverse">
            <a
              :href="item.detailsUrl"
              target="_blank"
              class="text-3xl hover:text-neutral-400 scale-up-on-hover flex-none w-auto"
            >
              <v-icon i="arrow-outline-rev-circle" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showStatus() {
      return this.item?.byAppointmentOnly === true ? 'By Appointment Only' : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  @apply absolute top-4 right-0 py-1 pl-2 pr-6 border-l-3 border-secondary bg-transparent-dark;
  @apply text-right text-white font-bold uppercase text-xs;
}
</style>
