<template>
  <div class="accordion-tab">
    <a
      class="accordion-toggle"
      :class="{ open: isOpen, disabled: isDisabled }"
      @click="toggle"
    >
      <h3
        :class="[{
          'text-xl ml-3 md:text-2xl font-bold': !headingClasses,
          [headingClasses]: headingClasses,
        }]"
        v-text="name"
      />

      <VIcon
        v-if="showDelete"
        class="text-xl mr-2"
        i="trash"
        @click.stop="deleteMe($event)"
      />

      <VIcon
        class="text-2xl mr-2"
        i="caret"
        :rotate="isOpen ? 180 : 0"
      />
    </a>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div v-show="isOpen">
        <div class="pb-8">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {

  props: {
    name: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    initOpen: {
      type: Boolean,
      default: false,
    },
    headingClasses: {
      type: String,
      default: null,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
  },

  emits: ['expanded', 'collapsed', 'deleted'],

  setup(props, { emit }) {
    const acc = inject('AccordionProvider');

    const isOpen = ref(props.initOpen);

    function toggle() {
      if (props.isDisabled) return;
      isOpen.value = !isOpen.value;

      emit(isOpen.value ? 'expanded' : 'collapsed', this);

      acc.toggleAccordionPanel(this);
    }

    function deleteMe(event) {
      if (props.isDisabled) return;

      emit('deleted', props.index);
    }

    return {
      toggle,
      isOpen,
      deleteMe,
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion-tab {
  @apply border-b border-t border-neutral-200;
  .accordion-toggle {
    @apply flex py-4 flex-row justify-between items-center cursor-pointer;
  }
}
</style>
