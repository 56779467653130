import { createApp } from 'vue';
import { GMapCluster } from '@fawmi/vue-google-maps';
import gmapDefaultStyles from '../configs/GmapDefaultStyles';
import gmapOfficeMarkerIcon from '../configs/GmapOfficeMarkerIcon';
import gmapClusterStyles from '../configs/GmapClusterStyles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config.js';
const fullTailwindConfig = resolveConfig(tailwindConfig);

export default function (data) {
    return createApp({
        components: {
            GMapCluster,
        },
        data() {
            return _.merge(data, {
                searchString: '',
                showMap: true,
                infoWindow: {
                    open: false,
                    position: null,
                    options: { pixelOffset: { width: 0, height: -35 } },
                    item: {},
                },
                gmapOfficeMarkerIcon,
                gmapClusterStyles,
                mapOptions: {
                    zoomControl: true,
                    minZoom: 2,
                    maxZoom: 18,
                    mapTypeControl: false,
                    detectEvents: true,
                    drawEnabled: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: true,
                    styles: gmapDefaultStyles,
                },
                mapIdle: false,
                mapTilesLoaded: true,
                markers: [],
                officesToMarkers: {},
            });
        },
        methods: {
            initMap() {
                this.officesToMarkers = {};
                this.markers = [];
                this.$refs.theMap.$mapPromise.then(map => {
                    const bounds = new google.maps.LatLngBounds();
                    this.offices.forEach((item, index) => {
                        item.markerID = index;
                        if (
                            _.isUndefined(item.latLng.lat)
                            || _.isUndefined(item.latLng.lng)
                            || isNaN(parseFloat(item.latLng.lat))
                            || isNaN(parseFloat(item.latLng.lng))
                        ) {
                            console.error(`The ${item.name} office is missing lat or lng property`);
                        } else {
                            this.officesToMarkers[item.id] = this.addMarker(item);
                            bounds.extend({
                                lat: item.latLng.lat,
                                lng: item.latLng.lng,
                            });
                        }
                    });
                    map.fitBounds(bounds);
                    this.showMap = this.offices.length > 0;
                });
            },
            addMarker(item) {
                this.markers.push({
                    position: {
                        lat: item.latLng.lat,
                        lng: item.latLng.lng,
                    },
                    item,
                });

                return this.markers.length - 1;
            },
            handleItemSelected(event) {
                window.location.href = `${event.url}`;
            },

            showMarkerCard(marker) {
                this.infoWindow.open = true;
                this.infoWindow.position = marker.position;
                this.infoWindow.item = marker.item;
            },

            openInfoWindow(item) {
                if (!this.showMap || window.innerWidth < parseInt(fullTailwindConfig.theme.screens.lg)) {
                    return;
                }
                const index = this.officesToMarkers[item.id];
                if (this.markers[index]) {
                    this.showMarkerCard(this.markers[index]);
                }
            },
        },

        mounted() {
            this.initMap();
        },
    });
}
