<template>
  <div>
    <slot :state="state"></slot>
  </div>
</template>
<script>
export default {
  props: {
    state: {
      type: Object,
      default: () => {},
    }
  },
}
</script>
