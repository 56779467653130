<template>
  <div class="expandable-card-container">
    <div
      ref="card"
      class="flex flex-col bg-white text-center m-auto p-2"
    >
      <a
        class="w-2/3 m-auto mb-2"
        :href="item.profileURL"
      >
        <img
          v-lazy="item.profilePhoto"
          class="w-full rounded-full"
          :alt="`${item.name} photo`"
          width="90"
          height="90"
        >
      </a>
      <h2 class="text-lg md:text-xl mb-5">
        <a
          :href="item.profileURL"
          class="hover:opacity-75"
          v-text="item.name"
        />
      </h2>
      <hr class="my-0.5 border-neutral-200">

      <div class="flex-1 m-4 relative">
        <div>
          <a
            :href="item.profileURL"
            class="underline hover:opacity-75"
          >Contact Me</a>
        </div>
        <div v-if="showMobileAndOffice">
          <div>
            <span class="uppercase">Office</span>:
            <a
              :href="agentOfficeNumberHref"
              class="hover:underline hover:opacity-75"
              v-text="item.directOffice"
            />
          </div>
          <div>
            <span class="uppercase">Mobile</span>:
            <a
              :href="agentMobileNumberHref"
              class="hover:underline hover:opacity-75"
              v-text="item.mobile"
            />
          </div>
        </div>
        <div v-else>
          <div>
            <span
              class="uppercase"
              v-text="agentPhoneLabel"
            />
            <a
              :href="agentPhoneNumberHref"
              class="hover:underline hover:opacity-75"
              v-text="agentPhoneNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    agents: {
      type: Array,
    },
  },
  computed: {
    showMobileAndOffice() {
      if (this.item.mobileVisible === true) {
        return true;
      }
      return false;
    },
    agentPhoneNumber() {
      let phone;
      if (this.item.mobileVisible && this.item.mobile) {
        phone = this.item.mobile;
      } else if (this.item.directOffice !== null) {
        phone = this.item.directOffice;
      } else {
        phone = this.$root.teamNumber;
      }
      return phone;
    },
    agentPhoneNumberHref() {
      return this.agentPhoneNumber ? `tel:+1.${this.agentPhoneNumber}` : null;
    },
    agentMobileNumberHref() {
      return this.item.mobile ? `tel:+1.${this.item.mobile}` : null;
    },
    agentOfficeNumberHref() {
      return this.item.directOffice ? `tel:+1.${this.item.directOffice}` : `tel:+1.${this.$root.teamNumber}`;
    },
    agentPhoneLabel() {
      let phoneLabel;

      if (this.item.mobileVisible && this.item.mobile) {
        phoneLabel = 'Mobile:';
      } else if (this.item.directOffice !== null) {
        phoneLabel = 'Office:';
      } else {
        phoneLabel = '';
      }
      return phoneLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
.expandable-card-container {
  @apply relative m-auto;
  max-width: 275px;
  min-height: 22rem;
  @screen xl {
    height: 25rem;
  }
}
</style>
