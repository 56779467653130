<template>
  <div>
    <div class="p-3 lg:p-0 mb-4 flex-col lg:flex-row">
      <select
        v-model="userChartOptions.interval"
        class="mb-2 lg:mb-0 lg:pr-5 w-full lg:w-1/5"
        @change="loadNewChartData"
      >
        <option value="36">
          Past 3 Years
        </option>
        <option value="12">
          Past Year
        </option>
        <option value="6">
          Past 6 Months
        </option>
        <option value="3">
          Past 3 Months
        </option>
      </select>
      <select
        v-model="userChartOptions.stat"
        class="lg:ml-4 lg:pr-5 w-full lg:w-1/5"
        @change="loadNewChartData"
      >
        <option value="DOM">
          Avg. Days on Market
        </option>
        <option value="LN">
          Properties On Market
        </option>
        <option value="SPLP">
          Avg. Sales Price
        </option>
        <option value="SPLPRatio">
          Sales Price vs List Price
        </option>
      </select>
    </div>
    <div class="m-3 lg:m-0 chart-container relative border border-neutral-300">
      <LineChart
        v-if="loaded"
        v-bind="lineChartProps"
      />
      <div id="legend" />
    </div>
  </div>
</template>

<script setup>
import Api from '../../services/Api';
import Alert from '../../services/Alert';
import { ref, computed, onMounted, reactive } from 'vue';
import { LineChart, useLineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const props = defineProps({
  entity: {
    type: Object,
    default: () => ({
      id: 0,
      MLS: 0,
      LN: 0,
    }),
  },
  entityType: {
    type: String,
    default: 'neighborhood',
    validator: function (value) {
      return ['neighborhood', 'listing'].indexOf(value) > -1;
    },
  },
});

const chartLabels = ref(['Label 1', 'Label 2']);
const chartDatasets = ref([
  { label: 'Label 1', fill: false },
  { label: 'Label 2', fill: false },
]);

const chartData = computed(() => ({
  labels: chartLabels.value,
  datasets: chartDatasets.value,
}));

const options = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback: function (value) {
          // this.getLabelForValue is an internal CharJS function
          // Converting label to MM-DD here for the ticks
          return (new Date(this.getLabelForValue(value))).toISOString().slice(5, 10);
        },
      },
    },
    y: {
      ticks: {
        beginAtZero: true,
        callback: function (value) {
          if (parseInt(value) >= 5000) {
            return '$' + value.toLocaleString();
          } else {
            return value;
          }
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
  layout: {
    padding: {
      left: 20,
      right: 20,
      top: 20,
      bottom: 20,
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
}));

const { lineChartProps } = useLineChart({
  chartData,
  options,
});

const userChartOptions = reactive({
  stat: 'DOM',
  interval: 36,
});

const loaded = ref(false);
const apiUrl = computed(() => {
  const queryParams = `stat=${userChartOptions.stat}&dateInterval=${userChartOptions.interval}`;
  switch (props.entityType) {
    case 'listing':
      return `market-data/listings/${props.entity.MLS}/${props.entity.LN}?${queryParams}`;
    case 'neighborhood':
    default:
      return `market-data/neighborhoods/${props.entity.id}?${queryParams}`;
  }
});

function loadNewChartData() {
  loaded.value = false;
  Api.get(apiUrl.value).then(response => {
    if (userChartOptions.stat === 'DOM' || userChartOptions.stat === 'LN') {
      chartLabels.value = response.data.map(dataPoint => {
        return (new Date(dataPoint.x || 0)).toISOString().slice(0, 10);
      });
      chartDatasets.value = [
        {
          label: userChartOptions.stat === 'DOM' ? 'Days On Market' : 'Properties Listed',
          data: response.data.map(dataPoint => dataPoint.y),
          borderColor: 'rgb(210,38, 48)',
          backgroundColor: 'rgb(210,38, 48)',
          fill: false,
        },
      ];
    } else if (userChartOptions.stat === 'SPLP' || userChartOptions.stat === 'SPLPRatio') {
      const dataWithMostPoints = response.data[0].length > response.data[1].length
        ? response.data[0]
        : response.data[1];
      chartLabels.value = dataWithMostPoints.map(dataPoint => {
        return (new Date(dataPoint.x || 0)).toISOString().slice(0, 10);
      });
      chartDatasets.value = [
        {
          label: userChartOptions.stat === 'SPLP' ? 'Average Sale Price' : 'Average sale price % of list price',
          data: response.data[0].map(dataPoint => dataPoint.y),
          borderColor: 'rgb(210,38, 48)',
          backgroundColor: 'rgb(210,38, 48)',
          fill: false,
        },
        {
          label: userChartOptions.stat === 'SPLP' ? 'Average List Price' : 'Median sale price % of list price',
          data: response.data[1].map(dataPoint => dataPoint.y),
          borderColor: 'rgb(82,174,190)',
          backgroundColor: 'rgb(82,174,190)',
          fill: false,
        },
      ];
    }
    loaded.value = true;
  }).catch(err => {
    Alert.toastError(err);
  });
}

onMounted(() => loadNewChartData());
</script>

<style scoped>
.chart-container {
  height: 400px;
}
</style>
