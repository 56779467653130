<template>
  <VModal
    name="schedule-virtual-tour"
    @before-open="init"
  >
    <button
      type="button"
      class="close"
      @click="closeModal"
    >
      <VIcon i="close" />
      <span class="sr-only">Close</span>
    </button>
    <div class="modal-header">
      <h3 class="modal-title">
        Request Tour
      </h3>
      <p
        v-if="listing"
        class="text-center mb-4"
        v-text="listing.fullAddress"
      />
      <p
        v-else-if="development"
        class="text-center mb-4"
        v-text="development.fullAddress"
      />
    </div>
    <div class="modal-body">
      <div v-if="!submitted">
        <p class="text-base text-center mx-auto md:w-1/2 mb-10">
          <strong>Upon submitting this form, you will be contacted by an agent to assist you.</strong>
        </p>
        <div class="my-4">
          <FancyDatePicker @date-selected="tourDateSelected" />
          <FancyTimePicker
            :earliest-hour="earliestHour"
            :earliest-minute="earliestMinute"
            @time-selected="tourTimeSelected"
          />
        </div>
        <ContactForm
          endpoint="forms/schedule-a-viewing"
          :show-client-type-options="false"
          :show-area-of-interest="false"
          :form-defaults="contactFormDefaults"
          :payload="addDateAndTimeToPayload"
          :show-required-hint="false"
          submit-button-label="send message"
          @submitted="submitted = true"
        />
        <p class="text-xs text-center mx-auto md:w-1/2 my-4">
          Your name and email address are required. Your personal
          information is strictly confidential and will not be shared
          with any outside organizations.
        </p>
      </div>
      <div v-else>
        <h3 class="text-4xl text-center t-header mx-auto my-4">
          An agent will be in touch soon to confirm your request.
        </h3>
        <button
          class="btn btn-primary btn-lg w-full"
          type="button"
          @click="closeModal"
        >
          Got it!
        </button>
      </div>
    </div>
    <div class="modal-footer" />
  </VModal>
</template>

<script>
import ContactForm from './forms/ContactForm.vue';
import FancyTimePicker from './FancyTimePicker.vue';
import FancyDatePicker from './FancyDatePicker.vue';
import VModal from '../../components/VModal.vue';
import VIcon from '../../components/VIcon.vue';

export default {
  components: { VIcon, VModal, FancyTimePicker, FancyDatePicker, ContactForm },

  data() {
    return {
      form: {},
      earliestHour: 9,
      earliestMinute: 0,
      listing: null,
      development: null,
      building: null,
      submitted: false,
      addDateAndTimeToPayload: payload => {
        if (!this.form.tourDate || !this.form.tourTime) {
          throw new Error('Please select a date and time.');
        }

        payload.firstChoice = `${this.form.tourDate} ${this.form.tourTime}`;
        if (this.listing) {
          payload.LN = this.listing.LN;
          payload.MLS = this.listing.MLS;
          payload.viewingType = 'listing';
        } else if (this.development) {
          payload.LN = this.development.id;
          payload.viewingType = 'development';
        } else if (this.building) {
          payload.LN = this.building.id;
          payload.viewingType = 'building';
        }

        return payload;
      },
    };
  },

  computed: {
    contactFormDefaults() {
      return {
        comments: this.initialMessage,
      };
    },
    initialMessage() {
      if (this.listing && this.listing.fullAddress) {
        return "I'm interested in " + this.listing.fullAddress;
      }
      if (this.development && this.development.fullAddress) {
        return "I'm interested in " + this.development.fullAddress;
      }
      return '';
    },
  },

  methods: {
    tourDateSelected(value) {
      this.form.tourDate = value;

      this.updateEarliestHourAndMinute(value);
    },
    tourTimeSelected(value) {
      this.form.tourTime = value;
    },
    updateEarliestHourAndMinute(selectedDateValue) {
      if (!selectedDateValue) {
        this.earliestHour = 9;
        this.earliestMinute = 0;
      } else {
        const selectedDate = new Date(selectedDateValue.valueOf());
        const today = new Date();

        if (selectedDate.getUTCDate() === today.getUTCDate()
            && selectedDate.getUTCMonth() === today.getUTCMonth()
            && selectedDate.getUTCFullYear() === today.getUTCFullYear()) {
          if (today.getMinutes() >= 30) {
            this.earliestHour = today.getHours() + 1;
            this.earliestMinute = 0;
          } else {
            this.earliestHour = today.getHours();
            this.earliestMinute = 30;
          }
        } else {
          this.earliestHour = 9;
          this.earliestMinute = 0;
        }
      }
    },
    closeModal() {
      this.$modal.hide('schedule-virtual-tour');
      this.submitted = false;
    },
    init(data) {
      this.listing = data.listing || null;
      this.development = data.development || null;
      this.building = data.building || null;
    },
  },
};
</script>
