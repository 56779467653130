import Api from '../../services/Api';
import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                activeListings: [],
                soldListings: [],
                showAllTestimonials: false,
                totalSoldListings: 0,
            });
        },

        computed: {
            visibleTestimonials() {
                if (this.showAllTestimonials) {
                    return this.testimonials;
                }
                return [...this.testimonials].splice(0, 3);
            },
            activeListingsHeader() {
                if (!this.agent.teamName) {
                    return 'Active Listings';
                }

                const containsTeamListings = this.activeListings.findIndex(listing => listing.isTeamListing) > -1;
                return containsTeamListings ? `Active Listings for ${this.agent.teamName}` : 'Active Listings';
            },
            soldListingsHeader() {
                if (!this.agent.teamName) {
                    return 'Recent Sales';
                }

                const containsTeamListings = this.soldListings.findIndex(listing => listing.isTeamListing) > -1;
                return containsTeamListings ? `Past Sales for ${this.agent.teamName}` : 'Recent Sales';
            },
        },

        methods: {
            toggleTestimonials() {
                this.showAllTestimonials = !this.showAllTestimonials;
            },

            scheduleTour(listing) {
                this.$modal.show('schedule-virtual-tour', { listing });
            },
        },

        mounted() {
            Api.get(`/agents/${appData.agentPID}/listings/active`, {})
                .then(response => {
                    this.activeListings = response.data;
                });

            Api.get(`/agents/${appData.agentPID}/listings/recent`, {})
                .then(response => {
                    this.soldListings = response.data;
                    this.totalSoldListings = response.total;
                });

            if (this.showReviewModal) {
                this.$modal.show('review-agent-form');
            }
        },
    });
}
