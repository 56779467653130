<template>
  <div>
    <div class="saved-listing-container flex flex-col">
      <div class="saved-search-data-container flex flex-col flex-grow">
        <div class="name-container">
          <div class="mb-3">
            <div class="text-2xl font-bold leading-none mb-1">
              {{ search.description }}
            </div>
            <div>
              Date Created:
              <span class="font-bold">{{ search.createdDate }}</span>
            </div>
            <div class="py-2 font-bold text-lg">
              {{ search.type }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-neutral-200 text-sm mb-1">
            NOTIFY ME WHEN NEW LISTINGS MATCH THIS SEARCH:
          </div>
          <div class="button-group-container">
            <label
              :for="search.id + 'daily'"
              :class="{active: form.emailFrequency === 'daily'}"
              class="flex-1"
            >
              <input
                :id="search.id + 'daily'"
                v-model="form.emailFrequency"
                type="radio"
                value="daily"
                @change="updateFrequency"
              >
              Daily
            </label>
            <label
              :for="search.id + 'weekly'"
              :class="{active: form.emailFrequency === 'weekly'}"
              class="flex-1"
            >
              <input
                :id="search.id + 'weekly'"
                v-model="form.emailFrequency"
                type="radio"
                :name="search.id + 'weekly'"
                :class="{active: form.emailFrequency === 'weekly'}"
                value="weekly"
                @change="updateFrequency"
              >
              Weekly
            </label>
            <label
              :for="search.id + 'monthly'"
              :class="{active: form.emailFrequency === 'monthly'}"
              class="flex-1"
            >
              <input
                :id="search.id + 'monthly'"
                v-model="form.emailFrequency"
                type="radio"
                :name="search.id + 'monthly'"
                :class="{active: form.emailFrequency === 'monthly'}"
                value="monthly"
                @change="updateFrequency"
              >
              Monthly
            </label>
            <label
              :for="search.id + 'never'"
              :class="{active: form.emailFrequency === 'never'}"
              class="flex-1"
            >
              <input
                :id="search.id + 'never'"
                v-model="form.emailFrequency"
                type="radio"
                :name="search.id + 'never'"
                :class="{active: form.emailFrequency === 'never'}"
                value="never"
                @change="updateFrequency"
              >
              Never
            </label>
            <label
              :for="search.id + 'whenever'"
              :class="{active: form.emailFrequency === 'whenever'}"
              class="flex-1"
            >
              <input
                :id="search.id + 'whenever'"
                v-model="form.emailFrequency"
                type="radio"
                :name="search.id + 'whenever'"
                :class="{active: form.emailFrequency === 'whenever'}"
                value="whenever"
                @change="updateFrequency"
              >
              Whenever they occur
            </label>
          </div>
        </div>
      </div>
      <div class="saved-search-icon-container inline-flex flex-col">
        <div class="saved-listing-icons inline-flex">
          <a
            v-tippy
            :href="search.searchUrl"
            class="hover:text-neutral-400 text-5xl md:text-4xl cursor-pointer"
            content="Click to run this search"
          >
            <VIcon i="search-outline-circle" />
          </a>
          <div class="flex-grow sm:hidden" />
          <a
            v-tippy
            class="text-5xl hover:text-neutral-400 md:text-4xl cursor-pointer"
            content="Delete this saved search"
            @click="removeSavedSearch"
          >
            <VIcon i="close-outline-circle" />
          </a>
        </div>
      </div>
    </div>

    <hr class="my-6">
  </div>
</template>

<script>
import Api from '../../../services/Api';
import Alert from '../../../services/Alert';

export default {

  props: {
    search: {
      type: Object,
      required: true,
    },
  },
  emits: ['unsaved'],

  data() {
    return {
      form: {
        emailFrequency: null,
      },
    };
  },

  mounted() {
    this.form.emailFrequency = this.search.emailFrequency;
  },

  methods: {
    removeSavedSearch() {
      Api.delete(`user/saved-searches/${this.search.id}`).then(() => {
        Alert.toastSuccess('Search un-saved!');
        this.$emit('unsaved');
      }).catch(e => {
        Alert.toastError(e);
      });
    },
    updateFrequency() {
      Api.patch(`user/saved-searches/${this.search.id}`, this.form).then(() => {
        Alert.toastSuccess('Search frequency updated!');
      }).catch(e => {
        Alert.toastError(e);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.saved-listing-container {
  @screen md {
    @apply flex-row;
  }
}
</style>
