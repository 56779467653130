<template>
<div>
  <FadeTransition>
    <div
        v-show="isVisible"
        class="tab-panel"
        :aria-hidden="!isVisible"
        role="tabpanel"
    >
      <slot />
    </div>
  </FadeTransition>
</div>
</template>

<script setup>
import { inject, onBeforeMount, computed } from 'vue';

const props = defineProps({
  name: {
    required: true
  },
  isDisabled: {
    default: false
  },
  isShown: {
    default: null
  }
});

const emit = defineEmits(['collapsed', 'expanded']);

const TabsProvider = inject("TabsProvider");

const selfIndex = computed(() => TabsProvider.tabs.findIndex(tab => tab.name === props.name))

const isActive = computed(() => {
  return TabsProvider.currentTab === selfIndex.value;
});

// const isVisible = computed(() => _.isNull(props.isShown) ? isActive.value : props.isShown);
const isVisible = computed(() => isActive.value);

onBeforeMount(() => {
  TabsProvider.tabs.push(props);
})
</script>
