import { createApp } from "vue";
import SvgVue from 'svg-vue3';
import VueClickAway from "vue3-click-away";
import VIcon from "../../components/VIcon";

const app = createApp({
    data() {
        return {
            dropdown: null,
            mobileVisible: false,
            preventMenuClose: false,
        };
    },

    methods: {
        showDropdown(dropdown, toggle = false, desktopOnly = false) {
            if (desktopOnly && this.mobileVisible) {
                return null;
            }
            if (toggle && this.dropdown === dropdown) {
                return this.preventMenuClose ? null : this.hideDropdown();
            }

            this.dropdown = dropdown;

            this.preventMenuClose = true;
            setTimeout(() => {
                this.preventMenuClose = false;
            }, 500);
        },
        hideDropdown(desktopOnly = false) {
            if (desktopOnly && this.mobileVisible) {
                return null;
            }
            this.dropdown = null;
        },
        toggleMobile() {
            setTimeout(() => {
                this.mobileVisible = !this.mobileVisible;
            });
        },
        hideMobile() {
            this.mobileVisible = false;
        },
    },

    mounted() {
        window.addEventListener('resize', _.throttle(() => {
            this.mobileVisible = false;
        }, 300))
    }
})

app.use(SvgVue);
app.use(VueClickAway);
app.component('VIcon', VIcon);

app.config.compilerOptions.whitespace = 'preserve';

app.mount('#header');
